import React from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AccountContext } from "../context/AccountProvider";
import { ReactComponent as SuccessImage } from "../resources/WM_Success_Illustration__1_.svg";
import NavBar from "../components/navigation/NavBar";

interface LocationState {
  pathname: string;
  eligibility: {
    email: string;
  };
  card: string;
  package: {
    sku: string;
  };
}
/**
 * Handles calling the aggregated enrollment when a user transitions from invalid to a valid user
 * @returns loading, error, or valid success page
 */
const SuccessUpdated = () => {
  const location = useLocation<LocationState>();
  const { error } = useContext(AccountContext);
  const {
    package: { sku },
    eligibility: { email },
    card,
  } = location.state;

  //we have refreshed and its done loading
  // useEffect(() => {
  //   if (!loading && called) setHasLoaded(true);
  // }, [loading, called]);

  const handleMembershipCard = () => {
    const image = new Image();
    image.src = `data:image/png;base64,${card}`;
    const w = window.open("");
    w?.document.write(image.outerHTML);
  };

  // if (!hasLoaded) {
  //   return (
  //     <div className="center-loading">
  //       <Spinner animation="border" />
  //     </div>
  //   );
  // }

  if (error) throw new Error("Invalid");

  return (
    <div
      style={{
        background: "#F5F5F5",
        height: `100%`,
        width: `100%`,
        position: `absolute`,
        top: `0`,
        left: `0`,
        // paddingTop: `10px`,
      }}
    >
      <NavBar logo id={true} />
      <div
        style={{
          paddingTop: "15px",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <SuccessImage style={{ width: `80%` }} />
        <span
          style={{ fontSize: `44px`, marginTop: "15px", marginBottom: "10px" }}
        >
          <b>Success!</b>
        </span>
        <h4 style={{ color: "black" }}>
          <b>Please check your email</b>
        </h4>
        <p style={{ color: "black", marginBottom: 10 }}>
          We've sent the instructions for completing your account setup to
        </p>
        <p style={{ color: "black" }}>
          <b>{email}</b>
        </p>
        {sku !== "Fitness Pass Digital" && (
          <div
            style={{
              border: "solid",
              borderWidth: `.5px`,
              borderColor: `#DEDEDE`,
              borderRadius: `5px`,
              maxWidth: `40%`,
              padding: "13px",
              background: `white`,
              marginTop: `50px`,
            }}
          >
            <p>Need quick access to your member card?</p>
            <span
              onClick={() => handleMembershipCard()}
              style={{
                textDecoration: "underline",
                color: `var(--primary)`,
                cursor: `pointer`,
              }}
            >
              View my member card
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessUpdated;
