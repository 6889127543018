import { useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";
// TODO: Other query parameters
const useGetTierCount = () => {
  const [tierCount, setTierCount] = useState<unknown>(0);

  const getTierCount = {
    run: async (tier: string, token: string) => {
      if (tier && tier !== "0") {
        await axiosNetworkInstance
          .get(`/location-summary?programCode=prime_lte&tierNumber=${tier}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data) {
              setTierCount(response.data.data.activeLocationCount);
            }
          })
          .catch((error) => {
            console.log(error.toJSON());
          });
      }
    },
  };

  return {
    getTierCount,
    tierCount,
  };
};

export default useGetTierCount;
