import React, { Fragment, useContext } from "react";
import { Card, Collapse, Spinner } from "react-bootstrap";
import CollapseHeader from "../../shared/CollapseHeader";
import { convertToCurrency, momentStartOfDay } from "../../../utility/util";
import { AccountContext } from "../../../context/AccountProvider";
import { UserContext } from "../../../context/UserProvider";
import { SubscriptionPreviewResponse } from "../../../utility/member-portal-api-types";

const PlanSummary = ({
  //   summary,
  //   currentPlanName,
  previewData,
  currentPlanTotal,
  userTermed = false,
  loadingPending,
  promoLoading,
  errorSummary,
  isUpgrade,
}: {
  //   summary: SummaryUIMap | null;
  previewData: SubscriptionPreviewResponse;
  currentPlanTotal: string;
  userTermed?: boolean;
  loadingPending: boolean;
  promoLoading: boolean;
  errorSummary: boolean;
  validPromo: string;
  promoOpen: boolean;
  promo: string;
  currentPromo: string;
  onPromoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPromoRemove: (name: string) => void;
  onPromoSubmit: () => void;
  isUpgrade?: boolean;
  isChange: boolean;
}) => {
  //   const [isPromoCode, setIsPromoCode] = useState(false);
  const { nextPayment, member } = useContext(AccountContext);
  const { user } = useContext(UserContext);

  const currentPlanName = member?.eligibilities
    ? member?.eligibilities[0].currentSubscription?.ratePlanName
    : "";

  let amount: string | undefined;
  const due = momentStartOfDay(nextPayment?.due).format("MM/DD/YYYY");
  //   const formatContentArray = (items: string[]) => {
  //     return (
  //       <div className="d-flex flex-column">
  //         {items.map((i: string) => (
  //           <p className="mb-1" key={i}>
  //             <i>{i}</i>
  //           </p>
  //         ))}
  //       </div>
  //     );
  //   };
  const formatContent = (title: string, cost: string) => {
    return (
      <p className="mb-1">
        <i>{title}</i>
        <span style={{ float: "right" }}>{cost}</span>
      </p>
    );
  };

  const convertDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const adjustedDate = new Date(date);
    adjustedDate.setUTCHours(0, 0, 0, 0);
    const month = monthNames[adjustedDate.getUTCMonth()];
    const day = adjustedDate.getUTCDate();
    const result = month + " " + day;
    return result;
  };
  // const convertDate = (date) => {
  //   const month = monthNames[new Date(date).getMonth()];
  //   const day = new Date(date).getDate();

  //   const result = month + " " + day;

  //   return result;
  // };

  //   const handleTogglePromoCode = () => {
  //     setIsPromoCode(!isPromoCode);
  //   };

  // Authenticated member with an active subscription
  if (nextPayment!.amount > 0 && nextPayment!.due.length > 0) {
    amount = nextPayment?.amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  return (
    <Card bg={`${loadingPending && "light"}`} className="edit-plan__summary">
      <Card.Body>
        <Card.Title>Current Plan</Card.Title>
        {!userTermed && currentPlanName ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <span className="edit-plan__text" style={{ fontSize: "1rem" }}>
                <i>{currentPlanName}</i>
              </span>
              <span className="edit-plan__text" style={{ fontSize: "1rem" }}>
                {currentPlanTotal}
              </span>
            </div>
            {!user.isPayroll ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="edit-plan__text" style={{ fontSize: "1rem" }}>
                  <b>Taxes</b>
                </span>
                <span className="edit-plan__text" style={{ fontSize: "1rem" }}>
                  {convertToCurrency(parseInt("0.00"))}
                </span>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span className="edit-plan__text" style={{ fontSize: "1rem" }}>
                <b>{!user.isPayroll ? `Monthly Total` : `Per Paycheck`}</b>
              </span>
              <span
                className="edit-plan__text"
                style={{ fontWeight: "bolder", fontSize: "1rem" }}
              >
                {currentPlanTotal}
              </span>
            </div>
          </>
        ) : (
          <div>
            <h6>
              <i>
                <strong>No current package</strong>
              </i>
            </h6>
          </div>
        )}
      </Card.Body>
      {/* {!summary?.pending && !loadingPending && isChange && ( */}
      {!loadingPending && !previewData.success && (
        <Card.Footer className="summary-container__footer">
          {!nextPayment?.amount ||
          member?.eligibilities[0].isMembershipActive === false
            ? "No payment due"
            : `Your next payment of ${amount} is due on ${due}`}
        </Card.Footer>
      )}
      <Collapse in={errorSummary} unmountOnExit mountOnEnter>
        <div className="summary-container__error">
          <h4>
            <b>Sorry, there was an error.</b>
          </h4>
        </div>
      </Collapse>
      <Collapse
        in={loadingPending && !promoLoading && !errorSummary}
        mountOnEnter
      >
        <div className="summary-container__loading">
          <h4>Updating your Order Summary</h4>
          <Spinner animation="border" />
        </div>
      </Collapse>
      {previewData.success && !loadingPending && !errorSummary && (
        <Collapse in={!loadingPending}>
          <div>
            <Card>
              <Card.Body>
                <Card.Title>New Plan</Card.Title>
                <CollapseHeader
                  header="Package Selection(s) Total"
                  price={
                    previewData.monthlyPaymentSummary
                      ?.monthlyTotalPaymentAmountWithTax
                      ? convertToCurrency(
                          previewData.monthlyPaymentSummary
                            ?.monthlyTotalPaymentAmountWithTax
                        )
                      : convertToCurrency(parseInt("0.00"))
                  }
                >
                  <>
                    {previewData.monthlyPaymentSummary?.monthlyPaymentDetails?.map(
                      (p, i) => (
                        <Fragment key={i}>
                          {formatContent(
                            p.chargeName ? p.chargeName : "",
                            convertToCurrency(p.amountWithTax)
                          )}
                        </Fragment>
                      )
                    )}
                  </>
                </CollapseHeader>
                {/* {summary.pending?.showDiscounts && (
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={summary.pending.discountsTotal}
                  >
                    {formatContentArray(summary.pending.discounts)}
                  </CollapseHeader>
                )} */}
                {!user.isPayroll ? (
                  <p className="mt-3 mb-1">
                    Taxes
                    <span style={{ float: "right" }}>
                      {previewData.monthlyPaymentSummary
                        ?.monthlyTotalPaymentTaxAmount
                        ? convertToCurrency(
                            previewData.monthlyPaymentSummary
                              ?.monthlyTotalPaymentTaxAmount
                          )
                        : convertToCurrency(parseInt("0.00"))}
                    </span>
                  </p>
                ) : null}
                <p className="mb-0">
                  <b>
                    {!user.isPayroll ? `New Monthly Total` : `New Per Paycheck`}
                    <span style={{ float: "right" }}>
                      {previewData.monthlyPaymentSummary
                        ?.monthlyTotalPaymentAmountWithTax
                        ? convertToCurrency(
                            previewData.monthlyPaymentSummary
                              ?.monthlyTotalPaymentAmountWithTax
                          )
                        : convertToCurrency(parseInt("0.00"))}
                    </span>
                  </b>
                </p>
                <span>
                  <small>
                    <i>
                      Starting{" "}
                      {convertDate(
                        isUpgrade
                          ? previewData.immediatePaymentSummary
                              ?.immediatePaymentDetails?.[0].serviceStartDate
                          : previewData.monthlyPaymentSummary
                              ?.monthlyPaymentDetails?.[0].serviceStartDate
                      )}
                    </i>
                  </small>
                </span>
              </Card.Body>
            </Card>
            {previewData && !errorSummary && !user.isPayroll && isUpgrade && (
              <>
                <Card>
                  <Card.Body>
                    <Card.Title>Due Now</Card.Title>
                    <CollapseHeader
                      header="Package Selection(s) Total"
                      price={
                        previewData.immediatePaymentSummary
                          ?.immediateTotalPaymentAmountWithoutTax
                          ? convertToCurrency(
                              previewData.immediatePaymentSummary
                                ?.immediateTotalPaymentAmountWithoutTax
                            )
                          : convertToCurrency(parseInt("0.00"))
                      }
                    >
                      <>
                        {previewData.immediatePaymentSummary?.immediatePaymentDetails?.map(
                          (p) => (
                            <>
                              {formatContent(
                                p.chargeName ? p.chargeName : "",
                                convertToCurrency(p.amountWithTax)
                              )}
                            </>
                          )
                        )}
                      </>
                    </CollapseHeader>
                    {!user.isPayroll && isUpgrade ? (
                      <>
                        <p className="mb-1">
                          <b>
                            Prorated Amount
                            <span style={{ float: "right" }}>
                              {previewData.immediatePaymentSummary
                                ?.creditAmountWithTax
                                ? convertToCurrency(
                                    previewData.immediatePaymentSummary
                                      ?.creditAmountWithTax
                                  )
                                : convertToCurrency(parseInt("0.00"))}
                            </span>
                          </b>
                        </p>
                      </>
                    ) : null}
                    <p className="mt-3 mb-1">
                      Subtotal
                      <span style={{ float: "right" }}>
                        {previewData.immediatePaymentSummary
                          ?.immediateTotalPaymentAfterDiscountAmountWithoutTax
                          ? convertToCurrency(
                              previewData.immediatePaymentSummary
                                ?.immediateTotalPaymentAfterDiscountAmountWithoutTax
                            )
                          : convertToCurrency(parseInt("0.00"))}
                      </span>
                    </p>
                    <p className="mb-1">
                      Taxes
                      <span style={{ float: "right" }}>
                        {previewData.immediatePaymentSummary
                          ?.immediateTotalPaymenAfterDiscountTaxAmount
                          ? convertToCurrency(
                              previewData.immediatePaymentSummary
                                ?.immediateTotalPaymenAfterDiscountTaxAmount
                            )
                          : convertToCurrency(parseInt("0.00"))}
                      </span>
                    </p>
                    <p className="mb-1">
                      <b>
                        Total Due Now
                        <span style={{ float: "right" }}>
                          {previewData.immediatePaymentSummary
                            ?.immediateTotalPaymentAfterDiscountAmountWithTax
                            ? convertToCurrency(
                                previewData.immediatePaymentSummary
                                  ?.immediateTotalPaymentAfterDiscountAmountWithTax
                              )
                            : convertToCurrency(parseInt("0.00"))}
                        </span>
                      </b>
                    </p>
                  </Card.Body>
                </Card>
                {/* <Card bg="light">
                  <Card.Body>
                    <div
                      className="plan-summary-promo-code"
                      onClick={handleTogglePromoCode}
                    >
                      <FontAwesomeIcon icon={faTags} />
                      <span>Enter Promo Code</span>
                    </div>
                    {/* </Card.Body>
                </Card> 
                    {isPromoCode && (
                      <div style={{ margin: "1rem 0" }}>
                        <CollapseHeader
                          header="Discount(s) Total"
                          price={
                            summary.due?.discountsTotal
                              ? convertToCurrency(
                                  parseFloat(summary!.due!.discountsTotal)
                                )
                              : convertToCurrency(parseInt("0.00"))
                          }
                          initial={true}
                        >
                          {promoLoading ? (
                            <div
                              className="center-loading"
                              style={{ marginTop: "0" }}
                            >
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <>
                              {formatContentArray(summary.due?.discounts)}
                              {summary?.due.promoCodes?.map((code) => {
                                if (code.status === "SUCCESS") {
                                  return (
                                    <PromoCodeDisplay
                                      name={code.code}
                                      description={code.description}
                                      onRemove={onPromoRemove}
                                    />
                                  );
                                }
                              })}
                              <PromoCollapseHeader
                                header="Add Promo Code"
                                promoState={promoOpen}
                              >
                                <>
                                  <Form.Label className="mt-3 mr-2">
                                    Enter Promotional Code
                                  </Form.Label>
                                  {summary?.due.promoCodes?.map((code) => {
                                    if (
                                      code.code === promo &&
                                      code.status === "SUCCESS" &&
                                      currentPromo !== ""
                                    ) {
                                      return (
                                        <p className="text-danger">
                                          This promo code has already been
                                          applied
                                        </p>
                                      );
                                    }
                                  })}
                                  <InputGroup>
                                    <FormControl
                                      type="text"
                                      placeholder="Enter Code Here"
                                      onChange={onPromoChange}
                                      isInvalid={
                                        validPromo === "FALSE" &&
                                        promo !== "" &&
                                        currentPromo === promo
                                      }
                                    ></FormControl>
                                    <InputGroup.Append>
                                      <InputGroup.Append>
                                        <Button
                                          variant="primary"
                                          onClick={onPromoSubmit}
                                          disabled={
                                            validPromo === "FALSE" &&
                                            currentPromo === promo
                                          }
                                        >
                                          Apply
                                        </Button>
                                      </InputGroup.Append>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">
                                      Invalid Promo Code
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </>
                              </PromoCollapseHeader>
                            </>
                          )}
                        </CollapseHeader>
                      </div>
                    )}
                  </Card.Body>
                </Card> 
            */}
              </>
            )}
          </div>
        </Collapse>
      )}
    </Card>
  );
};

export default PlanSummary;
