import { fetchWrapper } from "../../utility/util";

export interface IToken {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
}
/*
Post Requests
 */
export const signup = async (
  username: string,
  password: string,
  acceptedTerms: string[],
  callback: (tokens: IToken) => Promise<void>
): Promise<void> => {
  const res = await fetchWrapper("/app/v1/account/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "pipeline-session-id":
        sessionStorage.getItem("pipeline-session-id") ?? "",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      acceptedTerms: acceptedTerms,
    }),
  });

  const json: IToken & { success: boolean; message?: string } =
    await res.json();

  if (json.success) {
    await callback({
      accessToken: json.accessToken,
      refreshToken: json.refreshToken,
      expiresIn: json.expiresIn,
      idToken: json.idToken,
    });
    return;
  }

  throw new Error(json.message);
};
//TODO - Refactor backend to not return an array
export const getClientConfig = async (host: string) => {
  if (host == "") {
    return Promise.reject(new Error("host required"));
  }
  try {
    const results = {
      client: "bcbsa",
      config: {
        // "communication.cancel": "d-479366375f014f1b9f6b152ae57e8d52",
        "eligibility.kind": "api",
        // "communication.welcomeDigital": "d-f08beb9f7cc640f685ffadd744c13a5c",
        wholeHealthChoices: "true",
        // "communication.passwordChanged": "d-704886d2cdc14ebfbf19e05045193815",
        "network.clientCode": "bcbsa",
        "eligibility.scope": "client:bcbsa",
        "zuora.paymentPageId.echeck": "8ad08c0f7c642e21017c76f0ad3d7133",
        "contact.phoneNumber": "888-872-7833",
        "smartystreets.key": "8945709737349046",
        "communication.emailChanged": "d-c986ee1fc33b4bdfbe7ec9df63988f10",
        "communication.welcomeHomeGym": "d-947591d37be34d998055eeecf92a2949",
        // "membership.attributeMapper": "bcbsa",
        "idp.clientId": "1upB9fLAYBKVNvrW9518hP03DoTEeqlb",
        "idp.issuer": "sandbox-tivityhealth.us.auth0.com",
        "idp.audience": "https://member-portal.sandbox.tivity.health",
        "communication.welcomeNoHomeGym": "d-13c25734b4814e8693b1c1df9d495834",
        "zuora.paymentGatewayCode.echeck": "BCBSA",
        "communication.addressChanged": "d-1e387a68c05e4bfdb123914f87c67e93",
        // "communication.upgrade": "d-9c89f1551ca040fd962938a2cfe9d727",
        "maintenance.enable": "false",
        "membership.costCenter": "5749",
        // "communication.downgrade": "d-ad61a108986f4a2597e0b576cbf8bb72",
        "zuora.hostedPagePath": "/apps/PublicHostedPageLite.do",
        "zuora.paymentGatewayCode.cc": "BCBSA",
        "site.baseUrl": "https://walmart.developer.local.tivity.health:3005",
        "eligibility.flow": "bcbsa",
        // "eligibility.minAge": "18",
        "maintenance.until": "",
        "zuora.paymentPageId.cc": "8ad08c0f7c642e21017c76e994182500",
        "commerce.clientCode": "BCBSA",
        "google.maps.key": "AIzaSyD7E3ij5Hy6y0WZU2vcTXDOx7Oyg9lGV4w",
        "hotjar.hjsv": "",
        "hotjar.hjid": "",
        "lesmills.enable": "false",
      },
      refresh: () => {
        return Promise.resolve();
      },
    };

    return Promise.resolve(results);
  } catch (err: unknown) {
    return Promise.reject(err);
  }
};

export const getBurnalong = async (): Promise<string> => {
  try {
    const resp = await fetchWrapper("/app/v1/partner/burnalong/forward");
    if (resp.ok) {
      const json = await resp.json();
      return json.redirectUri;
    }
    return Promise.reject("unknown error");
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

export const setAcceptanceDate = async () => {
  try {
    await fetchWrapper(`/app/blue365/accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e: unknown) {
    throw new Error("Error accepting Terms and Conditions");
  }
};

export const getSessionId = async (): Promise<string> => {
  try {
    const resp = await fetchWrapper("/app/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!resp.ok) return Promise.reject("unknown error");

    const json = await resp.json();
    return json.id;
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};
