import React, { useContext } from "react";
import moment from "moment";
import { AccountContext } from "../../context/AccountProvider";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const DependentTermedBanner = () => {
  const { member } = useContext(AccountContext);
  // const [link, setLink] = useState("");
  // const userStatus = member?.eligibilities[0].currentSubscriptionStatus;
  const history = useHistory();

  const handleReenroll = () => {
    //history.push(link);
    history.push("/enroll/eligibility");
  };

  // useEffect(() => {
  //   if (
  //     userStatus === undefined ||
  //     userStatus === "Cancelled" ||
  //     typeof hasAccount === "undefined" // If the user hasAccount is undefined, the user does not have a zuora account
  //   ) {
  //     setLink("/enroll/package");
  //   } else {
  //     setLink("/enroll/eligibility");
  //   }
  // }, []);

  return (
    <Alert className="account-suspension-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        Attention
      </Alert.Heading>
      <Row>
        <Col xs={12} style={{ padding: 0 }}>
          <div className="account-suspension-banner__body">
            Our records indicate the primary member has removed you from their
            plan. Please contact the primary account holder if you feel this is
            an error. If you are a Walmart associate, you can{" "}
            <u>
              {/* <a href={`/enroll/eligibility`} className="a-link">
                enroll now
              </a> */}

              <a
                onClick={handleReenroll}
                style={{ cursor: "pointer" }}
                className="a-link"
              >
                enroll now
              </a>
            </u>{" "}
            using your Walmart Identification Number by{" "}
            <b>
              {moment(member?.eligibilities[0].memberTermDate).format(
                "MM/DD/YYYY"
              )}
            </b>
            , to maintain your access.
            <br />
          </div>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      <Row>
        <Col xs={5}></Col>
        <Col xs={5}></Col>
        <Col xs={2} style={{ padding: 0 }}>
          {/* <Link
            to="/enroll/eligibility"
            style={{ color: "white", textDecoration: "none", fontSize: 12 }}
            className="btn btn-primary"
          >
            Enroll now
          </Link> */}
          <Button
            onClick={handleReenroll}
            className="btn btn-primary w-5 btn-sm"
          >
            Enroll now
          </Button>
        </Col>
      </Row>
    </Alert>
  );
};

export default DependentTermedBanner;
