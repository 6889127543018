import React, { useCallback, useState } from "react";
import { AccountSummaryMember } from "../utility/member-portal-api-types";

export enum USER_STATUS {
  ELIGIBLE,
  ENROLLED,
  FEP,
  PENDING,
  EXISTS,
}

export enum ELIGIBILITY_MESSAGE {
  Eligible = "Eligible",
  NotEligible = "NotEligible",
  EmailUnavailable = "EmailUnavailable",
  LoginCreated = "LoginCreated",
  ExistingLoginFound = "ExistingLoginFound",
  PersonIdFound = "PersonIdFound",
}

export enum USER_PAYROLL_STATUS {
  Payroll = "Payroll",
  NonPayroll = "NonPayroll",
}

export enum USER_PAYROLL_FREQUENCY {
  Weekly = "Weekly",
  BiWeekly = "Biweekly",
  NotApplicable = "NotApplicable",
}

export enum PROGRAM_CODE {
  PrimeLTE = "prime_lte",
  WalmartPayrollWeekly = "walmart_payroll_weekly",
  WalmartPayrollBiWeekly = "walmart_payroll",
  NotApplicable = "NotApplicable",
}
//FEP / REDEMPTION are 'authenticated' DEFAULT is not
export enum USER_FROM {
  FEP,
  REDEMPTION,
  DEFAULT,
}

type PayrollAuthorization = {
  clientCode: string;
  memberPayrollAuthorizationId: string;
  personId: number | undefined;
  authorizationAtUtc: string;
  authorizationStartDateAtAz: string;
  authorizationEndDateAtAz?: string;
  authorizationType: string;
  currentTierEndDate: string | undefined;
  currentPackageName: string;
  currentPackageAmount: number;
  newTierStartDate: string;
  newPackageName: string;
  newPackageAmount: number | undefined;
  createdAtUtc: string;
  updatedAtUtc: string;
};

export type IUser = {
  status: USER_STATUS;
  from: USER_FROM;
  info: UserInfo | undefined;
  personId: number | undefined;
  isPayroll: boolean | undefined;
  payrollFrequency: USER_PAYROLL_FREQUENCY;
  isSSO: boolean | undefined;
  isTermed: boolean | undefined;
  consumerId: string;
  clientStateCode: string;
  emailTaken: boolean | undefined;
  payrollAuthorizations: PayrollAuthorization[];
  zuoraAccountId: string | undefined;
  AccountSummaryMember: AccountSummaryMember | undefined;
};

export type UserInfo = {
  email: string;
  familyName: string | undefined;
  givenName: string | undefined;
  picture: string | undefined;
  preferredUsername: string | undefined;
  sub: string | undefined;
};

interface IUserProvider {
  user: IUser;
  isMocked: boolean;
  setIsMocked(m: boolean): void;
  setUser(u: Partial<IUser>): void;
}

export const UserContext = React.createContext<IUserProvider>({
  user: {
    status: USER_STATUS.PENDING,
    from: USER_FROM.DEFAULT,
    isPayroll: false,
    payrollFrequency: USER_PAYROLL_FREQUENCY.NotApplicable,
    personId: undefined,
    isSSO: false,
    isTermed: false,
    consumerId: sessionStorage.getItem("consumerId") ?? "",
    clientStateCode: sessionStorage.getItem("clientStateCode") ?? "",
    emailTaken: false,
    info: undefined,
    payrollAuthorizations: [],
    zuoraAccountId: undefined,
    AccountSummaryMember: undefined,
  },
  isMocked: false,
  setIsMocked: () => {
    return;
  },
  setUser: () => {
    return;
  },
});
/**
 * Checks eligibility/enrollment to determine if user is eligible already
 * Determines where the user came from if possible
 * eligibility.status or enrollment client means theyre eligible
 */
const UserProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [user, setUser] = useState<IUser>({
    status: USER_STATUS.PENDING,
    from: USER_FROM.DEFAULT,
    info: undefined,
    personId: undefined,
    isPayroll: false,
    payrollFrequency: USER_PAYROLL_FREQUENCY.NotApplicable,
    isSSO: false,
    isTermed: false,
    consumerId: sessionStorage.getItem("consumerId") ?? "",
    clientStateCode: "",
    emailTaken: false,
    payrollAuthorizations: [],
    zuoraAccountId: undefined,
    AccountSummaryMember: undefined,
  });

  const [isMocked, setIsMocked] = useState<boolean>(
    localStorage.isMocked === "false" ? false : true
  );

  /*
   * This handles b365 redemption flow users. Sets their user state.
   */
  const userValue: IUserProvider = {
    user,
    isMocked: isMocked,
    setIsMocked: useCallback(
      (m: boolean) => {
        setIsMocked(m);
        localStorage.setItem("isMocked", String(m));
      },
      [user]
    ),
    setUser: useCallback(
      (u: Partial<IUser>) => {
        setUser({ ...user, ...u });
      },
      [user]
    ),
  };

  return (
    <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
