import { ObjectShape } from "yup/lib/object";
import { WizardStepperStep } from "../components/Stepper";
import {
  EligibilityFlow,
  Flow,
  FormikWizardState,
  ICompleted,
} from "./../types";
import {
  AddressStep,
  // BCBSAStep,
  // BSCStep,
  CheckoutStep,
  // FEPStep,
  PackageStep,
  WalmartStep,
} from "./Steps";
import WizardStep from "./WizardStep";

export const Flows: Record<string, Flow> = {
  "client:walmart": {
    eligibility: WalmartStep,
    package: PackageStep,
    address: AddressStep,
    checkout: CheckoutStep,
  },
};

export const getModel = (flow: {
  [key: string]: WizardStep<Record<string, unknown> & ICompleted, unknown>;
}): FormikWizardState<EligibilityFlow> => {
  const model = Object.keys(flow).map((k) => ({ [k]: flow[k].getModel() }));
  return Object.assign({}, ...model);
};

export const getValidation = (flow: {
  [key: string]: WizardStep<Record<string, unknown> & ICompleted, unknown>;
}): ObjectShape => {
  const validation = Object.keys(flow).map((k) => ({
    [k]: flow[k].getValidation(),
  }));
  return Object.assign({}, ...validation);
};

export const getSteps = (flow: {
  [key: string]: WizardStep<Record<string, unknown> & ICompleted, unknown>;
}): WizardStepperStep[] => {
  return Object.keys(flow).map((k) => flow[k].getStep());
};
