import React, { Fragment, useEffect, useState } from "react";
import { contentfulConfig } from "../client";
import Section from "../components/terms/Section";
import { TermsOfUse, TermsOfUseSection } from "../types";
import { convertToTerms } from "../utility/contentful";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";

const Terms = ({ home }: { home: string }) => {
  const [content, setContent] = useState<TermsOfUse | null>(null);
  const [storedHash, setStoredHash] = useState<string | null>(null);
  const config = useConfig();
  const { getLegalDocs } = useContentful(config.config);
  useEffect(() => {
    (async () => {
      getLegalDocs(contentfulConfig.ids.privacy).then((res) => {
        if (res) {
          setContent(convertToTerms(res));
        }
      });
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (content) {
      if (storedHash) {
        window.location.hash = storedHash;
      }
    } else {
      setStoredHash(window.location.hash === "" ? null : window.location.hash);
      window.location.hash = "";
    }
  }, [content]);

  if (!content) {
    return null;
  }
  return (
    <Fragment>
      <p>
        <a href={home} className="a-link">
          Home
        </a>
        {" > "}Privacy Policy
      </p>
      {/* <h1>PRIVACY POLICY AND TERMS OF USE</h1> */}
      {/* <span>Last updated: {content.lastUpdated}</span> */}
      {content.sections.map((section: TermsOfUseSection) => (
        <Section {...section} key={section.header} />
      ))}
    </Fragment>
  );
};
export default Terms;
