import React, { useContext } from "react";
import {
  USER_PAYROLL_FREQUENCY,
  UserContext,
} from "../../context/UserProvider";
import { AccountContext } from "../../context/AccountProvider";
import BannerWrapper from "../shared/BannerWrapper";
import ReEnrollBanner from "./ReEnrollBanner";
import UpcomingPaymentDisplay from "./UpcomingPaymentDisplay";
import { useConfig } from "../../configuration/useConfig";
import { momentStartOfDay } from "../../utility/util";

const InformationDisplay = ({ header }: { header: string }) => {
  const { member } = useContext(AccountContext);
  const { config } = useConfig();
  const { user } = useContext(UserContext);

  const now = momentStartOfDay();
  const termDate = momentStartOfDay(
    member?.eligibilities[0].memberTermDate
  ).format("MM/DD/YY");
  const currentDate = momentStartOfDay(now).format("MM/DD/YY");
  const compareDates = termDate < currentDate;

  return (
    <div className="account-information">
      <h1>{header}</h1>
      <BannerWrapper
        // ! TEMPORARY CONDITION TO SHOW RE-ENROLL BANNER
        // ! When the "currentSubscriptionStatus" is fixed on the backend please revert this condition
        // show={
        //   member?.eligibilities[0].currentSubscriptionStatus === "Cancelled" &&
        //   ((user.isPayroll && user.status === USER_STATUS.ELIGIBLE) ||
        //     !user.isPayroll)
        // }
        show={!member?.eligibilities[0].isMembershipActive || compareDates}
      >
        <div className="widget d-flex flex-wrap flex-row mb-3">
          <ReEnrollBanner
            title="Welcome back! Re-Enroll to enjoy all the perks of the membership"
            content={
              <>
                Enroll and gain access to our vast network of participating gyms
                and digital content from{" "}
                {config["lesmills.enable"] == "true"
                  ? `Les
                Mills and `
                  : ""}
                Burnalong. Packages start{" "}
                <b>
                  as low as{" "}
                  {user.isPayroll
                    ? user.payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly
                      ? "$2.50/paycheck."
                      : "$5/paycheck."
                    : "$10.83/month."}
                </b>
              </>
            }
            enrollHref={"/enroll/package"}
            exploreHref="/locations"
          />
        </div>
      </BannerWrapper>
      <BannerWrapper
        show={
          !(
            member?.eligibilities[0].currentSubscriptionStatus ===
              "Cancelled" ||
            member?.eligibilities[0].currentSubscriptionStatus === undefined
          )
        }
      >
        <UpcomingPaymentDisplay />
      </BannerWrapper>
    </div>
  );
};

export default InformationDisplay;
