import { PropsWithChildren, useContext, useState } from "react";
import { Button, Modal, ModalProps, Spinner } from "react-bootstrap";
import { useConfig } from "../../../configuration/useConfig";
import { UserContext } from "../../../context/UserProvider";
import { useMembership } from "../../../hooks/useMembership";
import showToastMessage from "../../../utility/ProfileToast";
import { discardSubscription } from "../DiscardCancellationModal";
import moment from "moment";

interface DiscardSubscriptionChangeProperties {
  onClose: (success?: boolean) => void;
  nextPaymentDate?: string;
}

function Element({ children, ...props }: PropsWithChildren<ModalProps>) {
  return (
    <Modal show {...props} centered>
      <Modal.Header>
        <Modal.Title>
          <h2>Discard Changes?</h2>
          {props.onHide && (
            <span style={{ cursor: "pointer" }} onClick={props.onHide}>
              X
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}

export function DiscardPlanChangeModal({
  nextPaymentDate,
  onClose,
}: DiscardSubscriptionChangeProperties) {
  const { client } = useConfig();
  const { user } = useContext(UserContext);
  const { eligibility, ...member } = useMembership();
  const [isDiscarding, setIsDiscarding] = useState(false);

  const currentSubscription = eligibility.currentSubscription;
  const futureSubscription = eligibility.futureSubscription;
  const personId = member.personId;
  const isDependent = member.eligibilities[0].isDependentToMember;
  const dateOfPlanChange = nextPaymentDate
    ? nextPaymentDate
    : eligibility.memberEffectiveDate;
  const isPayroll = user.isPayroll;
  if (
    !(
      currentSubscription &&
      futureSubscription &&
      personId &&
      dateOfPlanChange &&
      isPayroll !== undefined
    )
  ) {
    return <></>;
  }

  const onDiscard = async () => {
    try {
      setIsDiscarding(true);
      await discardSubscription(
        personId,
        futureSubscription,
        isPayroll,
        isDependent,
        currentSubscription
      );
      showToastMessage("Your plan was successfully updated!", true, client);
      onClose(true);
    } catch {
      onClose(false);
    }
  };

  if (isDiscarding) {
    return (
      <Element style={{ pointerEvents: "none" }}>
        <Modal.Body style={{ minHeight: "160px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      </Element>
    );
  }

  return (
    <Element onHide={() => onClose()}>
      <Modal.Body>
        <p>
          You recently changed your fitness package from{" "}
          <b>{currentSubscription.ratePlanName}</b> to{" "}
          <b>{futureSubscription.ratePlanName}</b>, which is scheduled to take
          place on <b>{moment(dateOfPlanChange).format("MM/DD/YYYY")}</b>. Would
          you like to discard this change?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "white", width: "48%" }}
          onClick={() => onClose()}
        >
          <span style={{ color: "var(--primary)" }}>Close</span>
        </Button>
        <Button variant="primary" style={{ width: "48%" }} onClick={onDiscard}>
          Discard Changes
        </Button>
      </Modal.Footer>
    </Element>
  );
}

export default DiscardPlanChangeModal;
