import React, { useContext, useEffect } from "react";
import { Tile } from "./Tile";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MembershipCard from "../MembershipCard";
import { AccountContext } from "../../../context/AccountProvider";
import { Link } from "react-router-dom";
import digitalPlan from "./assets/digitalPlan.svg";
import { axiosMemberInstance } from "../../../axios.instance";
import { UserContext } from "../../../context/UserProvider";
import { useAuthToken } from "../../shared/Auth0TokenProvider";

const PlanTile = () => {
  const { member, memberCardBaseImage, setAccountDetails } =
    useContext(AccountContext);
  const { user } = useContext(UserContext);
  const token = useAuthToken();
  const program = member?.eligibilities[0].currentSubscription
    ? member!.eligibilities[0].currentSubscription.ratePlanName
    : "";
  useEffect(() => {
    if (memberCardBaseImage === "") {
      axiosMemberInstance
        .get(`/${user.personId}/card`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          if (data) {
            setAccountDetails({ memberCardBaseImage: `${data}` });
          }
        })
        .catch((e) => {
          console.log(e.toJSON());
        });
    }
  }, []);

  const printCard = () => {
    const myWindow: Window | null = window.open("");
    if (myWindow) {
      myWindow.document.write(
        `<img src=data:image/png;base64,${memberCardBaseImage} alt="card"/>`
      );
      setTimeout(() => {
        myWindow.focus();
        myWindow.print();
      }, 10);
      myWindow.document.close();
    }
  };

  const downloadCard = () => {
    const fileName = `membership-card.png`;
    const link = document.createElement("a");
    link.href = "data:image/png;base64," + memberCardBaseImage;
    link.download = fileName;
    document.body.append(link);
    link.click();
  };
  const myPlan = () => {
    switch (program) {
      case "Fitness Pass Base":
        return (
          <>
            <h2 className="mt-3">BASE Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 3k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Pass Core":
        return (
          <>
            <h2 className="mt-3">CORE Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 7k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Pass Power":
        return (
          <>
            <h2 className="mt-3">POWER Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Pass Ultra":
        return (
          <>
            <h2 className="mt-3">ULTRA Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              {/* <li className="package-list-horizontal__package">
                1 Premium Gym/month
              </li> */}
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Pass Digital":
        return (
          <>
            <h2 className="mt-3">Digital Only Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;
      case "Fitness Pass Legacy":
        return (
          <>
            <h2 className="mt-3">POWER Fitness Package</h2>
            <ul className="package-list-horizontal">
              <li className="package-list-horizontal__package">
                Access 11k gyms
              </li>
              <li className="package-list-horizontal__package">
                Premium Digital Content
              </li>
            </ul>
          </>
        );
        break;

      default:
        return (
          <>
            <h2 className="mt-3 align-self-center">No Current Packages</h2>
          </>
        );
        break;
    }
  };

  return (
    <Tile className="widget__card">
      <div className="myPlan-tile">
        <Tile.THeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: ".5rem",
            }}
          >
            <FontAwesomeIcon icon={faClipboardCheck} /> <b>MY PLAN</b>
          </div>
          {member?.eligibilities[0].isPrimary && (
            <Link to="/account/manage" className="a-link">
              <span>Manage Plan</span>
            </Link>
          )}
        </Tile.THeader>
        <Tile.TBody>
          {myPlan()}
          {program !== "Fitness Pass Digital" ? (
            <div>
              <MembershipCard />
              <span className="title mt-3">HOW TO USE YOUR CARD</span>
              <br />
              <span>
                Show this card at any gym in your fitness package network.
              </span>
              <div className="myPlan-tile__buttons mt-3">
                <Button
                  variant="outline-primary"
                  className="btn btn-outline-primary"
                  onClick={() => downloadCard()}
                >
                  <FontAwesomeIcon icon={faDownload} /> Download
                </Button>
                <Button
                  variant="outline-primary"
                  className="btn btn-outline-primary"
                  onClick={() => printCard()}
                >
                  <FontAwesomeIcon icon={faPrint} /> Print
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <img
                src={digitalPlan}
                alt="Woman following yoga class from her laptop"
                width={"44%"}
              />
              <h6>
                <b>Want to add gym access?</b>
              </h6>
              <p>Visit the Manage Plan page to add gym access at any time.</p>
            </div>
          )}
        </Tile.TBody>
      </div>
    </Tile>
  );
};
export default PlanTile;
