import { UserContext } from "../../context/UserProvider";
import React, { useContext, useState } from "react";

import styles from "./MockPanel.module.scss";

export const MockPanel = () => {
  const [opacity, setOpacity] = useState(0.3);
  const { setUser, user } = useContext(UserContext);
  const [resetEnrollment, setResetEnrollment] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updated = user;
    const key = e.target.attributes["name"].nodeValue;

    if (key == "resetEnrollment") {
      setResetEnrollment(e.target.checked);
      //clear local cache and navigate to homepage.
      sessionStorage.removeItem("enrollment-flow");
      sessionStorage.removeItem("pipeline-session-id");
      // history.push("/");
    }
    if (key) {
      updated[key] = e.target.checked;
      setUser(updated);
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpacity(opacity == 0.3 ? 1 : 0.3);
  };

  return (
    <div style={{ opacity }} className={styles.mockpanel}>
      <div
        className={styles["mock-transparency"]}
        onClick={(e) => handleOnClick(e)}
      ></div>
      <div className={styles.mocks}>
        <div>
          <input
            id="isPayroll"
            name="isPayroll"
            checked={user.isPayroll}
            onChange={handleChange}
            type="checkbox"
          />
          <label htmlFor="isPayroll">Payroll</label>
        </div>
        <div>
          <input
            id="resetEnrollment"
            name="resetEnrollment"
            checked={resetEnrollment}
            onChange={handleChange}
            type="checkbox"
          />
          <label htmlFor="resetEnrollment">Reset Enrollment</label>
        </div>
      </div>
    </div>
  );
};
