import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import ModalMap from "../../../resources/ModalMapWalmart.svg";
const UpdateInformation = ({
  show,
  progress,
  onClose,
}: {
  show: boolean;
  progress: number;
  onClose: () => void;
}) => {
  return (
    <Modal show={show} onClose={onClose} centered>
      <Modal.Body>
        <div className="d-flex p-2 flex-column text-center align-items-center">
          <h4>
            <b>Hang Tight!</b>
          </h4>
          <span>We&apos;re updating your information.</span>
          <img
            src={ModalMap}
            height="140"
            width="290"
            alt="map"
            className="m-3"
          />
          {progress > 90 ? (
            <span>Wrapping up...</span>
          ) : (
            <span>Confirming your selections...</span>
          )}
          <div className="w-100 mt-3 mb-2">
            <ProgressBar striped now={progress} />
          </div>
          <p className="mt-4 mb-2">
            Please <b>do not refresh the page</b>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateInformation;
