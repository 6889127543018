import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  faDownload,
  faPrint,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { AccountContext } from "../../context/AccountProvider";
import AccountUpdateFailedBanner from "../../components/account/AccountUpdateFailedBanner";
import AddFamilyMember from "../../components/account/AddFamilyMember";
import ArrowRotateLeftSolid from "../../resources/ArrowRotateLeftSolid.svg";
import BundlesDropDown from "../../components/shared/BundlesDropDown";
import CancelSubscriptionModal from "../../components/account/CancelSubscriptionModal";
import DiscardCancellationModal from "../../components/account/DiscardCancellationModal";
import { DiscardPlanChangeModal } from "../../components/account/EditPlan/DiscardPlanChangeModal";
import FamilyStatusModal from "../../components/wizard/components/FamilyStatusModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterCard from "../../components/wizard/components/FooterCard";
import { IAddressFields } from "../../components/wizard/types";
import NonPayrollCheckoutModal from "../../components/wizard/components/NonPayrollCheckoutModal";
import OutStandingBalanceBanner from "../../components/account/OutStandingBalanceBanner";
import { Payment } from "../../types";
import PayrollDeductionModal from "../../components/wizard/components/PayrollDeductionModal";
import PlanSummaryUpdate from "../../components/account/Billing/PlanSummaryUpdate";
import ProcessingOrderModal from "../../components/wizard/components/ProcessingOrderModal";
import ProfileToast from "../../utility/ProfileToast";
import ProfileToastDiscardPlan from "../../utility/ProfileToastDiscardPlan";
import {
  AccountAddress,
  AccountPayment,
  AccountSummaryMember,
  SubscriptionPreviewResponse,
} from "../../utility/member-portal-api-types";
import { UserContext } from "../../context/UserProvider";
import axiosInstance, { axiosMemberInstance } from "../../axios.instance";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { setAddressQuery } from "../../api/identity/Queries";
import skuMap from "../../utility/skuMap";
import { useConfig } from "../../configuration/useConfig";
import { useContext } from "react";
import useSummary from "../../hooks/useSummary";
import { useMembership } from "../../hooks/useMembership";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ImpersonationContext } from "../../context/ImpersonationProvider";
import useGetScreenSize from "../../hooks/useGetScreenSize";
import { momentStartOfDay } from "../../utility/util";
import { useAuthToken } from "../../components/shared/Auth0TokenProvider";

export enum ADDRESS {
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}

export type AccountCancelRequest = {
  primaryPersonId: number | undefined | null;
  personId: number | undefined | null;
  subscriptionNumber?: string | undefined;
  zuoraAccountNumber?: string | undefined;
};

function useRouterQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function DiscardDisclaimer() {
  const { eligibility } = useMembership();
  const { nextPayment } = useContext(AccountContext);
  const termReasonPattern = /^RS5[0-9]/;
  const termReason = eligibility?.termReason ?? "";

  if (eligibility.isPendingTermination && !termReasonPattern.test(termReason)) {
    if (!eligibility.memberTermDate) {
      throw new Error(
        "Cannot display discard disclaimer without a term date when a cancellation is pending."
      );
    }

    return (
      <div className="manage-plans__discard-disclaimer">
        Your requested plan cancellation is in progress. If you change your mind
        you can <u>discard this cancellation</u> before{" "}
        {new Date(`${eligibility.memberTermDate}Z`).toLocaleDateString()}.
      </div>
    );
  }

  const { currentSubscription, futureSubscription } = eligibility;

  if (
    !futureSubscription ||
    !nextPayment?.due ||
    futureSubscription?.ratePlanChargeId ===
      currentSubscription?.ratePlanChargeId
  ) {
    return <></>;
  }

  return (
    <div className="manage-plans__discard-disclaimer">
      Your requested plan change is in progress. If you change your mind you can{" "}
      <u>discard this change</u> before{" "}
      {new Date(nextPayment.due).toLocaleDateString(undefined, {
        timeZone: "UTC",
      })}
      .
    </div>
  );
}

const ManagePlanUpdate = () => {
  const { client } = useConfig();
  const { user, setUser } = useContext(UserContext);
  const {
    entitlements,
    memberCardBaseImage,
    nextPayment,
    hasPaymentMethods,
    clientStateCode,
    payrollStatus,
    setAccountDetails,
    dependents,
    memberCanCancel,
    refresh,
  } = useContext(AccountContext);
  const { isImpersonated } = useContext(ImpersonationContext);
  const member = useMembership();
  const { isSmallScreen } = useGetScreenSize();
  const q = useRouterQuery();
  const { setPromoCode, summary, promoLoading } = useSummary();
  const currentRatePlanChargeId = member?.eligibilities
    ? member?.eligibilities[0].currentSubscription?.ratePlanChargeId
    : "";

  const futurePlanChargeId = member?.eligibilities
    ? member?.eligibilities[0].futureSubscription?.ratePlanChargeId
    : "";
  const [showNonPayrollCheckoutModal, setShowNonPayrollCheckoutModal] =
    useState<boolean>(false);
  const [openDiscardCancellationModal, setIsDiscardingCancellation] =
    useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [openPayrollModal, setOpenPayrollModal] = useState<boolean>(false);
  const [reloadToggle, setReloadToggle] = useState<boolean>(false);
  const [isDependentEmailLoading, setIsDependentEmailLoading] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [change, setChange] = useState<string | undefined>(
    currentRatePlanChargeId
  );
  const [dependent, setDependent] = useState<AccountSummaryMember[]>([]);
  const [firstNameCurFamMember, setFirstNameCurFamMember] =
    useState<string>("");
  const [lastNameCurFamMember, setLastNameCurFamMember] = useState<string>("");
  const [emailCurFamMember, setEmailCurFamMember] = useState<string>("");
  const [nextPaymentDate, setnextPaymentDate] = useState<string>("");
  const todayFormattedDate = momentStartOfDay().format("MM/DD/YYYY");

  const [promo, setPromoState] = useState("");
  const [promoOpen] = useState(true);
  const [validPromo] = useState("");
  const [currentPromo] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<AccountPayment[] | null>(
    null
  );
  const [defaultpaymentMethod, setDefaultPaymentMethod] =
    useState<AccountPayment | null>(null);

  const [isChange, setIsChange] = useState<boolean>(false);
  const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [proccessingOrder, setProccessingOrder] = useState(false);
  const [disablePlanSelect, setDisablePlanSelect] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [errorSummary, setErrorSummary] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedMemberEmail, setSelectedMemberEmail] = useState<string>("");
  const [selectedMemberName, setSelectedMemberName] = useState<string>("");
  const [selectedMemberLastName, setSelectedMemberLastName] =
    useState<string>("");
  const [isDependent, setIsDependent] = useState<boolean>(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [, setLoadingPayments] = useState(false);
  const [addressChanged, setAddressChanged] = useState<boolean>(false);
  const [isDiscardingPlanChange, setIsDiscardingPlanChange] = useState(false);
  const [filteredDependents, setFilteredDependents] = useState<
    AccountSummaryMember[]
  >([]);
  const initialPreviewData = {
    immediatePaymentSummary: {
      creditAmountWithoutTax: 0,
      creditAmountWithTax: 0,
      creditTaxAmount: 0,
      immediatePaymentDetails: [
        {
          amountWithoutTax: 0,
          amountWithTax: 0,
          chargeName: "",
          consumerId: "",
          chargeType: "",
          serviceStartDate: "",
          serviceEndDate: "",
          tax: 0,
          tierId: 0,
        },
      ],
      immediateTotalPaymentAmountWithoutTax: 0,
      immediateTotalPaymentAmountWithTax: 0,
      immediateTotalPaymentTaxAmount: 0,
      immediatePaymentDiscountAmountWithoutTax: 0,
      immediatePaymentDiscountTaxAmount: 0,
      immediateTotalPaymentAfterDiscountAmountWithoutTax: 0,
      immediateTotalPaymentAfterDiscountAmountWithTax: 0,
      immediateTotalPaymenAfterDiscountTaxAmount: 0,
      immediateTotalPaymentSetUpFee: 0,
      immediateTotalPaymentRecurringFee: 0,
    },
    monthlyPaymentSummary: {
      monthlyPaymentDetails: [
        {
          amountWithoutTax: 0,
          amountWithTax: 0,
          chargeName: "",
          consumerId: "",
          chargeType: "",
          serviceStartDate: "",
          serviceEndDate: "",
          tax: 0,
          tierId: 0,
        },
      ],
    },
  };

  const faEnvelopeIcon = faEnvelope as IconProp;
  const token = useAuthToken();
  const [previewData, setPreviewData] =
    useState<SubscriptionPreviewResponse>(initialPreviewData);
  const { eligibility } = useMembership();
  const termReasonPattern = /^RS5[0-9]/;
  const termReason = eligibility?.termReason ?? "";

  const convertToCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(+amount.toFixed(2));
  };
  const [defaultAddress, setDefaultAddress] = useState<AccountAddress | null>(
    null
  );

  useEffect(() => {
    setDefaultAddress(member.subscriptionAccount?.shipping ?? null);
    setDefaultPaymentMethod(
      member?.subscriptionAccount?.paymentMethods?.find(
        (item) => item.isDefaultPaymentMethod === true
      ) ?? null
    );
    setPaymentMethods(member?.subscriptionAccount?.paymentMethods ?? null);
  }, []);

  useEffect(() => {
    if (q.get("edit_success") === "true") {
      setTimeout(() => {
        ProfileToast(`Your Plan Was Successfully Updated`, true, client);
      }, 250);
    }
  }, []);

  useEffect(() => {
    if (dependents?.length) {
      const dependentsFiltered = dependents?.filter((dep) => {
        let keepDependent = true;
        if (!dep.eligibilities[0].isMembershipActive) {
          keepDependent = false;
        } else if (
          dep.eligibilities[0].isPrimary === true &&
          dep.eligibilities[0].isDependentToMember === false
        ) {
          keepDependent = false;
        }
        return keepDependent;
      });
      setFilteredDependents(dependentsFiltered);
    }
  }, [dependents]);

  useEffect(() => {
    if (member?.eligibilities[0].futureSubscription) {
      setDisablePlanSelect(
        !Object.entries(member?.eligibilities[0].futureSubscription).length ===
          false
      );
    } else if (member?.eligibilities[0].futureSubscription === null) {
      setDisablePlanSelect(false);
    }
    if (isImpersonated && payrollStatus === "Payroll") {
      setDisablePlanSelect(true);
    }
  }, [
    member?.eligibilities[0].futureSubscription,
    isImpersonated,
    payrollStatus,
  ]);

  useEffect(() => {
    if (change !== currentRatePlanChargeId) {
      previewTierSubmit(change);
      setIsChange(true);
    } else {
      setIsChange(false);
      setPreviewData(initialPreviewData);
    }
  }, [change]);

  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const canChangePlan = useMemo(() => {
    const currentPlanChargeId =
      eligibility.currentSubscription?.ratePlanChargeId;
    if (eligibility.isPendingTermination) {
      return false;
    }

    if (!eligibility.futureSubscription) {
      return true;
    }
    // TODO - this checking of the current and future
    // ratePlanChargeIds should be not necessary, but at
    // the time of writing there is an issue where the
    // futureSubscription is not being set to null when
    // discarding a change. This has the same effect.
    if (currentPlanChargeId === futurePlanChargeId) {
      return true;
    }

    const currentTier = parseInt(eligibility.currentSubscription?.tier || "0");
    const futureTier = parseInt(eligibility.futureSubscription?.tier || "0");
    console.log(currentTier < futureTier);
    return currentTier < futureTier;
  }, [eligibility]);

  const previewTierSubmit = (newRatePlanChargeId) => {
    setLoadingPreview(true);
    setErrorSummary(false);
    axiosInstance
      .post(
        "/account/subscriptions/preview",
        {
          members: [
            {
              payrollStatus: user.isPayroll ? "Payroll" : "NonPayroll",
              orderActions: [
                user.isPayroll
                  ? {
                      currentRatePlanChargeId: currentRatePlanChargeId,
                      newRatePlanChargeId: newRatePlanChargeId,
                      type: "SubscriptionUpdate",
                    }
                  : {
                      currentRatePlanChargeId: currentRatePlanChargeId,
                      newRatePlanChargeId: newRatePlanChargeId,
                      currentSubscriptionNumber:
                        member?.eligibilities[0].currentSubscription
                          ?.subscriptionNumber,
                      type: "SubscriptionUpdate",
                    },
              ],
            },
          ],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          const { data } = response;
          setPreviewData(data);
          if (
            data.immediatePaymentSummary.immediatePaymentDetails[0]?.chargeName
          ) {
            setIsUpgrade(true);
          } else {
            setIsUpgrade(false);
          }
          // PENDING
          setLoadingPreview(false);
          setIsFailed(false);
        } else {
          setIsFailed(true);
        }
      })
      .catch(() => {
        setErrorSummary(true);
      });
  };

  const onAdd = () => {
    refresh().then(() => {
      ProfileToast(`Your plan was successfully updated`, true, "success");
    });
    // getAccountSummary();
  };

  const findCurrentFamMember = (email) => {
    return dependents!.find((item) => item.email === email);
  };

  const handleCurrentFamMember = (email) => {
    const cur = findCurrentFamMember(email);
    setFirstNameCurFamMember(cur!.firstName);
    setLastNameCurFamMember(cur!.lastName);
    setEmailCurFamMember(cur!.email);
  };

  // const getAccountSummary = async () => {
  //   await axiosInstance
  //     .get("/account/summary", {
  //       headers: {
  //         accept: "application/json",
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     })
  //     .then(({ data }) => {
  //       if (data) {
  //         setAccountDetails(data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.toJSON());
  //     });
  // };

  type DeleteBody = {
    primaryPersonId: number | undefined | null;
    personId: number | undefined | null;
    subscriptionNumber?: string | undefined;
    zuoraAccountNumber?: string | undefined;
    termDate: string | undefined;
  };

  const findDependent = () => {
    return dependents?.find((item) => {
      return item.email === emailCurFamMember;
    });
  };

  const cancellingMember = () => {
    const body: DeleteBody = {
      primaryPersonId: member?.personId,
      personId: !isDependent ? member!.personId : findDependent()?.personId,
      termDate: nextPaymentDate,
    };
    if (member?.eligibilities[0].isPrimary && !user.isPayroll) {
      body.subscriptionNumber =
        member?.eligibilities[0].currentSubscription?.subscriptionNumber;
      body.zuoraAccountNumber = member?.subscriptionAccount?.accountNumber;
    }

    const cancelMember = async () => {
      await axiosInstance
        .post("/account/cancel", body, {
          headers: {
            accept: "application/json",
          },
        })
        .then(({ data }) => {
          if (data.wasSuccessful) {
            resetAccountAndManagePlan();
            setIsFailed(false);
            ProfileToast(`Your Plan Was Successfully Updated`, true, client);
            setIsRemovingMember(false);
            setOpenRemoveModal(false);
          } else {
            setIsFailed(true);
            setIsRemovingMember(false);
            setOpenRemoveModal(false);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
          setIsRemovingMember(false);
          setOpenRemoveModal(false);
          setIsCancelling(false);
          setIsFailed(true);
        });
    };
    cancelMember();
  };

  const onSuccess = () => {
    setDependent(dependent.filter((item) => item.email !== emailCurFamMember));
    setIsRemovingMember(true);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    cancellingMember();
    setReloadToggle(!reloadToggle);
    setIsDependent(false);
  };

  const findDependentEditEmail = () => {
    return dependents?.find((item) => {
      return item.email === selectedMemberEmail;
    });
  };

  const changeDependentEmail = (newEmail) => {
    return dependents?.find((item) => {
      if (item.email === selectedMemberEmail) {
        item.email = newEmail;
      }
    });
  };

  const handleEmailChange = (newEmail) => {
    setIsDependentEmailLoading(true);
    const dependentId = findDependentEditEmail()?.personId;

    const body = {
      email: newEmail,
    };
    axiosInstance
      .request({
        url: `/account/dependents/${dependentId}`,
        data: body,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsDependentEmailLoading(false);
        setErrorMessage("");
        if (response.status === 200) {
          if (response.data && response.data.outcome) {
            if (response.data.outcome === "NoChanges") {
              setErrorMessage("200-NoChanges");
            } else if (response.data.outcome === "AccountCreated") {
              setIsFailed(false);
              ProfileToast(
                "Account Setup Invite has been successfully sent",
                true,
                client
              );
              changeDependentEmail(newEmail);
              toggleEditModalHandler();
            } else if (response.data.outcome === "EmailChanged") {
              setIsFailed(false);
              ProfileToast(
                "Email has been successfully updated.",
                true,
                client
              );
              changeDependentEmail(newEmail);
              toggleEditModalHandler();
            }
          }
        } else if (response.status === 401 || response.status === 500) {
          setIsFailed(true);
          toggleEditModalHandler();
        } else if (response.status === 409) {
          setErrorMessage("409-Error");
        } else {
          setIsFailed(true);
        }
      })
      .catch((e) => {
        setIsDependentEmailLoading(false);
        console.log(e);
        if (e.response.status === 400) {
          ProfileToast(` Email Is Already In Use`, false, client);
        } else if (e.response.status === 409) {
          setErrorMessage("409-Error");
        } else if (e.response.status === 500 || e.response.status === 401) {
          setIsFailed(true);
          toggleEditModalHandler();
        } else {
          ProfileToast(` An Error Has Ocurred`, false, client);
        }
      });
  };

  const toggleModalHandler = () => {
    setOpenAddModal(!openAddModal);
  };
  const toggleRemoveModalHandler = () => {
    setOpenRemoveModal(!openRemoveModal);
    setIsDependent(false);
  };
  const toggleEditModalHandler = () => {
    setErrorMessage("");
    setOpenEditModal(!openEditModal);
  };

  const toggleDiscardCancellationModalHandler = () => {
    setIsDiscardingCancellation(true);
  };

  const handlePromo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoState(e.target.value);
  };

  const handlePromoSubmit = () => {
    if (summary?.promoCodes.length === 0) {
      setPromoCode(promo);
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== promo) return elem.code;
        })
        .join(",");
      setPromoCode(promo + "," + str);
    }
  };

  const handlePromoRemove = (deletedPromo: string) => {
    if (summary?.promoCodes.length === 1) {
      setPromoCode("");
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== deletedPromo) return elem.code;
        })
        .join(",");
      const removed = str?.replace(/(^,)|(,$)/g, "");
      setPromoCode(removed + "");
    }
  };

  const handleCancelChangeTier = () => {
    setPreviewData(initialPreviewData);
    setIsChange(false);
    setProccessingOrder(false);
    setChange(eligibility.currentSubscription?.ratePlanChargeId);
  };

  const setAddress = async (values: IAddressFields) => {
    if (addressChanged) {
      try {
        setAddressQuery({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          address1: values.street1,
          address2: values.street2,
          city: values.city,
          state: values.state,
          zipCode: values.zip,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const resetAccountAndManagePlan = async () => {
    await axiosInstance("/account/summary", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.data) {
        axiosInstance
          .get(`/account/next-payment-info`, {})
          .then((upcomingResponse) => {
            const newAccountData = {
              ...response.data,
              nextPayment: {
                amount: upcomingResponse.data.amount,
                due: upcomingResponse.data.nextPaymentDate,
                overdue: upcomingResponse.data.isBalanceOverdue,
              },
            };
            setAccountDetails(newAccountData);

            setPreviewData(initialPreviewData);
            setChange(
              response.data.member?.eligibilities[0]?.currentSubscription
                ?.ratePlanChargeId
            );
            setIsChange(false);
            setUser({
              AccountSummaryMember: response.data.member,
            });
            setProccessingOrder(false);
          });
      }
    });
  };

  const handleConfirmChangeTierPayroll = () => {
    setOpenPayrollModal(false);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    setProccessingOrder(true);
    axiosInstance
      .put(
        "/account/subscriptions",
        {
          members: [
            {
              personId: member?.personId,
              clientStateCode: clientStateCode,
              subscriberId: member?.subscriptionAccount?.id,
              payrollStatus: payrollStatus,
              orderActions: [
                {
                  currentRatePlanChargeId: currentRatePlanChargeId,
                  newRatePlanChargeId: change,
                  type: "SubscriptionUpdate",
                },
              ],
            },
          ],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          const { data } = response;

          if (data.wasSuccessful === true) {
            resetAccountAndManagePlan();
          } else {
            setProccessingOrder(false);
            setIsFailed(true);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const handleConfirmChangeTier = (paymentMethodId) => {
    setShowNonPayrollCheckoutModal(false);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    setProccessingOrder(true);
    axiosInstance
      .put(
        "/account/subscriptions",
        {
          members: [
            {
              personId: member?.personId,
              clientStateCode: clientStateCode,
              paymentMethodId: paymentMethodId,
              subscriberId: member?.subscriptionAccount?.id,
              payrollStatus: payrollStatus,
              orderActions: [
                {
                  currentRatePlanChargeId: currentRatePlanChargeId,
                  newRatePlanChargeId: change,
                  currentSubscriptionNumber:
                    member?.eligibilities[0].currentSubscription
                      ?.subscriptionNumber,
                  type: "SubscriptionUpdate",
                },
              ],
            },
          ],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          const { data } = response;
          if (data.wasSuccessful === true) {
            resetAccountAndManagePlan();
          } else {
            setIsFailed(true);
            setProccessingOrder(false);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setChange(e.target.value);
  };

  const handlePaymentChange = (newPaymentMethod: Payment) => {
    setDefaultPaymentMethod(newPaymentMethod);
  };

  useEffect(() => {
    (async () => {
      if (showNonPayrollCheckoutModal) {
        setLoadingPayments(true);
        setLoadingPayments(false);
      }
    })();
  }, [showNonPayrollCheckoutModal]);

  useEffect(() => {
    const fetchData = async () => {
      await axiosInstance
        .get(`/account/next-payment-info`, {})
        .then((response) => {
          setnextPaymentDate(response.data.nextPaymentDate);
        })
        .catch((e) => console.log(e));
    };
    fetchData();
  }, []);

  const handleCardPrint = () => {
    const image = new Image();
    image.src = `data:image/png;base64,${memberCardBaseImage}`;
    const w = window.open("");
    w?.document.write(image.outerHTML);
  };
  const handleDependentCardDownload = (person: AccountSummaryMember) => {
    axiosMemberInstance
      .get(`/${person.personId}/card`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data) {
          const a = document.createElement("a");
          a.href = "data:image/png;base64," + data;
          a.download = `membercard_${person.email}.png`;
          a.click();
        }
      })
      .catch((e) => {
        console.log(e.toJSON());
      });
  };
  const handleDependentCardPrint = (person: AccountSummaryMember) => {
    axiosMemberInstance
      .get(`/${person.personId}/card`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data) {
          const image = new Image();
          image.src = `data:image/png;base64,${data}`;
          const w = window.open("");
          w?.document.write(image.outerHTML);
        }
      })
      .catch((e) => {
        console.log(e.toJSON());
      });
  };

  const cancelAccount = async (account: AccountSummaryMember) => {
    try {
      const body: AccountCancelRequest = {
        primaryPersonId: member?.personId,
        personId: account.personId,
      };

      if (eligibility.isPrimary && !user.isPayroll) {
        body.subscriptionNumber =
          eligibility.currentSubscription?.subscriptionNumber;
        body.zuoraAccountNumber = member?.subscriptionAccount?.accountNumber;
      }

      const { data } = await axiosInstance.post("/account/cancel", body);
      if (data.wasSuccessful) {
        await resetAccountAndManagePlan();
        setIsFailed(false);
        if (member?.personId === account.personId) {
          ProfileToast(`Your Plan Was Successfully Updated`, true, client);
        } else {
          ProfileToastDiscardPlan(
            "Your family member's cancellation was submitted successfully",
            true,
            client
          );
        }
        return;
      }
    } catch (error) {
      console.log("An error occurred cancelling an account:");
      console.error(error);
    } finally {
      setIsCancelling(false);
    }
  };

  const handleCheckout = () => {
    if (user.isPayroll) {
      setOpenPayrollModal(true);
    } else {
      setShowNonPayrollCheckoutModal(true);
    }
  };

  const defaultCardBody = (
    <Row>
      <Col className="manage-plans__member-name" xs={12} lg={5}>
        <h6 className="text-uppercase">Primary Member</h6>
        <div>
          <b>
            {member?.firstName} {member?.lastName}
          </b>
        </div>
        <span className="mt-minus-half-rem" style={{ display: "block" }}>
          <small>{member?.email}</small>
        </span>
      </Col>
      <Col xs={12} lg={7}>
        <h6 className="text-uppercase">Fitness Package</h6>
        <>
          {member.eligibilities[0].isMembershipActive === true ? (
            <Form.Group>
              <Form.Control
                className="package-dropdown"
                as="select"
                value={
                  futurePlanChargeId || change || currentRatePlanChargeId || ""
                }
                onChange={handlePlanChange}
                style={{
                  appearance: "auto",
                  maxWidth: "600px",
                }}
                disabled={
                  disablePlanSelect ||
                  member.eligibilities[0].isPendingTermination === true ||
                  member.eligibilities[0].currentSubscriptionStatus ===
                    "ActiveFutureTerm"
                }
              >
                {member?.eligibilities[0].currentSubscriptionStatus !==
                "Active" ? (
                  <option
                    value={"termedDefault"}
                    key={"termedDefault"}
                    disabled
                  >
                    Please Select a Package
                  </option>
                ) : null}
                <BundlesDropDown
                  planId={futurePlanChargeId || currentRatePlanChargeId}
                />
              </Form.Control>
            </Form.Group>
          ) : (
            <div>
              <FontAwesomeIcon icon={faInfoCircle} /> Account does not have an
              active fitness package
            </div>
          )}
        </>
      </Col>
      {!canChangePlan && <DiscardDisclaimer />}
      {dependent.length > 0 &&
        change !== member?.eligibilities[0].currentSubscription?.ratePlanName &&
        !member?.eligibilities[0].isPendingTermination && (
          <Alert className="primary-member-alert-info" variant="primary">
            <b>Heads up!</b> When changes are made to the primary member's
            fitness package, the changes take place for all family members added
            to your plan
          </Alert>
        )}
    </Row>
  );

  const dependentCardBody = (firstName, lastName, email, dependent) => (
    <Row
      key={email}
      className={
        dependent.eligibilities[0]?.isPendingTermination ? "grayed-out" : ""
      }
    >
      <Col className="manage-plans__member-name" xs={12} lg={5}>
        <h6 className="text-uppercase">Family Member</h6>
        <div>
          <b>
            {firstName} {lastName}
          </b>
        </div>
        <span className="mt-minus-half-rem" style={{ display: "block" }}>
          <small>{email}</small>
        </span>
        <span className="mt-minus-zero2-rem" style={{ display: "block" }}>
          <a
            className={`manage-plans__card__edit-email ${
              isChange ? "disabled-links" : ""
            }`}
            onClick={() => {
              setSelectedMemberEmail(email);
              setSelectedMemberName(firstName);
              setSelectedMemberLastName(lastName);
              toggleEditModalHandler();
            }}
            aria-disabled={isChange}
          >
            <FontAwesomeIcon icon={faEnvelopeIcon} /> Send Account Setup Invite
          </a>
        </span>
      </Col>
      <Col xs={12} lg={7}>
        <h6>FITNESS PACKAGE</h6>
        <h6 style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
          {Object.keys(skuMap).map((key) => {
            if (key === change) {
              const name = skuMap[key].name;
              const className =
                skuMap[key].className !== "digital" ? " + Digital Content" : "";
              return name + className;
            }
          })}
        </h6>
        {dependent.eligibilities[0]?.isPendingTermination ? (
          <div style={{ marginBottom: "5px" }}>
            <b>Plan cancellation in progress</b> <br />
          </div>
        ) : null}
        <p className="mt-minus-half-rem" style={{ fontSize: "0.8rem" }}>
          Managed by primary member's package selection
        </p>
      </Col>
    </Row>
  );

  const noPaymentMethodBody = (
    <Alert variant="info">
      No payment method found. In order to make changes to your account please
      add one <NavLink to="/account/billing">here</NavLink>.
    </Alert>
  );

  const dependentCardFooter = (item) => (
    <Card.Text className="manage-plans__sub-text">
      <ListGroup>
        {!entitlements && (
          <div className="mt-2">
            <h3>
              <i>No current plan</i>
            </h3>
          </div>
        )}
        {entitlements && entitlements.includes("memberCard") && (
          <ListGroup.Item
            variant="secondary"
            className="manage-plans__footer manage-plans__footer__btn"
          >
            <div className="button-container">
              <Button
                variant="outline-primary"
                className="btn w-15 ml-2 btn-outline-primary btn-sm download-card-button"
                onClick={() => handleDependentCardDownload(item)}
                disabled={item.eligibilities[0]?.isPendingTermination}
              >
                <FontAwesomeIcon icon={faDownload} /> Download Card
              </Button>
            </div>
            <div className="button-container">
              <Button
                variant="outline-primary"
                className="btn w-15 ml-2 btn-outline-primary btn-sm print-card-button"
                onClick={() => handleDependentCardPrint(item)}
                disabled={item.eligibilities[0]?.isPendingTermination}
              >
                <FontAwesomeIcon icon={faPrint} /> Print Card
              </Button>
            </div>
            {!member?.eligibilities[0].isPendingTermination ? (
              item.eligibilities[0]?.isPendingTermination ? (
                <div
                  className="manage-plans__discard"
                  onClick={() => {
                    setEmailCurFamMember(item.email);
                    setIsDependent(true);
                    toggleDiscardCancellationModalHandler();
                  }}
                  style={{
                    marginLeft: "auto",
                    paddingLeft: "100px",
                    minHeight: "0px",
                  }}
                >
                  <img
                    alt="arrow"
                    className="manage-plans__discard__back-arrow"
                    src={ArrowRotateLeftSolid}
                    style={{
                      padding: "0",
                      width: "15px",
                    }}
                  />
                  <p style={{ fontSize: "14px" }}>
                    <b>Discard Changes</b>
                  </p>
                </div>
              ) : (
                <span
                  className={`manage-plans__cancel ${
                    isChange || item.eligibilities[0]?.isPendingTermination
                      ? "disabled-links"
                      : ""
                  }`}
                  onClick={() => {
                    toggleRemoveModalHandler();
                    handleCurrentFamMember(item.email);
                    setIsDependent(true);
                  }}
                  style={{
                    marginLeft: "auto",
                    cursor: `pointer`,
                  }}
                >
                  <b style={{ color: `red`, fontSize: "14px" }}>
                    X Remove Member
                  </b>
                </span>
              )
            ) : null}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card.Text>
  );

  const primaryCardFooter = () => (
    <>
      <Card.Text className="manage-plans__sub-text">
        <ListGroup>
          {!entitlements && (
            <div className="mt-2">
              <h3>
                <i>No current plan</i>
              </h3>
            </div>
          )}
          {entitlements &&
            entitlements.includes("memberCard") &&
            member.eligibilities[0].isMembershipActive === true && (
              <ListGroup.Item
                variant="secondary"
                className="manage-plans__footer manage-plans__footer__btn"
              >
                {member.eligibilities[0].currentSubscription?.tier !== "0" && (
                  <>
                    <div className="button-container">
                      <a
                        download="membership_card.png"
                        className={`btn btn-outline-primary btn-sm download-card-button ${
                          member?.eligibilities[0].isPendingTermination
                            ? "disabled-links"
                            : ""
                        }`}
                        href={`data:image/png;base64,${memberCardBaseImage}`}
                      >
                        <FontAwesomeIcon icon={faDownload} /> Download Card
                      </a>
                    </div>
                    <div className="button-container">
                      <Button
                        variant="outline-primary"
                        className="btn w-15 ml-2 btn-outline-primary btn-sm print-card-button"
                        onClick={handleCardPrint}
                        disabled={member?.eligibilities[0].isPendingTermination}
                      >
                        <FontAwesomeIcon icon={faPrint} /> Print Card
                      </Button>
                    </div>{" "}
                  </>
                )}

                {memberCanCancel &&
                !member?.eligibilities[0].isPendingTermination &&
                canChangePlan ? (
                  <span
                    className={`manage-plans__cancel`}
                    onClick={() => setIsCancelling(true)}
                    style={{
                      marginLeft: "auto",
                      cursor: `pointer`,
                    }}
                  >
                    <b style={{ color: `red`, fontSize: "14px" }}>
                      X Cancel My Plan
                    </b>
                  </span>
                ) : !canChangePlan && !termReasonPattern.test(termReason) ? (
                  <div
                    className="manage-plans__discard"
                    onClick={() =>
                      eligibility.isPendingTermination
                        ? setIsDiscardingCancellation(true)
                        : setIsDiscardingPlanChange(true)
                    }
                    style={{
                      marginLeft: "auto",
                      paddingLeft: "100px",
                      minHeight: "0px",
                      cursor: `pointer`,
                    }}
                  >
                    <img
                      alt="arrow"
                      className="manage-plans__discard__back-arrow"
                      src={ArrowRotateLeftSolid}
                      style={{
                        padding: "0",

                        width: "15px",
                      }}
                    />
                    <p style={{ fontSize: "14px" }}>
                      <b>
                        {member?.eligibilities[0].isPendingTermination
                          ? `Discard Changes`
                          : `Discard Changes`}
                      </b>
                    </p>
                  </div>
                ) : null}
              </ListGroup.Item>
            )}
        </ListGroup>
      </Card.Text>
    </>
  );

  const dependentList = filteredDependents?.map((item) => {
    return (
      <Card className="manage-plans__card" style={{ width: "90%" }}>
        <Card.Body>
          <div className="custom-card-content">
            {hasPaymentMethods
              ? dependentCardBody(
                  item.firstName,
                  item.lastName,
                  item.email,
                  item
                )
              : noPaymentMethodBody}
            {!isSmallScreen ? dependentCardFooter(item) : ""}
          </div>
        </Card.Body>
        {isSmallScreen ? dependentCardFooter(item) : ""}
      </Card>
    );
  });

  useEffect(() => {
    if (proccessingOrder && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, proccessingOrder]);

  return (
    <Fragment>
      {nextPayment?.overdue ? (
        <OutStandingBalanceBanner
          amount={nextPayment?.amount}
          due={nextPayment?.due}
        />
      ) : null}
      {openAddModal ? (
        <AddFamilyMember
          open={openAddModal}
          onClose={() => setOpenAddModal(!openAddModal)}
          onAdd={onAdd}
          zipCode={member!.postalCode}
        />
      ) : null}
      {openRemoveModal ? (
        <FamilyStatusModal
          show={openRemoveModal}
          status={openRemoveModal && isRemovingMember ? "LOADING" : "REMOVE"}
          firstName={firstNameCurFamMember}
          lastName={lastNameCurFamMember}
          onDone={onSuccess}
          progress={progress}
          close={toggleRemoveModalHandler}
          date={user.isPayroll ? todayFormattedDate : nextPaymentDate}
        />
      ) : null}
      {openEditModal ? (
        <FamilyStatusModal
          show={openEditModal}
          status="EDIT"
          firstName={selectedMemberName}
          lastName={selectedMemberLastName}
          handleChangeEmail={handleEmailChange}
          close={toggleEditModalHandler}
          email={selectedMemberEmail}
          errorMessage={errorMessage}
          isDependentEmailLoading={isDependentEmailLoading}
        />
      ) : null}
      <div className="manage-plans-description">
        <p className="manage-plans-description__text">
          To change your fitness plan, select a new package from the options
          below, and proceed to checkout.
        </p>
      </div>
      {isFailed && <AccountUpdateFailedBanner />}
      {isImpersonated && payrollStatus === "Payroll" && (
        <div className="csr-tip">
          <small>
            <i>
              <strong>CSR Tip!</strong> You cannot make changes to this member's
              fitness package. Please refer this member to the website to
              complete any package change requests that require payroll
              authorization.
            </i>
          </small>
        </div>
      )}
      <h3 style={{ margin: "1rem 0 1rem 0", paddingLeft: "1rem" }}>
        Current Members
      </h3>
      <Card className="edit-plan">
        <Card.Body>
          <Col xs={12} md="auto" className="edit-plan-column">
            <PlanSummaryUpdate
              currentPlanTotal={convertToCurrency(
                member?.eligibilities[0].currentSubscription?.price
                  ? member?.eligibilities[0].currentSubscription?.price
                  : 0
              )}
              previewData={previewData}
              userTermed={
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "Active" ||
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "ActiveFutureTerm"
                  ? false
                  : true
              }
              loadingPending={loadingPreview}
              promoLoading={promoLoading}
              onPromoChange={handlePromo}
              onPromoRemove={handlePromoRemove}
              onPromoSubmit={handlePromoSubmit}
              errorSummary={errorSummary}
              validPromo={validPromo}
              promoOpen={promoOpen}
              promo={promo}
              currentPromo={currentPromo}
              isUpgrade={isUpgrade}
              isChange
            />
          </Col>
          <div className="d-flex flex-column">
            <div className="mb-4">
              <Card className="manage-plans__card" style={{ width: "90%" }}>
                <Card.Body>
                  {hasPaymentMethods ? defaultCardBody : noPaymentMethodBody}
                  {!isSmallScreen ? primaryCardFooter() : ""}
                </Card.Body>
                {isSmallScreen ? primaryCardFooter() : ""}
              </Card>
              {dependentList}
            </div>
            <Button
              onClick={toggleModalHandler}
              className="manage-plans__button"
              disabled={
                isChange ||
                member.eligibilities[0].isMembershipActive === false ||
                member.eligibilities[0].isPendingTermination === true ||
                member.eligibilities[0].currentSubscriptionStatus ===
                  "ActiveFutureTerm"
              }
              style={{ width: "90%" }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              Add Family Member
            </Button>
          </div>

          {isCancelling && member && (
            <CancelSubscriptionModal
              onClose={() => setIsCancelling(false)}
              onCancel={() => cancelAccount(member)}
              termDate={nextPayment?.due}
            />
          )}
          {isDiscardingPlanChange && (
            <DiscardPlanChangeModal
              nextPaymentDate={nextPaymentDate}
              onClose={async (success) => {
                if (success !== undefined) {
                  await resetAccountAndManagePlan();
                }

                setIsDiscardingPlanChange(false);

                if (success !== undefined) {
                  setIsFailed(!success);
                }
              }}
            />
          )}
          {openDiscardCancellationModal && (
            <DiscardCancellationModal
              member={
                isDependent ? findCurrentFamMember(emailCurFamMember)! : member
              }
              onClose={async (success) => {
                setIsDependent(false);
                if (success !== undefined) {
                  await resetAccountAndManagePlan();
                }

                setIsDiscardingCancellation(false);

                if (success !== undefined) {
                  setIsFailed(!success);
                }
              }}
            />
          )}
          {showNonPayrollCheckoutModal && (
            <NonPayrollCheckoutModal
              show={showNonPayrollCheckoutModal}
              onClose={() => setShowNonPayrollCheckoutModal(false)}
              paymentMethod={defaultpaymentMethod}
              paymentMethods={paymentMethods}
              onPaymentChange={handlePaymentChange}
              shippingAddress={{
                street1: defaultAddress?.address1 ?? "",
                street2: defaultAddress?.address2 ?? "",
                city: defaultAddress?.city ?? "",
                state: defaultAddress?.state ?? "",
                zip: defaultAddress?.postalCode ?? "",
              }}
              total={
                previewData.immediatePaymentSummary
                  ?.immediateTotalPaymentAfterDiscountAmountWithTax
              }
              onAddressEdit={(values) => setAddress(values)}
              onConfirm={(paymentId) => handleConfirmChangeTier(paymentId)}
              setAddressChanged={setAddressChanged}
            />
          )}
        </Card.Body>
      </Card>
      {openPayrollModal && (
        <div
          onClick={() => setOpenPayrollModal(false)}
          style={{
            position: `absolute`,
            top: `0%`,
            right: `0`,
            left: `0`,
            zIndex: `98 !important`,
            background: `black`,
            opacity: `0.5`,
            width: `100%`,
            height: `100%`,
            paddingLeft: `6%`,
            paddingTop: `20px`,
            paddingBottom: `20px`,
            boxShadow: `0px 2px 8px #dddddd`,
            border: "1px solid rgba(0,0,0,.122)",
            borderRadius: 15,
          }}
        ></div>
      )}
      <div
        style={{
          display: openPayrollModal ? `` : `none`,
          transition: `all 10s ease`,
          transform: !openPayrollModal ? `translateY(-100%)` : `translateY(0%)`,
          position: `absolute`,
          top: `30%`,
          right: `0`,
          left: `0`,
          zIndex: `99 !important`,
          background: `white`,
          width: `100%`,
          paddingLeft: `6%`,
          paddingTop: `20px`,
          paddingBottom: `20px`,
          boxShadow: `0px 2px 8px #dddddd`,
          border: "1px solid rgba(0,0,0,.122)",
          borderRadius: 15,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <PayrollDeductionModal
            open={openPayrollModal}
            member={member ? member : null}
            hasRendered={true}
            onCancel={() => setOpenPayrollModal(false)}
            onConfirm={() => handleConfirmChangeTierPayroll()}
            checkoutDisabled={(b) => setCheckoutDisabled(b)}
          />
          <Col xs={12} md="auto" style={{ padding: 0, float: "right" }}>
            <PlanSummaryUpdate
              currentPlanTotal={convertToCurrency(
                member?.eligibilities[0].currentSubscription?.price
                  ? member?.eligibilities[0].currentSubscription?.price
                  : 0
              )}
              previewData={previewData}
              userTermed={
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "Active" ||
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "ActiveFutureTerm"
                  ? false
                  : true
              }
              loadingPending={loadingPreview}
              promoLoading={promoLoading}
              onPromoChange={handlePromo}
              onPromoRemove={handlePromoRemove}
              onPromoSubmit={handlePromoSubmit}
              errorSummary={errorSummary}
              validPromo={validPromo}
              promoOpen={promoOpen}
              promo={promo}
              currentPromo={currentPromo}
              isUpgrade={isUpgrade}
              isChange
            />
          </Col>
        </div>
        <div
          style={{
            display: `flex`,
            justifyContent: `right`,
          }}
        >
          <Button
            variant="outline-primary"
            className="nav-btn-enroll font-weight-bold"
            style={{ whiteSpace: "nowrap", marginRight: `15px` }}
            onClick={() => setOpenPayrollModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="nav-btn-enroll font-weight-bold"
            style={{ whiteSpace: "nowrap", marginRight: `30px` }}
            disabled={checkoutDisabled}
            onClick={() => handleConfirmChangeTierPayroll()}
          >
            Complete Checkout
          </Button>
        </div>
      </div>
      {/* )} */}
      <ProcessingOrderModal
        show={proccessingOrder}
        progress={progress}
        onClose={() => {
          return;
        }}
      />
      {isChange && !openPayrollModal && (
        <FooterCard>
          <Container>
            <Row>
              <Col md={6} style={{ marginLeft: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Button
                    variant="outline-primary"
                    className="nav-btn-enroll font-weight-bold"
                    style={{ whiteSpace: "nowrap", width: "100%" }}
                    onClick={handleCancelChangeTier}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="nav-btn-enroll font-weight-bold"
                    style={{ whiteSpace: "nowrap", width: "100%" }}
                    onClick={handleCheckout}
                    disabled={loadingPreview}
                  >
                    Confirm & Checkout
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </FooterCard>
      )}
    </Fragment>
  );
};

export default ManagePlanUpdate;
