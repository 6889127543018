import React, { useContext, useState } from "react";
import { defaultConfig, DynamicConfig, defaultClientConfig } from "./config";
import axiosInstance from "../axios.instance";
import { ConfigResponse } from "../utility/member-portal-api-types";

interface DynamicConfigContext {
  client: string;
  config: DynamicConfig;
  clientConfig: ConfigResponse;
  setConfig: (newConfig: DynamicConfig) => void;
  getSiteConfig: () => void;
}

export const ConfigContextObject = React.createContext<DynamicConfigContext>({
  client: "walmart",
  config: defaultConfig,
  clientConfig: defaultClientConfig,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfig: () => {},
  getSiteConfig: () => {
    axiosInstance
      .get("/config")
      .then(() => {
        // data.memberCancellations.map((item) => {
        //   item.valueList.map((value) => {
        //     if (value === member?.state) {
        //       // enrollmentFrame.allowedActions.push("CANCEL");
        //     }
        //   });
        // });
      })
      .catch((e) => console.log(e));
  },
});

export const useConfig = () => useContext(ConfigContextObject);

const ConfigContextProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [configState, setConfigState] = useState(defaultConfig);
  const [clientConfigState, setClientConfigState] =
    useState(defaultClientConfig);

  const getClientConfig = () => {
    axiosInstance
      .get("/config")
      .then(({ data }) => {
        setClientConfigState(data);
        // data.memberCancellations.map((item) => {
        //   item.valueList.map((value) => {
        //     if (value === member?.state) {
        //       // enrollmentFrame.allowedActions.push("CANCEL");
        //     }
        //   });
        // });
      })
      .catch((e) => console.log(e));
  };
  return (
    <ConfigContextObject.Provider
      value={{
        client: "walmart",
        config: configState,
        clientConfig: clientConfigState,
        setConfig: setConfigState,
        getSiteConfig: getClientConfig,
      }}
    >
      {children}
    </ConfigContextObject.Provider>
  );
};

export default ConfigContextProvider;
