import React, { useContext } from "react";
import moment from "moment";
import { AccountContext } from "../../context/AccountProvider";
import { Alert } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DependentPendingTerminationBanner = () => {
  const { member } = useContext(AccountContext);

  return (
    <Alert className="account-suspension-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        Attention
      </Alert.Heading>
      <div className="account-suspension-banner__body">
        Our records indicate that the primary account holder has canceled the
        plan. You will have full access to your plan until cancellation
        officially takes effect{" "}
        <b>
          on{" "}
          {moment(member?.eligibilities[0].memberTermDate).format("MM/DD/YYYY")}
        </b>
      </div>
    </Alert>
  );
};

export default DependentPendingTerminationBanner;
