import React, { useEffect, useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../../context/UserProvider";
import axiosInstance from "../../axios.instance";
import { AccountContext } from "../../context/AccountProvider";
import { useHistory } from "react-router-dom";
import useRouteQuery from "../../hooks/useRouteQuery";
import { ImpersonationContext } from "../../context/ImpersonationProvider";
import SomethingWentWrong from "../../pages/SomethingWentWrong";
import { TokenContext } from "./Auth0TokenProvider";

/**
 * requires a valid aggregated enrollment to render children
 */
const AccountRequiredWrapper = ({
  children,
}: {
  children: React.ReactChildren | React.ReactChild;
}) => {
  const {
    user: auth0User,

    isAuthenticated,
  } = useAuth0();
  const { setUser, user } = useContext(UserContext);
  const { setDependentStatus, setAccountDetails } = useContext(AccountContext);
  const { setValues } = useContext(ImpersonationContext);
  // const token = useAuthToken();
  const token = useContext(TokenContext);
  const history = useHistory();

  const query = useRouteQuery();
  const [getAccountSummaryError, setGetAccountSummaryError] = useState(false);
  const [, setErrorMessage] = useState("");

  useEffect(() => {
    let unRestricted = true;
    const getTokens = async () => {
      if (unRestricted && token && auth0User) {
        if (auth0User["http://tivityhealth.com/csrUsername"]) {
          const cId = query.get("consumerId");
          setValues({
            csrUsername: auth0User["http://tivityhealth.com/csrUsername"],
            contactId: auth0User["http://tivityhealth.com/csrEmail"],
            accessToken: token,
            client: "",
            inContactBaseUri: "",
            inContactToken: "",
            maskingEnabled: false,
            sessionId: "",
            siteUrl: "",
          });
          if (cId) {
            const getConsumer = async () => {
              const cId = query.get("consumerId");
              await axiosInstance(`/consumers/${cId}`, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then(({ data }) => {
                  if (data) {
                    // member?.eligibilities[0].currentSubscriptionStatus ===
                    //   undefined;
                    history.push(
                      `/enroll/eligibility?firstName=${data.firstName}&lastName=${data.lastName}&dob=${data.dateOfBirth}&email=${data.email}&subscriberId=${data.subscriberId}&zip=${data.zip}`
                    );
                  }
                })
                .catch((e) => {
                  console.log(e.toJSON());
                });
            };
            getConsumer();
          } else if (auth0User["http://tivityhealth.com/personId"]) {
            await axiosInstance("/account/summary", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }).then((response) => {
              setDependentStatus(
                !response.data.member.eligibilities[0].isPrimary
              );
              setAccountDetails(response.data);
              setUser({
                isSSO: auth0User.sub?.includes("sso"),
                isPayroll: response.data.payrollStatus === "Payroll",
                payrollFrequency: response.data.payrollFrequency,
                AccountSummaryMember: response.data.member,
                personId: auth0User["http://tivityhealth.com/personId"],
                info: {
                  preferredUsername: auth0User.preferred_username,
                  picture: auth0User.picture,
                  email: auth0User.email ?? "",
                  familyName: auth0User.family_name,
                  givenName: auth0User.given_name,
                  sub: auth0User.sub,
                },
                zuoraAccountId: response.data.member.subscriptionAccount
                  ? response.data.member.subscriptionAccount.id
                  : "",
              });
              history.push("/account");
            });
          } else {
            history.push("/enroll/eligibility");
          }
        } else {
          await axiosInstance("/account/summary", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              setDependentStatus(
                !response.data.member.eligibilities[0].isPrimary
              );
              setAccountDetails(response.data);
              setUser({
                isSSO: auth0User.sub?.includes("sso"),
                isPayroll: response.data.payrollStatus === "Payroll",
                AccountSummaryMember: response.data.member,
                payrollFrequency: response.data.payrollFrequency,
                personId: auth0User["http://tivityhealth.com/personId"],
                info: {
                  preferredUsername: auth0User.preferred_username,
                  picture: auth0User.picture,
                  email: auth0User.email ?? "",
                  familyName: auth0User.family_name,
                  givenName: auth0User.given_name,
                  sub: auth0User.sub,
                },
                zuoraAccountId: response.data.member.subscriptionAccount
                  ? response.data.member.subscriptionAccount.id
                  : "",
              });
              history.push("/account");
            })
            .catch(() => {
              // throw Error(err);
              setGetAccountSummaryError(true);
              setErrorMessage(
                "There was an issue getting your account details."
              );
            });
        }
      }
    };

    getTokens().catch((e) => {
      console.log(e);
    });

    return () => {
      unRestricted = false;
    };
  }, [isAuthenticated, auth0User, token]);

  if (getAccountSummaryError) {
    return <SomethingWentWrong backToSafety />;
  } else if (user.personId === undefined) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" role="status" aria-hidden="true" />
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

export default AccountRequiredWrapper;
