export const contentfulConfig = {
  ids: {
    landing: "6OVypRhsNhHQERGfSmOsWQ",
    howItWorks: "4m9fY1sIYjlKihhaptq6UV",
    faq: "7KTkC3l13rWdShRxleBW7C",
    terms: "7skhqnLdNeWQ8pv1G7aP8v",
    privacy: "4GS5M5AwL5IesPfdGYzZa9",
    help: "1k0NIQrJs9kZ1oey5qrOLn",
    attestation: "4d1jYjBUOqMc8REJm2mTq2",
  },
};
