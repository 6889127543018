import { useFormikContext, getIn } from "formik";
import React, { Fragment, useContext, useCallback, useState } from "react";
import { useEffect } from "react";
import { Alert, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import useCheckOrder from "../../../hooks/useCheckOrder";
import { AccountContext } from "../../../context/AccountProvider";
import useIncontact from "../../../hooks/useIncontact";
import useSummary from "../../../hooks/useSummary";
import useZuora from "../../../hooks/useZuora";
import useZuoraPayments from "../../../hooks/useZuoraPayments";
import FooterButtons from "../components/FooterButtons";
import Header from "../components/Header";
import ProcessingOrderModal from "../components/ProcessingOrderModal";
import StatusModal from "../components/StatusModal";
import Summary from "../components/Summary";
import { EligibilityFlow, FormikWizardState } from "../types";
import axiosInstance, { axiosMemberInstance } from "../../../axios.instance";
import { useConfig } from "../../../configuration/useConfig";
import useContentful from "../../../hooks/useContentful";
import { contentfulConfig } from "../../../client";
import styles from "./Checkout.module.scss";
import { axiosPolling } from "axios-polling";
import FailPaymentModal from "../../account/Billing/FailPaymentModal";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { ImpersonationContext } from "../../../context/ImpersonationProvider";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import { useAuthToken } from "../../shared/Auth0TokenProvider";

const Checkout = ({ previous }: { previous?: () => void }) => {
  const [paymentMethod, setPaymentMethod] = useState("CREDIT");
  const [attestationOpen, setAttestationOpen] = useState(true);
  const [attestations, setAttestations] = useState<
    Array<{ priority: number; text: string }>
  >([]);
  const { user } = useContext(UserContext);
  const [, setPaymentToken] = useState(null);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const { error: summaryError } = useSummary();
  const history = useHistory();
  const { values, setFieldValue, errors, touched, handleChange } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();
  const [openModal, setOpenModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);
  const [openSomethingModal, setOpenSomethingModal] = useState(false);
  const { member, clientStateCode } = useContext(AccountContext);
  const { values: impersonationValues } = useContext(ImpersonationContext);
  const [headsUp, setHeadsUp] = useState(false);

  const token = useAuthToken();
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const { success, error, reset } = useCheckOrder();
  const config = useConfig();
  const { getAttetestions } = useContentful(config.config);
  const { isSmallScreen } = useGetScreenSize();
  const {
    isImpersonated,
    loading: csrLoading,
    error: csrError,
    canContinue,
    setMaskDisabled,
    setMaskEnabled,
  } = useIncontact();

  useEffect(() => {
    if (isSmallScreen) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isImpersonated && !values.eligibility?.isPayroll) {
        await setMaskEnabled();
      }
    })();
  }, []);

  const onSuccess = (res) => {
    if (res.refId) {
      setProgress(0);
      setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
      setShow(true);
      setPaymentToken(res.refId);
      let billToContact = values.address?.billing;
      if (values.address?.billing.city === "") {
        billToContact = values.address?.home;
      }
      const soldToContact = values.address?.home;
      if (!member?.personId && values.eligibility?.email) {
        const body = {
          payrollStatus: values.eligibility?.isPayroll
            ? "Payroll"
            : "NonPayroll",
          subscriberId: values.eligibility?.associateIdentificationNumber,
          programCode: values.eligibility?.isPayroll
            ? "WALMART_PAYROLL"
            : "PRIME_LTE",
          paymentMethodId: res.refId, //token for iframe
          clientStateCode: user.clientStateCode,
          csrUsername: isImpersonated
            ? impersonationValues.csrUsername
            : undefined,
          members: [
            {
              billToContact: {
                firstName: values.eligibility?.firstName,
                lastName: values.eligibility?.lastName,
                address1: billToContact?.street1,
                address2: billToContact?.street2,
                city: billToContact?.city,
                postalCode: billToContact?.zip,
                state: billToContact?.state,
              },
              consumerId: values.eligibility?.consumerId,
              lastName: values.eligibility?.lastName,
              firstName: values.eligibility?.firstName,
              dateOfBirth: `${values.eligibility?.year}-${values.eligibility?.month}-${values.eligibility?.day}`,
              isPrimary: true,
              orderActions: [
                {
                  type: "NewAccount",
                  ratePlanChargeIds: [values.package?.ratePlanChargeId],
                },
              ],
              soldToContact: {
                firstName: values.eligibility?.firstName,
                lastName: values.eligibility?.lastName,
                address1: soldToContact?.street1,
                address2: soldToContact?.street2,
                city: soldToContact?.city,
                postalCode: soldToContact?.zip,
                state: soldToContact?.state,
                email: values.eligibility?.email,
              },
            },
          ],
        };

        axiosInstance
          .post("/enrollment", body)
          .then(async (response) => {
            if (response.data) {
              axiosPolling(axiosInstance, {
                retryLimit: 15,
                delay: 5000,
              });

              // create polling
              const { emit, on, off, remove } = axiosInstance.poll({
                url: `/enrollment/status/${response.data.executionId}`,
              });
              // send polling
              // Transmit config will be merged with poll config, transmit config takes precedence
              emit();
              const timer = setTimeout(() => {
                // console.log("This will run after 60 seconds!");
                setShow(false);
                setShowError(true);
                off();
                // remove all on
                remove();
              }, 120000);
              // watch request
              on("response", (response) => {
                if ((response.data.status as unknown as string) == "SUCCESS") {
                  clearTimeout(timer);
                  axiosMemberInstance
                    .get(`/${response.data.data.personId}/card`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then((response) => {
                      values.card = response.data;
                      setFieldValue("checkout.completed", true);
                      history.push({
                        pathname: "/success",
                        state: values,
                      });
                    })
                    .catch((e) => console.log(e)); // WRITE ERROR CODE
                  off();
                  // remove all on
                  remove();
                }
                if ((response.data.status as unknown as string) == "FAILED") {
                  if (
                    !values.eligibility?.isPayroll &&
                    response.data.error === "CommercePaymentError"
                  ) {
                    clearTimeout(timer);
                    setShow(false);
                    setOpenFailModal(true);
                    off();
                    remove();
                  }
                  clearTimeout(timer);
                  setShow(false);
                  setOpenSomethingModal(true);
                  off();
                  remove();
                }
              });
              // watch error
              on("error", (error) => {
                console.log(error);
              });
            }
          })
          .catch((e) => console.log(e));
      } else if (member?.personId && values.eligibility?.email && token) {
        axiosInstance
          .put(
            "/account/subscriptions",
            {
              members: [
                {
                  personId: member?.personId,
                  clientStateCode: clientStateCode,
                  paymentMethodId: res.refId, //token for iframe
                  subscriberId:
                    values.eligibility?.associateIdentificationNumber,
                  payrollStatus: values.eligibility?.isPayroll
                    ? "Payroll"
                    : "NonPayroll",
                  orderActions: [
                    {
                      ratePlanChargeId: values.package?.ratePlanChargeId,
                      type: "SubscriptionNew",
                    },
                  ],
                },
              ],
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setFieldValue("checkout.completed", true);
            history.push("/account");
          })
          .catch((e) => console.log(e));
      }
    } else {
      setShowErrorModal(true);
    }
  };

  const onSubmissionFailed = useCallback(() => {
    setShow(false);
    setShowError(false);
    return;
  }, []);

  const onRequestFailed = useCallback(() => {
    setShow(false);
    setShowError(true);
    return;
  }, []);

  const zuora =
    user.personId && user.zuoraAccountId ? useZuoraPayments : useZuora;

  const zuoraArgs = {
    paymentType: paymentMethod,
    onSuccess,
    onSubmissionFailed,
    onRequestFailed,
  };

  const { render, handleSubmit, hasRendered } = zuora(zuoraArgs);

  useEffect(() => {
    (async () => {
      if (success) {
        localStorage.removeItem("enrollment-flow");
        if (isImpersonated) {
          try {
            await setMaskDisabled();
            sessionStorage.removeItem("enrollment-flow");
            sessionStorage.removeItem("pipeline-session-id");
            history.push("/success");
          } catch (e) {
            setShow(false);
            setShowError(true);
          }
        } else {
          sessionStorage.removeItem("enrollment-flow");
          sessionStorage.removeItem("pipeline-session-id");
          history.push("/success");
        }
      }
      if (error) {
        if (isImpersonated) await setMaskDisabled();
        setShow(false);
        setShowError(true);
      }
    })();
  }, [success, error]);

  useEffect(() => {
    (async () => {
      getAttetestions(contentfulConfig.ids.attestation).then((res) => {
        if (res?.fields?.attestation) {
          const attest: Array<{ priority: number; text: string }> =
            res.fields.attestation.map((att) => {
              return {
                priority: att.fields?.priority as number,
                text: att.fields?.text as string,
              };
            });
          setAttestations(attest);
        }
      });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (show && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, show]);

  useEffect(() => {
    if (!csrLoading) {
      render();
    }
  }, [render, paymentMethod, csrLoading]);

  const payrollSubmit = () => {
    if (isImpersonated) setMaskDisabled();
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
    setShow(true);
    let billToContact = values.address?.billing;
    if (values.address?.billing.city === "") {
      billToContact = values.address?.home;
    }
    const soldToContact = values.address?.home;
    if (!member?.personId && values.eligibility?.email) {
      axiosInstance
        .post("/enrollment", {
          payrollStatus: values.eligibility?.isPayroll
            ? "Payroll"
            : "NonPayroll",
          subscriberId: values.eligibility?.associateIdentificationNumber,
          programCode: values.eligibility?.isPayroll
            ? "WALMART_PAYROLL"
            : "PRIME_LTE",
          clientStateCode: user.clientStateCode,
          csrUsername: isImpersonated
            ? impersonationValues.csrUsername
            : undefined,
          members: [
            {
              billToContact: {
                firstName: values.eligibility?.firstName,
                lastName: values.eligibility?.lastName,
                address1: billToContact?.street1,
                address2: billToContact?.street2,
                city: billToContact?.city,
                postalCode: billToContact?.zip,
                state: billToContact?.state,
              },
              consumerId: values.eligibility?.consumerId,
              lastName: values.eligibility?.lastName,
              firstName: values.eligibility?.firstName,
              dateOfBirth: `${values.eligibility?.year}-${values.eligibility?.month}-${values.eligibility?.day}`,
              isPrimary: true,
              orderActions: [
                {
                  type: "NewAccount",
                  ratePlanChargeIds: [values.package?.ratePlanChargeId],
                },
              ],
              soldToContact: {
                firstName: values.eligibility?.firstName,
                lastName: values.eligibility?.lastName,
                address1: soldToContact?.street1,
                address2: soldToContact?.street2,
                city: soldToContact?.city,
                postalCode: soldToContact?.zip,
                state: soldToContact?.state,
                email: values.eligibility?.email,
              },
            },
          ],
        })
        .then(async (response) => {
          if (response.data) {
            axiosPolling(axiosInstance, {
              retryLimit: 15,
              delay: 5000,
            });

            // create polling
            const { emit, on, off, remove } = axiosInstance.poll({
              url: `/enrollment/status/${response.data.executionId}`,
            });
            // send polling
            // Transmit config will be merged with poll config, transmit config takes precedence
            emit();
            const timer = setTimeout(() => {
              // console.log("This will run after 60 seconds!");
              setShow(false);
              setShowError(true);
              off();
              // remove all on
              remove();
            }, 60000);

            // watch request
            on("request", () => {
              // console.log(config.poling?.count);
            });
            // watch response
            on("response", (response) => {
              if ((response.data.status as unknown as string) == "SUCCESS") {
                clearTimeout(timer);
                axiosMemberInstance
                  .get(`/${response.data.data.personId}/card`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((response) => {
                    values.card = response.data;
                    setFieldValue("checkout.completed", true);
                    history.push({
                      pathname: "/success",
                      state: values,
                    });
                  })
                  .catch((e) => console.log(e));
                off();
                // remove all on
                remove();
              }
              if ((response.data.status as unknown as string) == "FAILED") {
                setShow(false);
                setShowError(true);
                clearTimeout(timer);
              }
            });
            // watch error
            on("error", (error) => {
              console.log(error);
            });
          }
        })
        .catch((e) => console.log(e));
    } else if (member?.personId && values.eligibility?.email && token) {
      axiosInstance
        .put(
          "/account/subscriptions",
          {
            members: [
              {
                personId: member?.personId,
                clientStateCode: clientStateCode,
                subscriberId: values.eligibility?.associateIdentificationNumber,
                payrollStatus: values.eligibility?.isPayroll
                  ? "Payroll"
                  : "NonPayroll",
                orderActions: [
                  {
                    ratePlanChargeId: values.package?.ratePlanChargeId,
                    type: "SubscriptionNew",
                  },
                ],
              },
            ],
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setFieldValue("checkout.completed", true);
          history.push("/account");
        })
        .catch((e) => console.log(e));
    } else {
      setShowErrorModal(true);
    }

    handleSubmit;
  };

  const handleCancelError = () => {
    reset();
    setShowError(false);
    setShowErrorModal(false);
  };
  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };
  const handlePrevious = async () => {
    if (isImpersonated) await setMaskDisabled();
    if (previous) previous();
  };

  const CSRState = () => {
    if (csrLoading) {
      <div className="center-loading">
        <Spinner animation="border" />
      </div>;
    }
    if (csrError) {
      return (
        <div>
          <h5 style={{ color: "red" }}>
            Issue with call recording session. Please contact your
            administrator.
          </h5>
        </div>
      );
    }
    if (!canContinue) {
      return (
        <div>
          <h5>
            Issue with call recording session. Please contact your
            administrator.
          </h5>
        </div>
      );
    }
    return null;
  };
  const handleZuora = () => {
    window.open("https://www.zuora.com/", "_blank");
    setOpenModal(false);
  };

  const handleZuoraCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (isImpersonated) {
      setHeadsUp(true);
    }
  }, []);

  return (
    <div>
      <Row>
        <Col xs={12} md={7}>
          {values.eligibility?.isPayroll ? (
            <>
              <Header
                style={{ margin: "20px 0 20px" }}
                title="Authorization For Payroll Deduction"
                subtitle="Please review the information below and agree to our terms to complete checkout."
              />
              {isSmallScreen && (
                <Col xs={12} md={4} style={{ marginBottom: "10px" }}>
                  <Summary />
                </Col>
              )}
              {headsUp && (
                <Alert className="primary-member-alert-info" variant="primary">
                  <b>Heads up!</b> You cannot agree to payroll authorization on
                  behalf of the member. Please refer the member to the website
                  to complete the enrollment process.
                </Alert>
              )}
              <h5>Personal Info</h5>
              <Card
                style={{
                  marginBottom: 40,
                  boxShadow: "0 2px 8px #ddd",
                  border: "1px solid rgba(0,0,0,.122)",
                  borderRadius: 5,
                }}
              >
                {csrLoading || csrError || !canContinue ? (
                  <Fragment>{CSRState()}</Fragment>
                ) : (
                  <Card.Body
                    className="checkout-card"
                    style={
                      hasRendered ? { padding: "24px" } : { padding: "20px" }
                    }
                  >
                    <Row className="eligibility-form__row">
                      <Form.Group
                        as={Col}
                        controlId="firstName"
                        xs={12}
                        md={6}
                        lg={6}
                        className="mb-3"
                      >
                        <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                          First Name
                          <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          maxLength={35}
                          name="eligibility.firstName"
                          readOnly
                          value={values.eligibility!.firstName}
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="lastName"
                        xs={12}
                        md={6}
                        lg={6}
                      >
                        <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                          Last Name
                          <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          maxLength={60}
                          name="eligibility.lastName"
                          value={values.eligibility!.lastName}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                        Today's Date
                        <span className="required-field-marker">*</span>
                      </Form.Label>
                      <Form.Group
                        as={Col}
                        controlId="month"
                        xs={3}
                        sm={2}
                        md={2}
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="eligibility.month"
                          value={month}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="day" xs={3} sm={2} md={2}>
                        <Form.Control
                          type="text"
                          name="eligibility.day"
                          value={day}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="year" xs={4} md={3}>
                        <Form.Control
                          type="text"
                          name="eligibility.year"
                          value={year}
                          readOnly
                          style={{ pointerEvents: "none" }}
                        />
                      </Form.Group>
                    </Row>
                  </Card.Body>
                )}
              </Card>
              <h5>Agree to Our Terms</h5>
              <Card
                style={{
                  marginBottom: 40,
                  boxShadow: "0 2px 8px #ddd",
                  border: "1px solid rgba(0,0,0,.122)",
                  borderRadius: 5,
                }}
                className="terms-container"
              >
                <Card.Body>
                  <Card.Text>
                    <div className="form-check">
                      <Form.Check
                        inline
                        isInvalid={
                          getIn(touched, "checkout.fitnessPolicy") &&
                          getIn(errors, "checkout.fitnessPolicy")
                        }
                        name="checkout.fitnessPolicy"
                        checked={values.checkout!.fitnessPolicy === true}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, "checkout.fitnessPolicy")}
                      </Form.Control.Feedback>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                        style={{ maxWidth: `85%` }}
                      >
                        By checking this box, I acknowledge that I have read and
                        agree to comply with the terms and conditions of the{" "}
                        {/* <a href="/agreement" target="_blank" className="a-link">
                          Fitness Program Agreement
                        </a> */}
                        <span
                          style={{
                            textDecoration: "underline",
                            // color: "primary",
                            cursor: "pointer",
                          }}
                          onClick={() => setAttestationOpen(!attestationOpen)}
                          className="a-link"
                        >
                          Payroll Deduction{" "}
                        </span>
                        <span className="a-link" style={{ padding: "5px" }}>
                          &and;
                        </span>
                      </label>
                      {attestationOpen ? (
                        <ol type="1" style={{ marginTop: "10px" }}>
                          {attestations.map((attest) => (
                            <li
                              style={{
                                marginBottom: `10px`,
                                fontWeight: "bold",
                                listStyleType: "number",
                              }}
                            >
                              <span style={{ fontWeight: "normal" }}>
                                {attest.text}
                              </span>
                            </li>
                          ))}
                        </ol>
                      ) : null}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          ) : (
            <>
              <Header
                style={{ margin: "20px 0" }}
                title="Add Payment Method"
                subtitle="How would you like to pay? Choose your preferred monthly payment method"
              />
              {isSmallScreen && (
                <Col xs={12} md={4} style={{ marginBottom: "10px" }}>
                  <Summary />
                </Col>
              )}
              <Card>
                {csrLoading || csrError || !canContinue ? (
                  <Fragment>{CSRState()}</Fragment>
                ) : (
                  <Card.Body
                    className="checkout-card"
                    style={
                      hasRendered ? { padding: "24px" } : { padding: "20px" }
                    }
                  >
                    <Row className="eligibility-form__row">
                      <Form.Group className="mb-1">
                        <Form.Label>PAYMENT METHOD</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handlePaymentMethod}
                        >
                          <option value="CREDIT">Credit Card</option>
                        </Form.Control>
                        {!hasRendered && !summaryError && (
                          <div className="zuora-loading">
                            <Spinner animation="border" />
                          </div>
                        )}
                        {summaryError && (
                          <div className="zuora-center-error">
                            <h2>
                              Sorry, there was an error loading this payment
                              page.
                            </h2>
                          </div>
                        )}
                        {!summaryError && !csrError && (
                          <div id="zuora_payment"></div>
                        )}
                        {hasRendered && (
                          <div className={styles["checkout__zuoraText"]}>
                            Powered by{" "}
                            <a
                              className={styles["checkout__zuoraButton"]}
                              onClick={() => setOpenModal(true)}
                            >
                              Zuora
                            </a>
                          </div>
                        )}
                      </Form.Group>
                    </Row>
                  </Card.Body>
                )}
              </Card>
              {openFailModal ? (
                <FailPaymentModal
                  open={openFailModal}
                  onClose={() => {
                    window.location.reload();
                    setOpenFailModal(!openFailModal);
                  }}
                  isEnrollment={true}
                />
              ) : null}
              {openSomethingModal ? <SomethingWentWrong /> : null}
            </>
          )}
        </Col>
        {!isSmallScreen && (
          <Col xs={12} md={4}>
            <Summary />
          </Col>
        )}
      </Row>
      <FooterButtons
        onSubmit={values.eligibility?.isPayroll ? payrollSubmit : handleSubmit}
        onBack={handlePrevious}
        submitDisabled={
          (isImpersonated && values.eligibility?.isPayroll === true) ||
          (!values.eligibility?.isPayroll && !hasRendered) ||
          (!values.eligibility?.isPayroll && error) ||
          (values.eligibility?.isPayroll &&
          (values.checkout?.fitnessPolicy ? false : true)
            ? true
            : false)
        }
        submitText="Complete Checkout"
      />
      <StatusModal
        show={showErrorModal}
        status={"GENERIC-ERROR"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      <ProcessingOrderModal
        show={show}
        progress={progress}
        onClose={() => {
          return;
        }}
      />
      <StatusModal
        show={showError}
        // show={true}
        status={"ERROR-CHECKOUT"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      {openModal && (
        <StatusModal
          show={openModal}
          status={"ZUORA"}
          message=""
          onCancel={handleZuoraCancel}
          onNext={handleZuora}
        />
      )}
    </div>
  );
};

export default Checkout;
