import React from "react";

const Header = ({
  title,
  subtitle,
  ...rest
}: {
  title: string;
  subtitle?: string;
  [x: string]: unknown;
}) => {
  return (
    <div className="header-component" {...rest}>
      <h3>
        <b>{title}</b>
      </h3>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
};

export default Header;
