import { useState } from "react";
import { GeoCode } from "../api/platform/Queries";
import { LocationData } from "../api/platform/Queries";
import { axiosNetworkInstance } from "../axios.instance";

// TODO: authentication
// TODO: Other query parameters
const useGetLocations = ({
  tier,
  amenities,
  radius,
  womenOnlyFilter,
}: {
  tier: string;
  amenities: string[];
  radius: number;
  womenOnlyFilter: boolean;
}) => {
  const [locations, setLocations] = useState<LocationData[]>([]);
  const [distances, setDistances] = useState([]);
  const [geoCode] = useState<GeoCode>({
    latitude: 44.96,
    longitude: -103.77,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getQuery = () => {
    let query = "";
    if (amenities.length > 0) {
      query += "&amenities=" + amenities.join(",");
    }
    if (radius !== 0) {
      query += "&Radius=" + radius;
    }
    if (tier) {
      query += "&TierNumber=" + tier;
    }
    //add female only
    if (womenOnlyFilter !== false) {
      query += "&womenOnlyFilter=" + womenOnlyFilter;
    }
    return query;
  };
  const getLocations = {
    run: async (lat: number, lng: number, token: string) => {
      // encodeURI(params);
      setLoading(true);
      setError(false);
      const query =
        "/locations/search?ProgramCode=prime_lte&Latitude=" +
        lat +
        "&Longitude=" +
        lng +
        encodeURI(getQuery());

      await axiosNetworkInstance
        .get(query, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const distances = response.data.data.list.map(
              (item) => item.distance
            );
            setDistances(distances);

            const locations = response.data.data.list.map(
              (item) => item.location
            );
            setLocations(locations);
          }
        })
        .catch((error) => {
          setLocations([]);
          setError(true);
          console.log(error.toJSON());
        });
    },
    runWithAddress: async (address: string, token: string) => {
      setLoading(true);
      setError(false);
      const query =
        "locations/search?ProgramCode=prime_lte&Address=" +
        address +
        encodeURI(getQuery());

      await axiosNetworkInstance
        .get(query, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const distances = response.data.data.list.map(
              (item) => item.distance
            );
            setDistances(distances);

            const locations = response.data.data.list.map(
              (item) => item.location
            );
            setLocations(locations);
          }
        })
        .catch((error) => {
          setLocations([]);
          setError(true);
          console.log(error.toJSON());
        });
    },
  };

  return {
    locations,
    distances,
    setLocations,
    getLocations,
    geoCode,
    loading,
    error,
    amenities,
    radius,
  };
};

export default useGetLocations;
