import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { Product } from "../../../api/enrollment/Queries";
import { convertToCurrency } from "../../../utility/util";
import YellowLaptop from "../../../resources/YellowLaptopDigitalPackage.svg";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import { useFormikContext } from "formik";
import { EligibilityFlow, FormikWizardState } from "../types";
import { USER_PAYROLL_FREQUENCY } from "../../../context/UserProvider";

const PackageCard = ({
  productInfo,
  name,
  price,
  handleChange,
  selected,
  loading,
}: {
  productInfo?: Product;
  name: string;
  className: string;
  price: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: string;
  loading: boolean;
}) => {
  const { isSmallScreen } = useGetScreenSize();
  const { values } = useFormikContext<FormikWizardState<EligibilityFlow>>();
  return (
    <>
      {loading ? (
        <div className="center-loading">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {productInfo && name !== "Fitness Pass Digital" && (
            <label htmlFor={name}>
              <Card
                className={`card ${
                  productInfo?.isBestValue ? "best-value" : ""
                }`}
              >
                <Card.Body className="body">
                  <Card.Title>{name}</Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    <div className="mb-0 p0 price">
                      {convertToCurrency(price)}
                    </div>
                    <div className="p-0 per-month">
                      {values.eligibility?.isPayroll
                        ? "per paycheck"
                        : "/ month"}
                    </div>
                    <div className="check-button">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name ? { borderColor: "#449D44" } : {}
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={handleChange}
                        />
                        {selected === name ? (
                          <span style={{ color: "#449D44" }}>
                            {" "}
                            <FontAwesomeIcon icon={faCheckCircle} /> Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                    {productInfo.isBestValue ? (
                      <div className="best-value-tag">Most Popular!</div>
                    ) : null}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div
                    style={{
                      width: `100%`,
                      background: `#ffffff`,
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="feature-package-select__gym-count-container">
                      <div className="feature-package-select__location-mark-icon">
                        <svg viewBox="0 0 384 512" width="20">
                          <path
                            fill="#ffc220"
                            d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                          />
                        </svg>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          className="feature-package-select__gym-count"
                          dangerouslySetInnerHTML={{
                            __html: productInfo.description,
                          }}
                        />
                      </div>
                    </div>
                    <hr style={{ marginLeft: `10%`, marginRight: `10%` }} />
                    <div className="feature-package-select__studio-class-reward">
                      <svg viewBox="0 0 15 15" width="22">
                        <path
                          d="M4.069 8.758L.176 4.865a.6.6 0 0 1 0-.847l.847-.847a.6.6 0 0 1 .847 0l2.623 2.623L10.11.177a.6.6 0 0 1 .847 0l.847.847a.6.6 0 0 1 0 .847L4.916 8.758a.6.6 0 0 1-.847 0z"
                          fill="#ffc220"
                        />
                      </svg>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          paddingTop: `3%`,
                        }}
                      >
                        <span>Digital Content</span>
                        <p>Videos & Live Streams</p>
                      </div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </label>
          )}
          {productInfo && name === "Fitness Pass Digital" && !isSmallScreen ? (
            <label htmlFor={productInfo?.name}>
              <div
                className={`digital-content-only-container ${
                  !values.eligibility?.isPayroll
                    ? "digital-content-only-container__non-payroll"
                    : values.eligibility.payrollFrequency ===
                      USER_PAYROLL_FREQUENCY.Weekly
                    ? "digital-content-only-container__weekly"
                    : ""
                } mt-3`}
              >
                <div className="digital-content-only-container__tag">
                  Fitness Pass Digital
                </div>
                <Form.Group></Form.Group>
                <div className="digital-content-only-container__content-row">
                  <div className="digital-content-only-container__price-container">
                    <div
                      className={`digital-content-only-container__price ${
                        !values.eligibility?.isPayroll
                          ? "digital-content-only-container__price__non-payroll"
                          : ""
                      }`}
                    >
                      {values.eligibility?.payrollFrequency ===
                      USER_PAYROLL_FREQUENCY.Weekly
                        ? convertToCurrency(price)
                        : ` $${price}`}
                    </div>
                    <div className="digital-content-only-container__duration">
                      {values.eligibility?.isPayroll
                        ? "per paycheck"
                        : "/ month"}
                    </div>
                  </div>
                  <div className="digital-content-only-container__content-column">
                    <div
                      className={`digital-content-only-container__description${
                        values.eligibility?.isPayroll &&
                        values.eligibility?.payrollFrequency !==
                          USER_PAYROLL_FREQUENCY.Weekly
                          ? "__payroll"
                          : ""
                      }`}
                    >
                      <div className="digital-content-only-container__description-top-line">
                        Instant access to thousands of on-demand videos and live
                        streams.
                      </div>
                      <div className="digital-content-only-container__description-bottom-line">
                        Content includes workouts, wellness videos, nutrition
                        tutorials, and more!
                      </div>
                    </div>
                  </div>
                  <div className="check-button-digital">
                    <label
                      className="btn btn-default"
                      style={
                        selected === name ? { borderColor: "#449D44" } : {}
                      }
                    >
                      <input
                        type="radio"
                        id={name}
                        value={name}
                        name="package.sku"
                        checked={selected === name}
                        onChange={handleChange}
                      />
                      {selected === name ? (
                        <span style={{ color: "#449D44" }}>
                          {" "}
                          <FontAwesomeIcon icon={faCheckCircle} /> Selected
                        </span>
                      ) : (
                        <span>Select</span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </label>
          ) : productInfo &&
            name === "Fitness Pass Digital" &&
            isSmallScreen ? (
            <label htmlFor={name}>
              <Card
                className={`card ${
                  productInfo?.isBestValue ? "best-value" : ""
                }`}
              >
                <Card.Body className="body">
                  <Card.Title>{name}</Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    <div className="mb-0 p0 price">
                      {convertToCurrency(price)}
                    </div>
                    <div className="p-0 per-month">
                      {values.eligibility?.isPayroll
                        ? "per paycheck"
                        : "/ month"}
                    </div>
                    <div className="check-button">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name ? { borderColor: "#449D44" } : {}
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={handleChange}
                        />
                        {selected === name ? (
                          <span style={{ color: "#449D44" }}>
                            {" "}
                            <FontAwesomeIcon icon={faCheckCircle} /> Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div
                    style={{
                      width: `100%`,
                      background: `#ffffff`,
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="feature-package-select__gym-count-container">
                      <img src={YellowLaptop} alt="Yellow Laptop Icon" />
                      {/* <div className="feature-package-select__location-mark-icon">
                        <svg viewBox="0 0 384 512" width="20">
                          <path
                            fill="#ffc220"
                            d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                          />
                        </svg>
                      </div> */}
                      {/* <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          className="feature-package-select__gym-count"
                          dangerouslySetInnerHTML={{
                            __html: productInfo.description,
                          }}
                        />
                      </div> */}
                    </div>
                    <hr style={{ marginLeft: `10%`, marginRight: `10%` }} />
                    <div className="feature-package-select__studio-class-reward">
                      {/* <svg viewBox="0 0 15 15" width="22">
                        <path
                          d="M4.069 8.758L.176 4.865a.6.6 0 0 1 0-.847l.847-.847a.6.6 0 0 1 .847 0l2.623 2.623L10.11.177a.6.6 0 0 1 .847 0l.847.847a.6.6 0 0 1 0 .847L4.916 8.758a.6.6 0 0 1-.847 0z"
                          fill="#ffc220"
                        />
                      </svg> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          paddingTop: `3%`,
                        }}
                      >
                        <span>
                          Instant access to thousands of on-demand videos and
                          live streams.
                        </span>
                        {/* <p>Videos & Live Streams</p> */}
                      </div>
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </label>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default PackageCard;
