import React, { Fragment, useEffect, useState } from "react";
import Section from "../components/terms/Section";
import { ProgramAgreementType, ProgramAgreementSection } from "../types";
import { convertToAgreement } from "../utility/contentful";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";

const FitnessProgramAgreement = ({ home }: { home: string }) => {
  const [content, setContent] = useState<ProgramAgreementType | null>(null);
  const config = useConfig();
  const { getProgramAgreement } = useContentful(config.config);
  useEffect(() => {
    (async () => {
      getProgramAgreement().then((res) => {
        if (res) {
          setContent(convertToAgreement(res.items[0]));
        }
      });
    })();
    // eslint-disable-next-line
  }, []);
  if (!content) {
    return null;
  }
  return (
    <Fragment>
      <p>
        <a href={home} className="a-link">
          Home
        </a>
        {" > "}Fitness Program Agreement
      </p>
      {/* <h1>PRIVACY POLICY AND TERMS OF USE</h1> */}
      {/* <span>Last updated: {content.lastUpdated}</span> */}
      {content.sections.map((section: ProgramAgreementSection) => (
        <Section agreement={true} {...section} key={section.header} />
      ))}
    </Fragment>
  );
};
export default FitnessProgramAgreement;
