import React from "react";
import { Navbar } from "react-bootstrap";
import Logo from "../../resources/walmart.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({
  logo,
  pbLogo,
  second,
  children,
  id,
  enrollment,
}: {
  logo?: boolean;
  pbLogo?: boolean;
  second?: boolean;
  children?: React.ReactElement[] | React.ReactChild | undefined;
  id?: boolean;
  enrollment?: boolean;
}) => {
  return (
    <Navbar
      collapseOnSelect
      className={`app-navbar ${second ? "second" : ""} ${
        id ? "app-navbar-account" : ""
      }`}
      bg="white"
      expand="lg"
      sticky="top"
    >
      {!enrollment && id ? (
        <Navbar.Toggle aria-controls="basic-navbar-nav-2" as="div">
          <div className="toggle-open" style={{ color: "white" }}>
            <div className="toggle-open d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faBars} />
              <div className="app-navbar__toggle-text">menu</div>
            </div>
          </div>
          <div className="toggle-close" style={{ color: "white" }}>
            <div className="toggle-close d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faTimes} />
              <div className="app-navbar__toggle-text">close</div>
            </div>
          </div>
        </Navbar.Toggle>
      ) : !enrollment ? (
        <Navbar.Toggle aria-controls="basic-navbar-nav-2" as="div">
          <div className="toggle-open">
            <div className="toggle-open d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faBars} />
              <div className="app-navbar__toggle-text">menu</div>
            </div>
          </div>
          <div className="toggle-close">
            <div className="toggle-close d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faTimes} />
              <div className="app-navbar__toggle-text">close</div>
            </div>
          </div>
        </Navbar.Toggle>
      ) : (
        ""
      )}
      {logo && (
        <>
          <Navbar.Brand href="/" className="order-lg-first ">
            <img
              alt="logo"
              src={
                id
                  ? "/Tivity_fitness_pass_white_512.png"
                  : "/Tivity_fitness_pass_full_512.png"
              }
              width="140"
              className="d-inline-block align-top img-fluid"
            />
          </Navbar.Brand>
        </>
      )}
      {pbLogo && (
        <>
          <Navbar.Brand href="/" className="order-lg-first">
            <img
              alt="logo"
              src={Logo}
              width="180"
              className="d-inline-block align-top d-md-none"
            />
          </Navbar.Brand>
        </>
      )}
      <Navbar.Collapse id="basic-navbar-nav-2">{children}</Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
