import moment from "moment";
import { POLICY_TYPES } from "../components/wizard/model/InitialState";
import { UserInfo } from "../context/UserProvider";
import { ImmediatePaymentPackages } from "../context/SummaryProvider";

export const getApiUrl = async () => {
  try {
    const res = await fetch("/boot.json");
    const json = await res.json();
    return json.apiUrl;
  } catch (e: unknown) {
    return Promise.reject();
  }
};
export const getApiUrlNew = async () => {
  try {
    const res = await fetch("/boot.json");
    const json = await res.json();
    return json.apiUrlNew;
  } catch (e: unknown) {
    return Promise.reject();
  }
};

export const fetchWrapper = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
  apiUrlNew?: boolean
): Promise<Response> => {
  const { headers, ...rest } = init ?? { headers: {} };
  let nh = {
    ...headers,
  };
  const tokenObj = sessionStorage.getItem("authkit.storage.tokens");
  let token = "";
  if (tokenObj) {
    const obj = JSON.parse(tokenObj);
    token = obj.accessToken;
  }
  if (token !== "") {
    nh = {
      ...nh,
      Authorization: `Bearer ${token}`,
    };
  }
  let api = sessionStorage.getItem("api-url");
  let apiNew = sessionStorage.getItem("api-url-new");
  if (api === null) {
    try {
      const res = await getApiUrl();
      if (res) {
        sessionStorage.setItem("api-url", res);
        api = res as string;
      } else {
        api = "";
      }
    } catch (e) {
      api = "";
    }
  }
  if (apiNew === null) {
    try {
      const res = await getApiUrlNew();
      if (res) {
        sessionStorage.setItem("api-url-new", res);
        apiNew = res as string;
      } else {
        apiNew = "";
      }
    } catch (e) {
      apiNew = "";
    }
  }
  const nInit: RequestInit = {
    ...rest,
    headers: nh,
  };
  if (apiUrlNew) {
    return fetch(apiNew + input, nInit);
  } else {
    return fetch(api + input, nInit);
  }
};

/*
Credit: https://stackoverflow.com/questions/34436133/pdf-is-blank-when-downloading-using-javascript/45669785#45669785
*/
export function pdfDownloadFromBase64(base64Data: string, date: string) {
  const arrBuffer = base64ToArrayBuffer(base64Data);

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([arrBuffer], { type: "application/pdf" });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  const link = document.createElement("a");
  document.body.appendChild(link); //required in FF, optional for Chrome
  link.href = data;
  link.download = `invoice-${date}.pdf`;
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
}

function base64ToArrayBuffer(data: string): Uint8Array {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const convertToCurrency = (amount: number | undefined) => {
  if (!amount) return "$0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const convertToDecimal = (amount: number | undefined) => {
  if (!amount) return "0.00";
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
 };

export const convertToOneTimePackageDetails = (
  packages: Array<ImmediatePaymentPackages>
) => {
  // let pDetails: { subtotal: number; name: string }[];
  const pDetails = packages.map((p) => {
    return { name: p.chargeName, subtotal: p.amountWithoutTax };
  });
  return pDetails;
};

/** maps state containing terms booleans to array of strings */
export const termsConditionsMap = (
  values: Record<string, unknown>
): Array<POLICY_TYPES> => {
  const ret = new Array<POLICY_TYPES>();

  Object.keys(values).forEach((k) => {
    switch (k) {
      case "privacyPolicy":
        if (values[k] === true) {
          ret.push(POLICY_TYPES.PRIVACY);
        }
        break;
      case "fitnessPolicy":
        if (values[k] === true) {
          ret.push(POLICY_TYPES.FITNESS);
        }
        break;
      case "contactInfo":
        if (values[k] === true) {
          ret.push(POLICY_TYPES.CONTACT);
        }
        break;
      default:
        break;
    }
  });

  return ret;
};
/**
 *
 * @param existing
 * @returns merged userinfo object with session storage object when present, or null
 */
export const userinfoWrapper = (existing: UserInfo): UserInfo | undefined => {
  if (existing) {
    const userinfoModified = sessionStorage.getItem("userinfoModified");
    if (userinfoModified) {
      const userinfoModifiedJSON = JSON.parse(userinfoModified);
      return { ...existing, ...userinfoModifiedJSON };
    }
    return { ...existing };
  }
  return undefined;
};

export const momentStartOfDay = (date?: string | moment.MomentInput) => {
  return moment(date).startOf("day");
};

/**
 *
 * @param predicates - array of predicates.
 * @returns boolean - and's together all predicates
 */
export const showBanner = ({
  predicates,
}: {
  predicates: (() => boolean)[];
}) => {
  return predicates.reduce<boolean>((a, b) => {
    return a && b();
  }, true);
};
