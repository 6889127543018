// Need to disable eslint for button type as it doesn't support dynamic
// types values
// https://github.com/yannickcr/eslint-plugin-react/issues/1555
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "react-jss";

import styles from "./styles";

const Button = React.forwardRef(
  (
    {
      classes,
      children,
      className,
      size,
      variant,
      isLink,
      isFullWidth,
      isActive,
      isLoading,
      type,
      // onMouseEnter,
      // onMouseLeave,
      // onFocus,
      // onBlur,
      as,
      ...props
    },
    ref
  ) => {
    // TODO: Replace with loading indicator
    // const content = isLoading ? 'Loading…' : { children }
    // const { setIsHovering } = useContext(ButtonContext)

    const buttonClasses = classNames(className, classes.button, {
      // Variants
      [classes.primary]: !isLink && variant === "primary",
      [classes.secondary]: !isLink && variant === "secondary",
      [classes.light]: !isLink && variant === "light",
      [classes.dark]: !isLink && variant === "dark",
      [classes.success]: !isLink && variant === "success",
      [classes.warning]: !isLink && variant === "warning",
      [classes.danger]: !isLink && variant === "danger",
      [classes.dangerOutline]: !isLink && variant === "dangerOutline",
      [classes.info]: !isLink && variant === "info",
      [classes.link]: isLink,
      [classes.linkPrimary]: isLink && variant === "primary",
      [classes.linkSecondary]: isLink && variant === "secondary",
      [classes.linkLight]: isLink && variant === "light",
      [classes.linkDark]: isLink && variant === "dark",
      [classes.linkSuccess]: isLink && variant === "success",
      [classes.linkWarning]: isLink && variant === "warning",
      [classes.linkDanger]: isLink && variant === "danger",
      [classes.linkInfo]: isLink && variant === "info",
      // Sizes
      [classes.large]: size === "large",
      [classes.medium]: size === "medium",
      [classes.small]: size === "small",
      // Misc
      [classes.isActive]: isActive,
      [classes.isLoading]: isLoading,
      [classes.isFullWidth]: isFullWidth,
      [classes.isInline]: isLink === "inline",
    });

    let Component = "button";
    if (props.href) Component = "a";
    if (as) Component = as;

    // Make sure we pass the default button type
    // if not a link or custom component
    if (!as && !props.href && !props.to) {
      // eslint-disable-next-line no-param-reassign
      props.type = type;
    }

    return (
      <Component
        {...props}
        ref={ref}
        className={buttonClasses}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        // onFocus={handleFocus}
        // onBlur={handleBlur}
      >
        {children}
      </Component>
    );
  }
);

Button.defaultProps = {
  size: "medium",
  variant: "default",
  type: "button",
  isLoading: false,
  isActive: false,
  isLink: false,
  href: null,
  to: null,
  isFullWidth: false,
};

Button.propTypes = {
  /**
   * Actual size of the button
   */
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"]),
  /**
   * Visual style of the button
   */
  variant: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "light",
    "dark",
    "success",
    "warning",
    "danger",
    "dangerOutline",
    "info",
  ]),
  /**
   * Only need to supply "submit" if using a submit button on a form.
   *
   * Don't ever use "reset" unless you have a really good reason.
   */
  type: PropTypes.oneOf(["button", "submit", "reset", null]),
  /**
   * Automatically switches to use a <a> link
   */
  href: PropTypes.string,
  /**
   * Automatically switches to use a Gatsby Link
   */
  to: PropTypes.string,
  /**
   * Applies active styles
   */
  isActive: PropTypes.bool,
  /**
   * Swaps text for a loading indicator
   */
  isLoading: PropTypes.bool,
  /**
   * Makes button take up full width of it's parent
   * container
   */
  isFullWidth: PropTypes.bool,
  /**
   * Makes button/link visually appear as a link
   */
  isLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["inline"])]),
  /** Pass custom elements instead of button, anchors, or Gatsby Links */
};

Button.displayName = "Button";

export default withStyles(styles)(Button);
