import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
export declare type ToastType = "info" | "success" | "error";
import { ToastOptions } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
interface Props {
  message: string;
  success: boolean;
  client: string;
}

const Toast: React.FC<Props> = (props) => {
  return (
    <div
      className={`d-flex flex-row  Toastify__toast--${
        props.success ? "success" : "error"
      }`}
    >
      <div className="icon-p">
        {props.success ? (
          <FontAwesomeIcon icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon icon={faExclamationCircle} />
        )}
      </div>
      <div className="d-flex flex-column ml-2 flex-fill">
        <p className="m-0 align-self-start">{props.message}</p>
        {props.success ? null : (
          <p className="m-0 align-self-start text-primary">
            If this problem persists, please call{" "}
            <a
              href={`tel:${
                props.client === "bsca" ? `1-888-502-0867` : `1-888-572-7833`
              }`}
            >
              {props.client === "bsca" ? `1-888-502-0867` : `1-888-572-7833`}
            </a>
          </p>
        )}
        <div className="d-flex flex-row align-self-end">
          <button
            onClick={() => toast.dismiss()}
            className={`p-0 border-0 bg-white text-primary text-underline-primary ${
              props.success && "font-weight-bold"
            }`}
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  );
};
const toastOptions: ToastOptions = {
  position: "bottom-right",
  hideProgressBar: true,
  closeButton: false,
  // autoClose: false,
  autoClose: 5000,
  closeOnClick: false,
};

const ProfileToastDiscardPlan = (
  successMessage: string,
  success: boolean,
  client: string
) => {
  toast(
    <Toast message={successMessage} success={success} client={client} />,
    toastOptions
  );
};

export default ProfileToastDiscardPlan;
