import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosInstance from "../axios.instance";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
/**
 *
 * @description requires a token
 */
const SSO = () => {
  const [error, setError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  type SSOResponse = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    zip: string;
    subscriberId: string;
    isDependent: boolean;
    groupId: string;
    createLogin: boolean;
  };
  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({ cacheMode: "off" });
        axiosInstance
          .post<SSOResponse | string>(
            "/sso",
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data === "") {
                // person id is populated and should get the token again from auth0 without usig cache
                getAccessTokenSilently({ cacheMode: "off" }).then(() => {
                  history.push("/account");
                });
              } else {
                const data = response.data as SSOResponse;
                history.push(
                  `/enroll/eligibility?isSSO=true&firstName=${data.firstName}&lastName=${data?.lastName}&dob=${data.dateOfBirth}&email=${data.email}&subscriberId=${data.subscriberId}&zip=${data.zip}`
                );
              }
            }
          })
          .catch((e) => {
            console.log({ e });
          });
      } catch (e) {
        console.error(e);
        setError(true);
      }
    })();
  }, [getAccessTokenSilently]);

  if (error) throw new Error();

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" role="status" aria-hidden="true" />
    </div>
  );
};

export default SSO;
