import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import Header from "../components/Header";
import PackageCard from "../components/PackageCard";
import { EligibilityFlow, FormikWizardState } from "../types";
import Locations from "../components/Locations";
import { ErrorBoundary } from "react-error-boundary";
import { useState } from "react";
import StatusModal from "../components/StatusModal";
import FooterButtons from "../components/FooterButtons";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountProvider";
import { useEffect } from "react";
import { ProductBundleContext } from "../../../context/ProductBundleProvider";
import { Spinner, Modal } from "react-bootstrap";
import useGetPrograms from "../../../hooks/useGetPrograms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import LocationsPage from "../../../pages/Locations";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import {
  PROGRAM_CODE,
  USER_PAYROLL_FREQUENCY,
} from "../../../context/UserProvider";

export type Product = {
  id: string;
  billingPeriod: string;
  credits: number;
  description: string;
  isBestValue: boolean;
  name: string;
  recurringPrice: number;
  recurringRatePlanChargeId: string;
  setupFee: number;
  setupRatePlanChargeId: string;
  tier: number;
};

const Package = ({ next }: { next?: () => void }) => {
  const { values, errors, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();
  const { loading: loadingBundles } = useContext(ProductBundleContext);
  const [show, setShow] = useState(false);
  const [primeProductRatePlans, setPrimeProductRatePlans] = useState<Product[]>(
    []
  );
  const [programCode, setProgramCode] = useState("");
  const [openMap, setOpenMap] = useState(false);

  const { member, acceptedTerms, payrollStatus, payrollFrequency } =
    useContext(AccountContext);
  const { isSmallScreen } = useGetScreenSize();
  const [isPayroll, setIsPayroll] = useState<boolean>();

  const { error, loading, productRatePlans } = useGetPrograms(
    true,
    member?.personId && !member.eligibilities[0].isDependentToMember
      ? isPayroll
      : values.eligibility?.isPayroll,
    member?.personId && !member.eligibilities[0].isDependentToMember
      ? (payrollFrequency as USER_PAYROLL_FREQUENCY)
      : (values.eligibility?.payrollFrequency as USER_PAYROLL_FREQUENCY)
  );
  const faMapIcon = faMap as IconProp;

  const logError = (error: Error) => {
    // Do something with the error, e.g. log to an external API
    console.log({ error });
  };

  useEffect(() => {
    if (isSmallScreen) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (values.eligibility?.payrollStatus) {
      if (
        values.eligibility?.payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly
      )
        setProgramCode(PROGRAM_CODE.WalmartPayrollWeekly);
      else if (
        values.eligibility?.payrollFrequency === USER_PAYROLL_FREQUENCY.BiWeekly
      )
        setProgramCode(PROGRAM_CODE.WalmartPayrollBiWeekly);
      else {
        setProgramCode(PROGRAM_CODE.NotApplicable);
      }
    } else {
      setProgramCode(PROGRAM_CODE.PrimeLTE);
    }
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      setPrimeProductRatePlans(productRatePlans);
    }
  }, [programCode, loading]);

  useEffect(() => {
    if (member?.personId && !member.eligibilities[0].isDependentToMember) {
      const {
        firstName,
        lastName,
        dateOfBirth,
        email,
        eligibilities,
        postalCode,
        consumerId,
      } = member;
      const dob = new Date(dateOfBirth || 0);
      const year = dob.getFullYear();
      const month = ("0" + (dob.getMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getDate()).slice(-2).toString();
      setFieldValue("eligibility.firstName", firstName);
      setFieldValue("eligibility.lastName", lastName);
      setFieldValue("eligibility.isPayroll", payrollStatus === "Payroll");
      setFieldValue("eligibility.payrollFrequency", payrollFrequency);
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.month", month);
      setFieldValue("eligibility.privacyPolicy", acceptedTerms);
      setFieldValue(
        "package.sku",
        eligibilities[0]?.currentSubscription?.ratePlanName
      );
      setFieldValue("eligibility.consumerId", consumerId);
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        eligibilities[0]?.clientMemberId
      );
      setFieldValue("eligibility.email", email);
      setFieldValue(
        "eligibility.subscriberId",
        eligibilities[0]?.clientMemberId
      );
      setFieldValue("eligibility.zip", postalCode);
      setFieldValue("package.isReenrolled", false);
      setFieldValue("package.isCancelled", false);
      if (member.eligibilities[0].termReason === "RS50") {
        setFieldValue("eligibility.isPayroll", false);
      }
      if (member.eligibilities[0].currentSubscriptionStatus === "Cancelled") {
        setFieldValue("package.isCancelled", true);
      }
      setIsPayroll(payrollStatus === "Payroll");
    }
  }, [member]);

  useEffect(() => {
    setFieldTouched("package");
  }, [values]);

  const handleSubmit = () => {
    const plan = primeProductRatePlans.filter(
      (plan) => plan.name === values.package?.sku
    );
    setFieldValue("package.sku", values.package?.sku);
    setFieldValue(
      "package.ratePlanChargeId",
      plan[0].recurringRatePlanChargeId
    );
    setFieldValue("package.completed", true);
    if (next) {
      next();
    }
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <>
      <Fragment>
        <StatusModal
          show={show}
          status={"ERROR"}
          message=""
          onCancel={handleCancel}
          onNext={() => {
            return;
          }}
        />
        <div className="package-step-container row">
          <div className="package-selection-container col">
            <Header
              title="What fitness package are you interested in?"
              subtitle={
                !isSmallScreen ? "Please confirm your selection below." : ""
              }
            />
            {isSmallScreen && (
              <span style={{ marginBottom: "10px" }}>
                <a
                  style={{
                    cursor: "pointer",
                    color: "#0071CE",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setOpenMap(true);
                  }}
                >
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faMapIcon} />
                  </span>{" "}
                  View Map
                </a>
              </span>
            )}
            {loading ? (
              <div className="d-flex justify-content-center align-items-center m-2">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="package-card-container row">
                {primeProductRatePlans.map((item) => {
                  if (item.name !== "Fitness Pass Digital") {
                    return (
                      <div className={`${item.name} col mb-4`} key={item.name}>
                        <PackageCard
                          productInfo={item}
                          price={item.recurringPrice ?? 0}
                          handleChange={handleChange}
                          selected={values.package!.sku}
                          loading={loadingBundles}
                          name={item.name}
                          className={item.name}
                        />
                      </div>
                    );
                  }
                })}
                {primeProductRatePlans.map((item) => {
                  if (item.name === "Fitness Pass Digital") {
                    return (
                      <div
                        className={`${item.name} ${
                          isSmallScreen ? "col mb-4" : "row"
                        }`}
                        key={item.name}
                      >
                        <PackageCard
                          productInfo={item}
                          price={item.recurringPrice ?? 0}
                          handleChange={handleChange}
                          selected={values.package!.sku}
                          loading={loadingBundles}
                          name={item.name}
                          className={item.name}
                        />
                      </div>
                    );
                  }
                  // }
                })}
              </div>
            )}
          </div>
          <div
            className="map-container col-4 justify-content-center align-items-center d-none d-lg-flex"
            style={{}}
          >
            <ErrorBoundary
              FallbackComponent={SomethingWentWrong}
              onError={logError}
            >
              <>
                <Locations
                  packageSelected={
                    values.package?.sku === undefined ||
                    values.package?.sku === ""
                      ? "2"
                      : values.package?.sku === "Fitness Pass Digital"
                      ? "0"
                      : values.package?.sku === "Fitness Pass Core"
                      ? "1"
                      : values.package?.sku === "Fitness Pass Power"
                      ? "2"
                      : values.package?.sku === "Fitness Pass Ultra"
                      ? "3"
                      : values.package!.sku
                  }
                />
              </>
            </ErrorBoundary>
          </div>
        </div>
        <FooterButtons
          onSubmit={handleSubmit}
          //Back button might not be needed at this point
          // onBack={user.status !== USER_STATUS.ELIGIBLE ? previous : previous}
          submitDisabled={errors.package !== undefined}
          submitText={"Save & Proceed"}
          packageStyling={true}
        />
      </Fragment>
      {openMap && (
        <>
          <Modal
            show={openMap}
            // onHide={onClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>Location Search</Modal.Title>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenMap(false);
                }}
              >
                X
              </span>
            </Modal.Header>

            <Modal.Body style={{ marginTop: "-25px" }}>
              <LocationsPage
                packageSelected={
                  values.package?.sku === undefined ||
                  values.package?.sku === ""
                    ? "2"
                    : values.package?.sku === "Fitness Pass Digital"
                    ? "0"
                    : values.package?.sku === "Fitness Pass Core"
                    ? "1"
                    : values.package?.sku === "Fitness Pass Power"
                    ? "2"
                    : values.package?.sku === "Fitness Pass Ultra"
                    ? "3"
                    : values.package!.sku
                }
              ></LocationsPage>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Package;
