import React, { useContext, useEffect, ReactNode } from "react";
import { TokenContext } from "./Auth0TokenProvider";
import axiosInstance from "../../../src/axios.instance";

interface AxiosInterceptorProps {
  children: ReactNode;
}

const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({ children }) => {
  const token = useContext(TokenContext);

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [token]);

  return <>{children}</>;
};

export default AxiosInterceptor;
