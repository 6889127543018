import React, { useContext, useRef } from "react";
import Tippy from "@tippy.js/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useTheme } from "react-jss";
import Collapse, { Panel } from "rc-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import useStyles from "./styles";
import { LocationsContext } from "../../../../../context/LocationsProvider";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

// const HomeGymIcon = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="18px"
//       margin="-6px 5px 0 0"
//       viewBox="0 0 782.208 752"
//     >
//       <path
//         id="Combined_Shape"
//         data-name="Combined Shape"
//         d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
//         fill="#fff"
//       />
//     </svg>
//   );
// };
const Marker = () => {
  return (
    <svg
      viewBox="0 0 5 24"
      width="24"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="none" fillRule="evenodd">
          <path
            d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
            fill="black"
          />
          <circle fill="#FFF" cx="8" cy="7.5" r="3" />
        </g>
      </g>
    </svg>
  );
};
// const HomeGymIconBlue = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="18px"
//       margin="-6px 5px 0 0"
//       viewBox="0 0 782.208 752"
//     >
//       <path
//         id="Combined_Shape"
//         data-name="Combined Shape"
//         d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
//         fill="#0d2674"
//       />
//     </svg>
//   );
// };

// TODO: Desktop breakpoint number is hardcoded now
// TODO: amenities not present
// TODO: Additional amenities
const ItemDetail = (props) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  // We need to forward refs for proper accessibility
  // with CopyToClipboard & Tippy
  const CopyWithTooltip = React.forwardRef(({ children, copyText }, ref) => (
    <CopyToClipboard
      text={copyText}
      onCopy={(text, result) =>
        result
          ? toast("Copied to clipboard", { closeOnClick: "true" })
          : toast("Sorry, you'll have to copy/paste manually")
      }
    >
      <button ref={ref} type="button" className={classes.locationDetail}>
        {children}
      </button>
    </CopyToClipboard>
  ));
  CopyWithTooltip.displayName = "CopyWithTooltip";
  // END CopyWithTooltip
  const { locationDetail, setLocationDetail, account } =
    useContext(LocationsContext);
  const copyPhoneRef = useRef();
  const copyAddressRef = useRef();
  const isDesktop = useMediaQuery(`(min-width: 780px)`);
  const currentTier = account.member?.eligibilities[0]
    ? account.member?.eligibilities[0].currentSubscription?.ratePlanName.substr(
        account.member?.eligibilities[0].currentSubscription?.ratePlanName.indexOf(
          "-"
        ) + 1
      )
    : "2";

  return (
    <>
      {/* COMMENTING OUT HOME GYMS FOR NOW */}
      {/* {locationDetail.isHomeLocationTier &&
        currentTier === "ELITE" &&
        account.homeGymFrame.status === "NONE" && (
          <div
            className="d-flex shadow card mb-2 p-3 border-0 rounded-0 bg-warning"
            style={{
              marginTop: "-2px",
              marginRight: "-20px",
              marginBottom: "0",
              marginLeft: "-18px",
            }}
          >
            <p className="test font-small m-0 px-4">
              It looks like you have not yet set a Home Gym. In order to access
              this Elite Location, you must first designate this location as
              your Home Gym by clicking the Set as Home Gym button below.
            </p>
          </div>
        )} */}
      {/* {locationDetail.isHomeLocationTier &&
        currentTier === "ELITE" &&
        account.homeGymFrame?.status !== "NONE" &&
        account.homeGymFrame?.allowedActions?.find(
          (t) => t === ENROLLMENT_ACTIONS.CHANGE
        ) &&
        locationDetail.id !== account.homeGymFrame.current.locationId && (
          <div
            className="d-flex shadow card mb-2 p-3 border-0 rounded-0 bg-warning"
            style={{
              marginTop: "-2px",
              marginRight: "-20px",
              marginBottom: "0",
              marginLeft: "-18px",
            }}
          >
            <p className="test font-small m-0 px-4">
              {`You currently do not have access to this Elite Gym.
          Update to this location in order to access this location
          starting 
          ${moment().startOf("month").add(1, "month").format("MMMM Do")}
          by clicking the Set as Home Gym button below.`}
            </p>
          </div>
        )} */}
      <div className={classes.detailWrapper}>
        <button
          type="button"
          className={classes.backLink}
          onClick={() => setLocationDetail({})}
        >
          <svg
            className={classes.backLinkIcon}
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h24v24H0z" />
              <path
                fill={theme.accessible.primary.color}
                d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"
              />
            </g>
          </svg>
          <span className={classes.backLinkText}>Back to search results</span>
        </button>
        <div className="position-relative">
          <h5 className={classes.name}>{locationDetail.name}</h5>

          <span className="locationDetailsTabs">
            {currentTier === "ELITE" && (
              <>
                <Marker className="locationDetailsTabs__maker" />
                <span className="locationDetailsTabs__eliteText">ELITE</span>
              </>
            )}

            <div className="position-realtive"></div>
            {/* {account.homeGymFrame.status !== "NONE" &&
              locationDetail.id === account.homeGymFrame.current.locationId && (
                <>
                  <HomeGymIconBlue className="locations-setHomeGym__icon" />
                  <div className="locationDetailsTabs__homeGymText">
                    HOME GYM
                  </div>
                </>
              )} */}
          </span>
        </div>
        {
          <Tippy
            content={`${isDesktop ? "Copy" : "Call"} phone number`}
            placement="top"
            animation="scale"
            animateFill={false}
            duration={[250, 175]}
            delay={[150, 0]}
            distance={8}
            appendTo="parent"
          >
            {isDesktop ? (
              // Show link to copy phone on desktop
              <CopyWithTooltip
                ref={copyPhoneRef}
                copyText={locationDetail.phone}
                className={classes.locationDetail}
              >
                <div className={classes.iconWrapper}>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
                      fill={theme.primary.color}
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
                <p className={classes.noneText}>
                  {locationDetail.phone || "None provided"}
                </p>
              </CopyWithTooltip>
            ) : (
              // Show link to call phone on mobile
              <a
                href={`tel:${locationDetail.phone}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`${classes.detailLink} ${classes.locationDetail}`}
              >
                <div className={classes.iconWrapper}>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
                      fill={theme.primary.color}
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
                {locationDetail.phone}
              </a>
            )}
          </Tippy>
        }
        {/* Website */}
        {/*locationDetail.website ? (
        <Tippy
          content="Open website in new tab"
          placement="top"
          animation="scale"
          animateFill={false}
          duration={[250, 175]}
          delay={[150, 0]}
          distance={8}
          appendTo="parent"
        >
          <a
            href={`http://${locationDetail.website}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.detailLink} ${classes.locationDetail}`}
            aria-describedby="website"
          >
            <div className={classes.iconWrapper}>
              <svg
                className={classes.icon}
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 18.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 5.08 16zm2.95-8H5.08a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                  fill={theme.primary.color}
                  fillRule="evenodd"
                />
              </svg>
            </div>
            {locationDetail.website}
          </a>
        </Tippy>
      ) : (
        <div className={classes.locationDetail}>
          <div className={classes.iconWrapper}>
            <svg
              className={classes.icon}
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 18.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 5.08 16zm2.95-8H5.08a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                fill={theme.primary.color}
                fillRule="evenodd"
              />
            </svg>
          </div>
          <span className={classes.noneText}>None provided</span>
        </div>
      )*/}
        {/* Address */}
        {locationDetail.street ? (
          <Tippy
            content="Copy address"
            placement="top"
            animation="scale"
            animateFill={false}
            duration={[250, 175]}
            delay={[150, 0]}
            distance={8}
            appendTo="parent"
          >
            <CopyWithTooltip
              ref={copyAddressRef}
              copyText={`${locationDetail.street}\n${locationDetail.city}, ${locationDetail.state} ${locationDetail.zip}`}
            >
              <div className={classes.iconWrapper}>
                <svg
                  className={classes.icon}
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(4)" fill="none" fillRule="evenodd">
                    <path
                      d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
                      fill={theme.primary.color}
                    />
                    <circle fill="#FFF" cx="8" cy="7.5" r="3" />
                  </g>
                </svg>
              </div>

              <div>
                <p className={classes.street}>{locationDetail.street}</p>
                <p className={classes.cityStateZip}>
                  {locationDetail.city}, {locationDetail.state}{" "}
                  {locationDetail.zip}
                </p>
              </div>
            </CopyWithTooltip>
          </Tippy>
        ) : (
          <div className={classes.locationDetail}>
            <div className={classes.iconWrapper}>
              <svg
                className={classes.icon}
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(4)" fill="none" fillRule="evenodd">
                  <path
                    d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
                    fill={theme.primary.color}
                  />
                  <circle fill="#FFF" cx="8" cy="7.5" r="3" />
                </g>
              </svg>
            </div>
            <span className={classes.noneText}>None provided</span>
          </div>
        )}
        {/* Home Gym not needed now */}
        {/* {locationDetail.isHomeLocationTier &&
          currentTier === "ELITE" &&
          account.homeGymFrame.status !== "NONE" &&
          locationDetail.id !== account.homeGymFrame.current.locationId && (
            <>
              <button
                onClick={() => {
                  account.homeGymFrame.allowedActions?.find(
                    (t) => t === ENROLLMENT_ACTIONS.CHANGE
                  ) || account.homeGymFrame.status === "TRANSITIONING"
                    ? setChangeShowModal(true)
                    : setShowModal(true);
                }}
                className="setHomeGymModal__button"
              >
                <HomeGymIcon style={{ marginTop: "-8px" }} /> Set As Home Gym
              </button>
            </>
          )} */}
        {/* not needed now */}
        {/* {locationDetail.isHomeLocationTier &&
          currentTier === "ELITE" &&
          account.homeGymFrame.status === "NONE" && (
            <>
              <button
                onClick={() => {
                  account.homeGymFrame.allowedActions?.find(
                    (t) => t === ENROLLMENT_ACTIONS.CHANGE
                  ) || account.homeGymFrame.status === "TRANSITIONING"
                    ? setChangeShowModal(true)
                    : setShowModal(true);
                }}
                className="setHomeGymModal__button"
              >
                <HomeGymIcon style={{ marginTop: "-8px" }} /> Set As Home Gym
              </button>
            </>
          )} */}
        {/* Directions */}
        <a
          className={classes.directionsLink}
          href={`https://www.google.com/maps/dir/?api=1&destination=${locationDetail.latitude},${locationDetail.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Get Directions
        </a>
        {/* Included Amenities */}
        <Collapse
          accordion={false}
          defaultActiveKey={["1", "2"]}
          expandIcon={({ isActive }) => (
            <FontAwesomeIcon
              className={classNames(classes.accordionIcon, {
                [classes.accordionIconIsActive]: isActive,
              })}
              icon={faChevronDown}
            />
          )}
        >
          <Panel
            key="1"
            headerClass={classes.accordionHeader}
            header={
              <h6 className={classes.amenitiesHeading}>Fitness Amenities</h6>
            }
          >
            <p className={classes.legal}>
              Actual amenities may differ and may require additional charge
            </p>

            {typeof locationDetail.amenities !== "undefined" &&
              locationDetail.amenities.length > 0 && (
                <ul className={classes.amenities}>
                  {locationDetail.amenities.map((amenity) => (
                    <li key={amenity.id}>{amenity.name}</li>
                  ))}
                </ul>
              )}
            {(typeof locationDetail.amenities === "undefined" ||
              !locationDetail.amenities.length) && (
              <div>
                <p>
                  Please contact the gym to learn what amenities they offer.
                </p>
              </div>
            )}
          </Panel>
          {/* Additional Amenities */}

          {/*additionalAmenities.length > 0 && (
          <Panel
            key="2"
            headerClass={classes.accordionHeader}
            header={
              <h6 className={classes.amenitiesHeading}>
                Additional Cost Amenities
              </h6>
            }
          >
            <ul className={classes.amenities}>
              {additionalAmenities.map((amenity) => (
                <li key={amenity.id}>{amenity.name}</li>
              ))}
            </ul>
          </Panel>
              )*/}
        </Collapse>
        {/* Additional Information */}
        {locationDetail?.marketingNotes && (
          <>
            <h6
              className={classNames(
                classes.amenitiesHeading,
                classes.sectionHeader
              )}
            >
              Additional Information
            </h6>
            <div>{locationDetail?.marketingNotes}</div>
          </>
        )}
        {/*
      Temporarily hide as the copy hasn't been approved and
      this needs to be dynamic
      <div className={classes.packageDetails}>
        <h6>Package Description</h6>
        <p className={classes.description}>This location is part of the Core Package. The package includes all other Core locations as well as locations included in the Basic Package.</p>
      </div> */}
      </div>
    </>
  );
};

export default ItemDetail;
