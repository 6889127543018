import React, { Fragment, useContext, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { useAuth0 } from "@auth0/auth0-react";
import { ELIGIBILITY_MESSAGE } from "../../../context/UserProvider";
import ModalMap from "../../../resources/ModalMapWalmart.svg";
import { useConfig } from "../../../configuration/useConfig";
import { UserContext } from "../../../context/UserProvider";

const StatusModal = ({
  status,
  message,
  show,
  onNext,
  onCancel,
  progress,
}: {
  status: string;
  message: string;
  show: boolean;
  onNext: (e: unknown) => void;
  onCancel: (e: unknown) => void;
  progress?: number;
}) => {
  const [check, setCheck] = useState(false);
  const [checkPA, setCheckPA] = useState(false);
  const history = useHistory();
  const checked = () => {
    setCheck(!check);
  };
  const checkedPA = () => {
    setCheckPA(!checkPA);
  };
  const { loginWithRedirect } = useAuth0();
  const { user } = useContext(UserContext);
  const { config } = useConfig();

  const getContent = () => {
    switch (status.toUpperCase()) {
      case "GENERIC-ERROR":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Something Went Wrong</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p style={{ textAlign: "left" }}>
                It seems we're having trouble processing your request. Please
                try again later.
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap", width: `65%`, height: `50%` }}
                onClick={onCancel}
              >
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ERROR-CHECKOUT":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Something Went Wrong</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p style={{ textAlign: "left" }}>
                It seems we&apos;re having trouble completing your membership.
                <br />
                Please <b>do not</b> reattempt checkout at this time.
              </p>
              <br />
              <p style={{ textAlign: "left" }}>
                If you have any questions, please contact customer service at:{" "}
                <a
                  href={`tel:${config["contact.phoneNumber"]}`}
                  className="a-link"
                >
                  {config["contact.phoneNumber"]}
                </a>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              {/* <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap", width: `65%`, height: `50%` }}
                onClick={onCancel}
              >
                Close
              </Button> */}
            </Modal.Footer>
          </Fragment>
        );
      case "ERROR":
        return (
          <Fragment>
            <Modal.Body className="modal-body-form">
              <SomethingWentWrong small={true} />
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
            </Modal.Footer>
            {/* <Modal.Header className="modal-header-form">
                <div className="modal-header-form__exclamation"></div>
                <h2>
                  <b>Unfortunately, there was an error with your request.</b>
                </h2>
              </Modal.Header>
              <Modal.Body className="modal-body-form">
                Please close this message and try again.
              </Modal.Body>
              <Modal.Footer className="modal-footer-form">
                <Button
                  variant="outline-primary"
                  className="nav-btn-enroll font-weight-bold"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={onCancel}
                >
                  Close
                </Button>
              </Modal.Footer> */}
          </Fragment>
        );
      case "LOADING":
        return (
          <Modal.Body>
            <div className="d-flex p-2 flex-column text-center align-items-center">
              <p style={{ textAlign: "center" }}>
                <h3 style={{ fontSize: "16px" }}>
                  <b>Hang Tight!</b>
                </h3>
              </p>
              <p style={{ textAlign: "center" }}>
                We&apos;re checking to see if you&apos;re eligible. <br />
                <img
                  src={ModalMap}
                  height="140"
                  width="290"
                  alt="map"
                  className="m-3"
                />
                <br />
                <span>Wrapping up...</span>
                <div className="progress-bar w-100 mt-3 mb-5 ml-3">
                  <ProgressBar striped now={progress} />
                </div>
                <span>
                  <small>
                    Please <b>do not refresh the page.</b>
                  </small>
                </span>
              </p>
            </div>
          </Modal.Body>
        );
      case "CONFIRMED":
        if (user.isPayroll) {
          return (
            <Fragment>
              <Modal.Header className="modal-header-form">
                <div className="modal-header-form__checkmark"></div>
                <h2>
                  <b>Eligibility Confirmed!</b>
                </h2>
              </Modal.Header>
              <Modal.Body className="modal-body-form">
                <h6>Congratulations! Your eligibility has been confirmed!</h6>
                <p>
                  <small>
                    You&apos;re one step closer to a longer, healthier life.
                  </small>
                </p>
              </Modal.Body>
              <Modal.Footer className="modal-footer-form">
                <Button
                  variant="primary"
                  className="nav-btn-enroll font-weight-bold modal-footer-form__button"
                  onClick={onNext}
                >
                  Proceed to Next Step
                </Button>
              </Modal.Footer>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Modal.Header className="modal-header-form">
                <div className="modal-header-form__checkmark"></div>
                <h2>
                  <b>Eligibility Confirmed!</b>
                </h2>
              </Modal.Header>
              <Modal.Body className="modal-body-form">
                <h2 style={{ textAlign: "center" }}>
                  <b>Please agree to our terms and continue</b>
                </h2>
                <p>
                  <small>
                    You&apos;re one step closer to a longer, healthier life.
                  </small>
                </p>
                <p style={{ marginBottom: "-20px", marginTop: "20px" }}>
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onChange={checkedPA}
                      style={{
                        marginRight: "6px",
                        height: "13px",
                        width: "13px",
                      }}
                    />
                    <small className="eligibility-form__row">
                      <span className="required-field-marker">*</span>By
                      checking this box, I acknowledge that I have read and
                      agree to comply with the terms and conditions of the{" "}
                      <a
                        href="/fitness-program-agreement"
                        target="_blank"
                        rel="noreferrer"
                        className="a-link"
                      >
                        Fitness Pass Program Agreement.
                      </a>{" "}
                    </small>
                  </label>
                </p>
              </Modal.Body>
              <Modal.Footer className="modal-footer-form">
                <Button
                  variant="primary"
                  className="nav-btn-enroll font-weight-bold modal-footer-form__button"
                  disabled={!checkPA}
                  onClick={onNext}
                >
                  Agree & Continue
                </Button>
              </Modal.Footer>
            </Fragment>
          );
        }

      case "DENIED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Eligibility Cannot Be Confirmed</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">{message}</Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Re-Enter Information
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case ELIGIBILITY_MESSAGE.LoginCreated.toUpperCase():
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body
              className="modal-body-form"
              style={{ textAlign: "center" }}
            >
              <b>
                Congratulations! Your eligibility has been confirmed and your
                portal account was created!
              </b>
              <br />
              <br />
              Instructions for your account setup have been emailed to you.
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                variant="primary"
                // as={Link}
                className="nav-btn-enroll font-weight-bold"
                // to="/sso"
                onClick={() =>
                  user.isSSO ? history.push("/sso") : loginWithRedirect()
                }
                style={{ whiteSpace: "nowrap" }}
              >
                {user.isSSO ? "Go to Account Dashboard" : "Go to Login"}
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "NOEMAIL":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Email Address Unavailable</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">{message}</Modal.Body>
            <br></br>
            <br></br>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold "
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Enter New Email
              </Button>
            </Modal.Footer>
            <br></br>
          </Fragment>
        );
      case "REDEMPTION":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2 className="redemption-eligibility">
                <b>Welcome Blue365 Members</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6 className="redemption-confirmed">
                Your Eligibility has been confirmed!
              </h6>
              <p>
                <small className="redemption-agreement">
                  Explore our different packages, find participating gyms, and
                  discover more perks that come with a Fitness Your Way
                  membership.
                </small>
              </p>
              <p style={{ marginTop: "10px" }}>
                <small className="redemption-proceed">
                  Please agree to our terms before proceeding.
                </small>
              </p>
              <p style={{ marginBottom: "-20px" }}>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onChange={checked}
                    style={{
                      marginRight: "6px",
                      height: "13px",
                      width: "13px",
                    }}
                  />
                  <small className="redemption-tnc">
                    <span className="required-field-marker">*</span>I have read
                    and agree to the{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </small>
                </label>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="redemption-button"
                disabled={!check}
                onClick={onNext}
              >
                Explore
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EXISTS":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2 className="redemption-eligibility">
                <b>Existing Account Found</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p>
                <small className="redemption-agreement">
                  Our records indicate that you already have an account with us.
                  Please{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    login
                  </a>{" "}
                  or{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    reset your password
                  </a>{" "}
                  to access your account.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EXISTS_NO_BUTTON":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2 className="redemption-eligibility">
                <b>Existing Account Found</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <p>
                <small className="redemption-agreement">
                  Our records indicate that you already have an account with us.
                  Please{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    login
                  </a>{" "}
                  or{" "}
                  <a
                    onClick={() => loginWithRedirect()}
                    style={{
                      textDecoration: `underline`,
                      color: "var(--primary)",
                      cursor: `pointer`,
                    }}
                  >
                    reset your password
                  </a>{" "}
                  to access your account.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ALREADY_CONFIRMED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Eligibility Already Confirmed!</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>Please Proceed to the next step!</h6>
              <p>
                <small>
                  You&apos;re one step closer to a longer, healthier life.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold modal-footer-form__button"
                onClick={onNext}
              >
                Proceed to Next Step
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "BURNALONG":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form-partner">
              <h2>
                <b>You're heading to BurnAlong.com</b>
              </h2>
              <span style={{ cursor: "pointer" }} onClick={onCancel}>
                X
              </span>
            </Modal.Header>
            <Modal.Body className="modal-body-form-partner">
              <h6>
                You're leaving the Fitness Your Way portal and being redirected
                to our friends at BurnAlong.com
              </h6>
              <p>
                <small>
                  Please note that BurnAlong.com is not owned, operated or
                  controlled by Tivity Health, inc. Or it's affiliates. By
                  visiting BurnAlong.com, you will be subject to their terms of
                  use and privacy policy
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form-partner">
              <Button
                variant="outline-primary"
                className="font-weight-bold modal-footer-form-partner__button stay-here"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Stay Here
              </Button>
              <Button
                variant="primary"
                className="font-weight-bold modal-footer-form-partner__button"
                onClick={onNext}
              >
                Visit BurnAlong
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ZUORA":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form-partner">
              <h2>
                <b>You're heading to Zuora.com</b>
              </h2>
              <span style={{ cursor: "pointer" }} onClick={onCancel}>
                X
              </span>
            </Modal.Header>
            <Modal.Body className="modal-body-form-partner">
              <h6>
                You're leaving Fitness Pass and being redirected to our friends
                at Zuora.com
              </h6>
              <p>
                <small>
                  Please note that Zuora.com is not owned, operated or
                  controlled by Tivity Health, inc. Or it's affiliates. By
                  visiting Zuora.com, you will be subject to their terms of use
                  and privacy policy
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form-partner">
              <Button
                variant="outline-primary"
                className="font-weight-bold modal-footer-form-partner__button stay-here"
                style={{ whiteSpace: "nowrap" }}
                onClick={onCancel}
              >
                Stay Here
              </Button>
              <Button
                variant="primary"
                className="font-weight-bold modal-footer-form-partner__button"
                onClick={onNext}
              >
                Visit Zuora
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      default:
        return null;
    }
  };
  return (
    <Modal centered show={show} dialogClassName="status-modal">
      {getContent()}
    </Modal>
  );
};

export default StatusModal;
