import React from "react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Modal } from "react-bootstrap";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddPaymentModal from "./AddPaymentModal";
import { useConfig } from "../../../configuration/useConfig";

const FailPaymentModal = ({
  open,
  onClose,
  isEnrollment,
}: {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  isEnrollment?: boolean;
}) => {
  const { config } = useConfig();

  const handleClose = async () => {
    onClose(!open);
  };

  return open ? (
    <Modal
      show={open}
      onHide={() => onClose(true)}
      style={{
        width: "50%",
        height: "80%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Modal.Title
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "30px",
          backgroundColor: "#024f9a",
          borderRadius: "6px 6px 0px 0px",
          gap: "12px",
          paddingBottom: "30px",
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{
            color: "white",
            fontSize: "5rem",
          }}
        />
        <h3
          style={{
            color: "white",
            fontSize: "1.8rem",
            fontWeight: "bold",
          }}
        >
          Payment Method Error
        </h3>
      </Modal.Title>
      <Modal.Body
        style={{
          padding: "45px",
        }}
      >
        <p>
          There was an issue verifying your payment method. Please{" "}
          <a href="#">add a new payment method</a> or call Customer Service:{" "}
          <a href={`tel:${config["contact.phoneNumber"]}`} className="a-link">
            {config["contact.phoneNumber"]}
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
          <button
            onClick={handleClose}
            className="col btn btn-outline-primary ml-2"
          >
            Cancel
          </button>
          <button
            onClick={
              isEnrollment
                ? handleClose
                : () => {
                    <AddPaymentModal
                      open={false}
                      onAdd={function (): void {
                        throw new Error("Function not implemented.");
                      }}
                      onClose={function (): void {
                        throw new Error("Function not implemented.");
                      }}
                    />;
                  }
            }
            className="col mx-2 btn btn-secondary "
          >
            Add New Payment Method
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default FailPaymentModal;
