import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Header from "../components/Header";
import { EligibilityFlow, FormikWizardState, SmartyAddress } from "../types";
import AddressForm from "../components/AddressForm";
import AddressVerificationModal from "../components/AddressVerificationModal";
import { AccountContext } from "../../../context/AccountProvider";
import useSummary from "../../../hooks/useSummary";
import StatusModal from "../components/StatusModal";
import FooterButtons from "../components/FooterButtons";
import axiosInstance from "../../../axios.instance";
import UpdateInformation from "../components/UpdateInformation";
import useGetScreenSize from "../../../hooks/useGetScreenSize";

export enum ADDRESS {
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}

const Address = ({
  previous,
  next,
}: {
  previous?: () => void;
  next?: () => void;
}) => {
  const { values, errors, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();

  const { forceRefresh } = useSummary();
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [openAccountExisting, setopenAccountExisting] = useState(false);
  const [show, setShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [openUpdateInformation, setOpenUpdateInformation] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const { member } = useContext(AccountContext);
  const { isSmallScreen } = useGetScreenSize();

  useEffect(() => {
    if (isSmallScreen) {
      window.scrollTo(0, 0);
    }
  }, []);

  //grab the latest summary
  useEffect(() => {
    forceRefresh();
  }, []);

  //initial verification
  useEffect(() => {
    setFieldTouched("address.home.street1");
  }, [setFieldTouched]);

  useEffect(() => {
    if (member) {
      const { address1, address2, city, state, postalCode } = member;
      setFieldValue("address.home.street1", address1 ? address1 : "");
      setFieldValue("address.home.street2", address2 ? address2 : "");
      setFieldValue("address.home.city", city ? city : "");
      setFieldValue("address.home.state", state ? state : "");
      setFieldValue("address.home.zip", postalCode ? postalCode : "");
    }
  }, [member]);

  useEffect(() => {
    setFieldTouched("address");
  }, [values]);

  //this needs billing address
  const handleAddress = async () => {
    setOpenUpdateInformation(true);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
    if (!member?.personId && values.eligibility!.email) {
      await axiosInstance
        .post("/member/setup", {
          firstName: values.eligibility!.firstName,
          lastName: values.eligibility!.lastName,
          email: values.eligibility!.email,
          dateOfBirth:
            values.eligibility!.year +
            "-" +
            values.eligibility!.month +
            "-" +
            values.eligibility!.day,
          address1: values.address!.home.street1,
          address2: values.address!.home.street2,
          city: values.address!.home.city,
          state: values.address!.home.state,
          zip: values.address!.home.zip,
          subscriberId: values.eligibility!.associateIdentificationNumber,
          isDependent: values.eligibility!.isDependent,
          groupId: values.eligibility!.groupNumber,
        })
        .then((response) => {
          setProgress(100);
          const no_member = {
            outcome: "PersonNotFound",
            personId: null,
          };

          if (
            response.data.outcome !== no_member.outcome &&
            response.data.personId !== no_member.personId
          ) {
            setOpenUpdateInformation(false);
            setopenAccountExisting(true);
          } else if (
            response.data.outcome === no_member.outcome ||
            response.data.personId === no_member.personId
          ) {
            setFieldValue("address.completed", true);
            if (next) {
              next();
            }
          } else {
            setopenAccountExisting(false);
            setOpenUpdateInformation(false);
            setShow(true);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
          setopenAccountExisting(false);
          setOpenUpdateInformation(false);
          setShow(true);
        });
    } else if (member?.personId && values.eligibility!.email) {
      setProgress(100);
      setFieldValue("address.completed", true);
      if (next) {
        next();
      }
    } else {
      setOpenUpdateInformation(false);
      setShowErrorModal(true);
    }
  };

  const handleConfirm = () => {
    handleAddress();
  };
  const handleCancel = () => {
    setVerifyOpen(false);
  };

  const handleNext = () => {
    if (
      !getIn(values, "address.verifiedHome") ||
      (!getIn(values, "address.verifiedBilling") &&
        values.address!.billingRequired)
    ) {
      setVerifyOpen(true);
    } else {
      handleAddress();
    }
  };

  const handleVerified = (field: string, value: boolean) => {
    if (field === "address.billing") {
      setFieldValue("address.verifiedBilling", value);
    } else {
      setFieldValue("address.verifiedHome", value);
    }
  };

  const setAddressValues = (
    field: string,
    smartAddressArr: SmartyAddress[]
  ) => {
    if (smartAddressArr.length > 0) {
      //timeouts required by formik :/
      const smartAddress = smartAddressArr[0];
      setFieldValue(field + ".street1", smartAddress.address.streetLine);
      setTimeout(() => setFieldTouched(field + ".street1", true));
      setFieldValue(field + ".street2", smartAddress.address.secondary);
      setTimeout(() => setFieldTouched(field + ".street2", true));
      setFieldValue(field + ".zip", smartAddress.address.zipcode);
      setTimeout(() => setFieldTouched(field + ".zip", true));
      setFieldValue(field + ".city", smartAddress.address.city);
      setTimeout(() => setFieldTouched(field + ".city", true));
      setFieldValue(field + ".state", smartAddress.address.state);
      setTimeout(() => setFieldTouched(field + ".state", true));
      handleVerified(field, true);
    }
  };

  //set all fields at once
  const handleHomeAddress = (t: SmartyAddress[]) => {
    setAddressValues("address.home", t);
  };

  const handleBillingAddress = (t: SmartyAddress[]) => {
    setAddressValues("address.billing", t);
  };
  //set single field
  const handleChangeWrapper =
    (field: string) => (e: React.ChangeEvent<unknown>) => {
      handleVerified(field, false);
      console.log(e);
      handleChange(e);
    };

  const handleInputChange = (field: string) => (input: string) => {
    handleVerified(field, false);
    setFieldValue(field + ".street1", input);
    setTimeout(() => setFieldTouched(field + ".street1", true));
  };

  const formatHomeAddress = (): string => {
    return `${values.address!.home.street1}${
      values.address!.home.street2 !== ""
        ? " " + values.address!.home.street2
        : ""
    } ${values.address!.home.city}, ${values.address!.home.state} ${
      values.address!.home.zip
    }`;
  };

  const formatBillingAddress = (): string => {
    return `${values.address!.billing.street1}${
      values.address!.billing.street2 !== ""
        ? " " + values.address!.billing.street2
        : ""
    } ${values.address!.billing.city}, ${values.address!.billing.state} ${
      values.address!.billing.zip
    }`;
  };

  const handleCancelError = () => {
    setShow(false);
    setShowErrorModal(false);
  };

  useEffect(() => {
    if (openUpdateInformation && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, openUpdateInformation]);

  return (
    <div>
      <AddressVerificationModal
        homeAddress={
          !getIn(values, "address.verifiedHome")
            ? formatHomeAddress()
            : undefined
        }
        billingAddress={
          !getIn(values, "address.verifiedBilling") &&
          values.address!.billingRequired
            ? formatBillingAddress()
            : undefined
        }
        show={verifyOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <StatusModal
        show={show}
        status={"ERROR"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      <StatusModal
        show={showErrorModal}
        status={"GENERIC-ERROR"}
        message=""
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      <StatusModal
        show={openAccountExisting}
        status={"EXISTS_NO_BUTTON"}
        message="TEST"
        onCancel={handleCancelError}
        onNext={() => {
          return;
        }}
      />
      <UpdateInformation
        show={openUpdateInformation}
        progress={progress}
        onClose={handleCancel}
      />
      <Row>
        <Col xs={12} md={7}>
          <Header
            title="Home Address"
            subtitle="Please enter your address below to complete your account setup."
          />

          <Card>
            <Card.Body style={{ padding: "24px" }}>
              <AddressForm
                onChange={handleChangeWrapper("address.home")}
                onChangeSmarty={handleHomeAddress}
                onInputChange={handleInputChange("address.home")}
                values={values.address!.home}
                prefix="address.home"
              />
              <Row className="eligibility-form__row p-3">
                <Form.Group
                  as={Col}
                  sm={12}
                  className="mb-3"
                  controlId="formbillingCheckbox"
                >
                  <Form.Check
                    style={{ paddingLeft: 0 }}
                    type="checkbox"
                    name="address.billingRequired"
                    label="Use this same address for billing"
                    checked={!values.address!.billingRequired}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(
                        "address.billingRequired",
                        !e.target.checked
                      )
                    }
                  />
                </Form.Group>
              </Row>
              {values.address!.billingRequired && (
                <div>
                  <h3>
                    <b>Billing Address</b>
                  </h3>
                  <hr />
                  <AddressForm
                    onChange={handleChangeWrapper("address.billing")}
                    onChangeSmarty={handleBillingAddress}
                    onInputChange={handleInputChange("address.billing")}
                    values={values.address!.billing}
                    prefix="address.billing"
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        {/* <Col xs={12} md={4}>
                    <Summary />
                </Col> */}
      </Row>
      <FooterButtons
        onSubmit={handleNext}
        onBack={previous}
        submitDisabled={errors.address !== undefined}
        submitText={"Save & Proceed"}
      />
    </div>
  );
};

export default Address;
