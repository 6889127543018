import { useContext, useEffect, useState } from "react";
import axiosInstance from "../axios.instance";
import { Product } from "../components/wizard/forms/Package";
import { PROGRAM_CODE, USER_PAYROLL_FREQUENCY, UserContext } from "../context/UserProvider";

const useGetPrograms = (
  enrollment?: boolean,
  isPayroll?: boolean | unknown,
  payrollFrequency?: USER_PAYROLL_FREQUENCY,
) => {
  const { user } = useContext(UserContext);
  const [productRatePlans, setProductRatePlans] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [addendum, setAddendum] = useState("per paycheck");


  //used for account summary
  useEffect(() => {

    if (!enrollment) {
      let programCode;
      if (user.isPayroll) {
        if (user.payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly)
          programCode = PROGRAM_CODE.WalmartPayrollWeekly;
        else if (user.payrollFrequency === USER_PAYROLL_FREQUENCY.BiWeekly) programCode = PROGRAM_CODE.WalmartPayrollBiWeekly;
        else programCode = PROGRAM_CODE.NotApplicable;
      } else {
        programCode = PROGRAM_CODE.PrimeLTE;
      }

      const fetchData = async () => {
        setLoading(true);
        axiosInstance
          .get(`/programs/${programCode}`)
          .then((response) => {
            if (response.data) {
              setProductRatePlans(response.data.productRatePlans);
              sessionStorage.setItem(
                "programs",
                JSON.stringify({
                  bundles: response.data.productRatePlans,
                  plan_type: user.isPayroll ? `walmart_payroll` : `prime_lte`,
                })
              );
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError(true);
          });
      };
      setAddendum(user.isPayroll ? "per paycheck" : "month");
      if (sessionStorage.getItem("programs")) {
        const programs = sessionStorage.getItem("programs");
        if (programs) {
          const bundles = JSON.parse(programs);
          if (bundles.plan_type === user.isPayroll) {
            setProductRatePlans(bundles.bundles);
          } else {
            fetchData();
          }
        }
      } else {
        fetchData();
      }

      return () => setLoading(false);
    }
  }, [user.isPayroll]);

  //Used for enrollments and re-enrollments
  useEffect(() => {
    if (enrollment && isPayroll !== undefined) {
      let programCode;
      if (isPayroll) {
        if (payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly)
          programCode = PROGRAM_CODE.WalmartPayrollWeekly;
        else if(payrollFrequency === USER_PAYROLL_FREQUENCY.BiWeekly) programCode = PROGRAM_CODE.WalmartPayrollBiWeekly;
        else {
          programCode = PROGRAM_CODE.NotApplicable;
        }
      } else {
        programCode = PROGRAM_CODE.PrimeLTE;
      }
      const fetchData = async () => {
        setLoading(true);
        axiosInstance
          .get(`/programs/${programCode}`)
          .then((response) => {
            if (response.data) {
              setProductRatePlans(response.data.productRatePlans);
              sessionStorage.setItem(
                "programs",
                JSON.stringify({
                  bundles: response.data.productRatePlans,
                  plan_type: isPayroll ? `walmart_payroll` : `prime_lte`,
                })
              );
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError(true);
          });
      };
      setAddendum(isPayroll ? "per paycheck" : "month");
      if (sessionStorage.getItem("programs")) {
        const programs = sessionStorage.getItem("programs");
        if (programs) {
          const bundles = JSON.parse(programs);
          if (bundles.plan_type === isPayroll) {
            setProductRatePlans(bundles.bundles);
          } else {
            fetchData();
          }
        }
      } else {
        fetchData();
      }

      return () => setLoading(false);
    }
  }, [isPayroll]);

  return { error, loading, productRatePlans, addendum };
};

export default useGetPrograms;
