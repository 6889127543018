import React, { useContext, useState } from "react";
import PersonalInformation from "../../components/account/Profile/PersonalInformation";
import ShippingAddress from "../../components/account/Profile/ShippingAddress";
import ChangeEmail from "../../components/account/Profile/ChangeEmail";
import ChangePassword from "../../components/account/Profile/ChangePassword";
import { Card } from "react-bootstrap";
import { Formik } from "formik";
import { ModelValidations } from "../../components/wizard/model/ModelValidations";
import SmartyStreetsProvider from "../../context/SmartyStreetsProvider";
import ProfileToast from "../../utility/ProfileToast";
import { useConfig } from "../../configuration/useConfig";
import UpcomingPaymentDisplay from "../../components/account/UpcomingPaymentDisplay";
import { UserContext } from "../../context/UserProvider";
import { AccountAddress } from "../../utility/member-portal-api-types";
import axiosInstance from "../../axios.instance";
import { AccountContext } from "../../context/AccountProvider";

const ViewProfile = () => {
  const { user } = useContext(UserContext);
  const { member, refresh } = useContext(AccountContext);
  const [initialValuesAddress] = useState<AccountAddress>({
    firstName: member?.firstName ?? "",
    lastName: member?.lastName ?? "",
    address1: member?.address1 ?? "",
    address2: member?.address2 ?? "",
    city: member?.city ?? "",
    postalCode: member?.postalCode ?? "",
    state: member?.state ?? "",
  });
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { client } = useConfig();
  const [verifiedAddress, setIsVerifiedAddress] = useState(false);

  // useEffect(() => {
  //   //get current address and update initial values
  //   (async () => {
  //     const results = await getCurrentAddress();
  //     if (results.data && results.data.length > 0) {
  //       const shippingAddr = results.data.find((add) => {
  //         if (add.defaultFor) {
  //           return (
  //             add.defaultFor.findIndex((a) => {
  //               return a.toLowerCase() === "shipping";
  //             }) > -1
  //           );
  //         }
  //         return false;
  //       });
  //       if (shippingAddr) {
  //         setId(shippingAddr.id);
  //         setInitialValues({
  //           city: shippingAddr.city,
  //           state: shippingAddr.state,
  //           street1: shippingAddr.address1,
  //           street2: shippingAddr.address2 ?? "",
  //           zip: shippingAddr.zip,
  //         });
  //       }
  //     }
  //     return;
  //   })();
  // }, []);

  const handle = (values: AccountAddress) => {
    setSubmitting(true);
    try {
      axiosInstance
        .put(
          "/account/address",
          {
            firstName: values.firstName ?? "",
            lastName: values.lastName ?? "",
            address1: values.address1 ?? "",
            address2: values.address2 ?? "",
            city: values.city ?? "",
            zipCode: values.postalCode ?? "",
            state: values.state ?? "",
            email: user.info?.email ?? "",
          },
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // return json as Address;
            refresh().then(() => {
              ProfileToast(
                "Your home address has been successfully updated",
                true,
                client
              );
              setSubmitting(false);
            });
          }
        })
        .catch((e) => {
          ProfileToast(
            "Your home address could not be updated.",
            false,
            client
          );
          setVerifyOpen(false);
          setSubmitting(false);
          console.log(e.toJSON());
        });
      setVerifyOpen(false);
    } catch (e) {
      ProfileToast("Your home address could not be updated.", false, client);
      setVerifyOpen(false);
    }
  };

  const handleSubmit = (values: AccountAddress) => {
    if (verifiedAddress) {
      setVerifyOpen(true);
    } else {
      handle(values);
    }
  };

  return (
    <>
      <div style={{ fontSize: "3rem", fontWeight: "300" }}>Profile</div>
      <UpcomingPaymentDisplay />
      <Card>
        <Card.Body className="vp">
          <PersonalInformation />
          <SmartyStreetsProvider>
            <Formik
              enableReinitialize
              initialValues={initialValuesAddress}
              validationSchema={ModelValidations.AddressAccountFieldsValidation}
              onSubmit={(values) => {
                //TODO - replace with proper handler to submit data and wait for response
                // handle(values);
                handleSubmit(values);
              }}
            >
              <ShippingAddress
                verifyOpen={verifyOpen}
                setVerifyOpen={setVerifyOpen}
                handle={handle}
                setIsVerifiedAddress={setIsVerifiedAddress}
                submitting={submitting}
              />
            </Formik>
          </SmartyStreetsProvider>
          {user.isSSO === true ? null : <ChangeEmail />}
          {user.isSSO === true ? null : <ChangePassword />}
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewProfile;
