import React, { useCallback } from "react";
import { useState } from "react";
import {
  convertToCurrency,
  convertToOneTimePackageDetails,
} from "../utility/util";
import axiosInstance from "../axios.instance";
import { IUser } from "./UserProvider";
import {
  FormikWizardState,
  IEligibilityProfileWalmart,
} from "../components/wizard/types";
import { v4 as uuidv4 } from "uuid";
import { AccountSummaryMember } from "../utility/member-portal-api-types";
import { Product } from "../components/wizard/forms/Package";

type PaymentPage = {
  hostedPaymentPageId: string;
  hostedPaymentPageUrl: string;
  key: string;
  signature: string;
  tenantId: string;
  token: string;
};

export type PackageDetails = {
  name: string;
  subtotal: number;
};

export type ImmediatePaymentPackages = {
  amountWithoutTax: number;
  amountWithTax: number;
  chargeName: string;
  consumerId: string;
  chargeType: string;
  serviceStartDate: string;
  serviceEndDate: string;
  tax: number;
  tierId: number;
};

type PromoCodeDetails = {
  code: string;
  status: string;
  description: string;
};

type SummaryResponse = {
  orders: [];
  promoCodeError: [];
  reasons?: [];
  immediatePaymentSummary: {
    immediatePaymentDetails: [
      {
        amountWithoutTax: number;
        amountWithTax: number;
        chargeName: string;
        consumerId: string;
        chargeType: string;
        serviceStartDate: string;
        serviceEndDate: string;
        tax: number;
        tierId: number;
      }
    ];
    promoCodeDescription: [];
    detailedPromoCodeDescription: [];
    creditAmountWithoutTax: number;
    creditAmountWithTax: number;
    creditTaxAmount: number;
    immediateTotalPaymentAmountWithoutTax: number;
    immediateTotalPaymentAmountWithTax: number;
    immediateTotalPaymentTaxAmount: number;
    immediatePaymentDiscountAmountWithoutTax: number;
    immediatePaymentDiscountTaxAmount: number;
    immediateTotalPaymentAfterDiscountAmountWithoutTax: number;
    immediateTotalPaymentAfterDiscountAmountWithTax: number;
    immediateTotalPaymenAfterDiscountTaxAmount: number;
    immediateTotalPaymentSetUpFee: number;
    immediateTotalPaymentRecurringFee: number;
  };
  monthlyPaymentSummary: {
    monthlyPaymentDetails: [
      {
        amountWithoutTax: number;
        amountWithTax: number;
        chargeName: string;
        consumerId: string;
        chargeType: string;
        serviceStartDate: string;
        serviceEndDate: string;
        tax: number;
        tierId: number;
      }
    ];
    promoCodeDescription: [];
    detailedPromoCodeDescription: [];
    monthlyTotalPaymentAmountWithoutTax: number;
    monthlyTotalPaymentAmountWithTax: number;
    monthlyTotalPaymentTaxAmount: number;
    monthlyPaymentDiscountAmountWithoutTax: number;
    monthlyPaymentDiscountTaxAmount: number;
    monthlyTotalPaymentAfterDiscountAmountWithoutTax: number;
    monthlyTotalPaymentAfterDiscountAmountWithTax: number;
    monthlyTotalPaymentAfterDiscountTaxAmount: number;
    monthlyTotalPaymentSetUpFee: number;
    monthlyTotalPaymentRecurringFee: number;
  };
  paymentId: string;
  paymentNumber: string;
  processId: string;
  success: boolean;
  status?: string;
};

type SummaryType = {
  sku: string;
  handle: string;
  onetimeCreditTotal: number;
  onetimeDiscount: number;
  onetimeDiscounts: string[];
  onetimePackageDetails: PackageDetails[];
  onetimeSubtotal: number;
  onetimeSubtotalAfterDiscount: number; //before tax after discount
  onetimeTaxes: number;
  onetimeTotal: number;
  onetimeSetupFee: number;
  periodicDiscount: number;
  periodicDiscounts: string[];
  periodicPackageDetails: PackageDetails[];
  periodicSubtotal: number;
  periodicTaxes: number;
  periodicTotal: number;
  periodicServiceStartDate: string;
  // promoCode: string;
  // promoCodeDescription: string;
  // promoCodeStatus: string;
  promoCodes: PromoCodeDetails[];
  paymentPageCC: PaymentPage;
  paymentPageECheck: PaymentPage;
  paymentMethodRequired: boolean;
  taxCalculated: boolean;
};

export type SummaryUIMap = {
  pending?: {
    //edit plan summary only
    planTotal: string;
    planList: PackageDetails[];
    discounts: string[];
    showDiscounts: boolean;
    discountsTotal: string;
    taxes: string;
    total: string;
    startDate: string;
  };
  due?: {
    //edit plan summary only
    planTotal: string;
    planList: PackageDetails[];
    credits: string;
    discounts: string[];
    discountsTotal: string;
    discountsTotalMinusDigital: string;
    promoCodes: PromoCodeDetails[];
    // promoCode: string;
    // promoCodeDescription: string;
    // promoCodeStatus: string;
    subtotal: string;
    enrollmentFee?: string;
    taxes: string;
    total: string;
  };
  checkout?: {
    //can be used in checkout summary, possibly in edit plan
    planTotal: string;
    planList: PackageDetails[];
    enrollmentFee?: string;
    discounts: string[];
    showDiscounts: boolean;
    discountsTotal: string;
    discountsTotalMinusDigital: string;
    subtotal: string;
    startDate: string;
    monthlyTotal: string;
    total: string;
    taxes: string;
  };
};

type SummaryContextType = {
  forceRefresh: () => void;
  getSummaryRatePlan: (
    wizardState: FormikWizardState<IEligibilityProfileWalmart>
  ) => void;
  getPreviewTierChange: (
    member: AccountSummaryMember,
    user: IUser,
    values: FormikWizardState<IEligibilityProfileWalmart>,
    token: string,
  ) => void;
  setMutated: () => void;
  cancel: () => void;
  mutated: boolean;
  force: boolean;
  setPromoCode: (arg0?: string) => void;
  // setAddendum: (value?: string) => void;
  // setProductRatePlans: (value?: Product[]) => void;
  setAddendum(a: string): void;
  setProductRatePlans(p: Product[]): void;
  summary: SummaryType | undefined;
  error: boolean;
  loading: boolean;
  promoLoading: boolean;
  uiSummary: SummaryUIMap | null;
  // productRatesLoaded: boolean;
  productRatePlans?: Product[];
  addendum?: string;
};


export const SummaryContext = React.createContext<SummaryContextType>({
  forceRefresh: () => {
    return;
  },
  getSummaryRatePlan: () => {
    return;
  },
  getPreviewTierChange: () => {
    return;
  },
  uiSummary: {},
  setMutated: () => {
    return;
  },
  mutated: false,
  cancel: () => {
    return;
  },
  force: false,
  setPromoCode: () => {
    return;
  },
  setAddendum: () => {
    return;
  },
  setProductRatePlans: () => {
    return;
  },
  summary: undefined,
  error: false,
  loading: true,
  promoLoading: false,
  productRatePlans: [],
  // productRatesLoaded:false
});

// TODO - use a reducer pattern
const SummaryProvider = ({ children }: { children: React.ReactNode }) => {
  const initialState = {
    mutated: false,
    force: false,
    shouldPoll: false,
    isLoading: false,
    promoLoading: false,
    errorRequest: false,
  };
  const [productRatePlans, setProductRatePlans] = useState<Product[]>([]);
  const [addendum, setAddendum] = useState<string>("Per Paycheck");
  // const [productRatesLoaded, setProductRatesLoaded] = useState<string>("Per Paycheck");
  // productRatesLoaded

  const [summary, setSummary] = useState<SummaryType>();
  // const [uuid, setUuid] = useState(uuidv4())
  const myuuid = uuidv4();

  const [state] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  let uiMapping: SummaryUIMap | null = null;

  if (summary) {
    // Alter the way digital plan shows when included as part of another package
    uiMapping = {
      checkout: {
        planTotal: convertToCurrency(summary.onetimeSubtotal),
        planList:
          summary.onetimePackageDetails.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        discounts:
          summary.onetimeDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        showDiscounts: summary.promoCodes.length === 0 ? false : true,
        discountsTotal: convertToCurrency(
          Math.abs(summary?.onetimeDiscount ?? 0)
        ),
        discountsTotalMinusDigital: convertToCurrency(
          summary.onetimeDiscount + 10
        ),
        subtotal: convertToCurrency(summary?.onetimeSubtotalAfterDiscount ?? 0),
        monthlyTotal: convertToCurrency(summary?.periodicTotal ?? 0),
        startDate: new Date(
          `${summary.periodicServiceStartDate}T00:00:00` ?? ""
        ).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        }),
        total: convertToCurrency(summary?.onetimeTotal ?? 0),
        taxes: convertToCurrency(summary?.onetimeTaxes ?? 0),
        enrollmentFee: convertToCurrency(summary?.onetimeSetupFee ?? 0),
      },
      due: {
        credits: convertToCurrency(summary.onetimeCreditTotal ?? 0),
        discounts:
          summary.onetimeDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        discountsTotal: convertToCurrency(summary.onetimeDiscount ?? 0),
        discountsTotalMinusDigital: convertToCurrency(
          summary.onetimeDiscount - 10
        ),
        planTotal: convertToCurrency(
          summary.onetimePackageDetails.length > 0
            ? summary.onetimePackageDetails.reduce((a, b) => a + b.subtotal, 0)
            : 0
        ),
        planList: summary.onetimePackageDetails ?? [],
        promoCodes: summary.promoCodes ?? [],
        // promoCode: summary.promoCode ?? "",
        // promoCodeDescription: summary.promoCodeDescription,
        // promoCodeStatus: summary.promoCodeStatus,
        //TODO -NEED THIS
        subtotal: convertToCurrency(summary.onetimeSubtotalAfterDiscount ?? 0),
        enrollmentFee: convertToCurrency(summary.onetimeSetupFee ?? 0),
        taxes: convertToCurrency(summary.onetimeTaxes ?? 0),
        total: convertToCurrency(summary.onetimeTotal ?? 0),
      },
      pending: {
        total: convertToCurrency(summary.periodicTotal ?? 0),
        discounts:
          summary.periodicDiscounts.filter(
            (discount) => discount !== "Digital Discount"
          ) ?? [],
        showDiscounts: summary.periodicDiscounts.some((discount) =>
          discount !== "Digital Discount" ? true : false
        ),
        discountsTotal: convertToCurrency(summary.periodicDiscount ?? 0),
        planTotal: convertToCurrency(
          summary.periodicPackageDetails.length > 1
            ? summary.periodicPackageDetails
                .filter((plan) => plan.name !== "Digital (included)")
                .reduce((a, b) => a + b.subtotal, 0)
            : summary.periodicPackageDetails[0]?.subtotal
        ),
        planList:
          summary.periodicPackageDetails.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [],
        startDate: new Date(
          `${summary.periodicServiceStartDate}T00:00:00` ?? ""
        ).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
        }),
        taxes: convertToCurrency(summary.periodicTaxes) ?? "$0.00",
      },
    };
  }

  const getSummary = (
    wizardState: FormikWizardState<IEligibilityProfileWalmart>
  ) => {
    const billToContact = wizardState.address?.billingRequired
      ? wizardState.address?.billing
      : wizardState.address?.home;
    const soldToContact = wizardState.address?.home;
    setLoading(true);
    setError(false);
    axiosInstance
      .post<SummaryResponse>("/enrollment/preview", {
        payrollStatus: wizardState.eligibility?.isPayroll
          ? "Payroll"
          : "NonPayroll",
        programCode: wizardState.eligibility?.isPayroll
          ? "WALMART_PAYROLL"
          : "PRIME_LTE",
        members: [
          {
            billToContact: {
              lastName: wizardState.eligibility?.lastName,
              firstName: wizardState.eligibility?.firstName,
              address1: billToContact?.street1,
              address2: billToContact?.street2,
              city: billToContact?.city,
              country: "United States",
              postalCode: billToContact?.zip,
              state: billToContact?.state,
            },
            consumerId: myuuid,
            lastName: wizardState.eligibility?.lastName,
            firstName: wizardState.eligibility?.firstName,
            isPrimary: true,
            dateOfBirth: `${wizardState.eligibility?.year}-${wizardState.eligibility?.month}-${wizardState.eligibility?.day}`,
            orderActions: [
              {
                type: "NewAccount",
                ratePlanChargeIds: [wizardState.package?.ratePlanChargeId],
              },
            ],
            soldToContact: {
              firstName: wizardState.eligibility?.firstName,
              lastName: wizardState.eligibility?.lastName,
              address1: soldToContact?.street1,
              address2: soldToContact?.street2,
              city: soldToContact?.city,
              country: "United States",
              id: "abc123",
              postalCode: soldToContact?.zip,
              state: soldToContact?.state,
              email: wizardState.eligibility?.email,
            },
          },
        ],
      })
      .catch((err) => {
        console.log(err.toJSON());
        setLoading(false);
        setError(true);
        throw err;
      })
      .then((response) => {
        if (response.data) {
          setSummary({
            sku: "Peter",
            handle: "res.orderHandle",
            onetimeCreditTotal: 1.0,
            onetimeDiscount: 1.0,
            onetimeDiscounts: [],
            onetimePackageDetails: convertToOneTimePackageDetails(
              response.data.immediatePaymentSummary.immediatePaymentDetails
            ),
            onetimeSubtotal:
              response.data.immediatePaymentSummary
                .immediateTotalPaymentAmountWithoutTax,
            onetimeSubtotalAfterDiscount:
              response.data.immediatePaymentSummary
                .immediateTotalPaymentAmountWithoutTax,
            onetimeTaxes:
              response.data.immediatePaymentSummary
                .immediateTotalPaymentTaxAmount,
            onetimeTotal:
              response.data.immediatePaymentSummary
                .immediateTotalPaymentAfterDiscountAmountWithTax,
            onetimeSetupFee:
              response.data.immediatePaymentSummary
                .immediateTotalPaymentAmountWithoutTax,
            periodicDiscount:
              response.data.immediatePaymentSummary
                .immediateTotalPaymentAmountWithoutTax,
            periodicDiscounts: [],
            periodicPackageDetails: [],
            periodicSubtotal:
              response.data.monthlyPaymentSummary
                .monthlyTotalPaymentAmountWithTax,
            periodicTotal:
              response.data.monthlyPaymentSummary
                .monthlyTotalPaymentAmountWithTax,
            periodicServiceStartDate:
              response.data.monthlyPaymentSummary.monthlyPaymentDetails[0]
                ?.serviceStartDate ?? "2023-10-14",
            paymentMethodRequired: true,
            periodicTaxes:
              response.data.monthlyPaymentSummary.monthlyTotalPaymentTaxAmount,
            promoCodes: [],
            // promoCode: res.order.promoCode,
            // promoCodeDescription: res.order.promoCodeDescription,
            // promoCodeStatus: res.order.promoCodeStatus,
            taxCalculated: true,
            paymentPageCC: {
              hostedPaymentPageId: "abc123",
              hostedPaymentPageUrl: "abc123",
              key: "abc123",
              signature: "abc123",
              tenantId: "abc123",
              token: "abc123",
            },
            paymentPageECheck: {
              hostedPaymentPageId: "abc123",
              hostedPaymentPageUrl: "abc123",
              key: "abc123",
              signature: "abc123",
              tenantId: "abc123",
              token: "abc123",
            },
          });
          setLoading(false);
        }
      });
  };

  const getPreviewTierChange = (
    member: AccountSummaryMember,
    user: IUser,
    values: FormikWizardState<IEligibilityProfileWalmart>,
    token: string
  ) => {
    axiosInstance
      .post(
        "/account/subscriptions/preview",
        {
          members: [
            {
              personId: user.personId,
              payrollStatus: values.eligibility?.isPayroll
                ? "Payroll"
                : "NonPayroll",
              clientStateCode: user.clientStateCode,
              subscriberId: values.eligibility?.associateIdentificationNumber,
              orderActions: [
                {
                  ratePlanChargeId: values.package?.ratePlanChargeId,
                  type: "SubscriptionNew",
                },
              ],
            },
          ],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSummary({
          sku: "Peter",
          handle: "res.orderHandle",
          onetimeCreditTotal: 1.0,
          onetimeDiscount: 1.0,
          onetimeDiscounts: [],
          onetimePackageDetails: convertToOneTimePackageDetails(
            response.data.immediatePaymentSummary.immediatePaymentDetails
          ),
          onetimeSubtotal:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          onetimeSubtotalAfterDiscount:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          onetimeTaxes:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentTaxAmount,
          onetimeTotal:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAfterDiscountAmountWithTax,
          onetimeSetupFee:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          periodicDiscount:
            response.data.immediatePaymentSummary
              .immediateTotalPaymentAmountWithoutTax,
          periodicDiscounts: [],
          periodicPackageDetails: [],
          periodicSubtotal:
            response.data.monthlyPaymentSummary
              .monthlyTotalPaymentAmountWithTax,
          periodicTotal:
            response.data.monthlyPaymentSummary
              .monthlyTotalPaymentAmountWithTax,
          periodicServiceStartDate:
            response.data.monthlyPaymentSummary.monthlyPaymentDetails[0]
              ?.serviceStartDate ?? "2023-10-14",
          paymentMethodRequired: true,
          periodicTaxes:
            response.data.monthlyPaymentSummary.monthlyTotalPaymentTaxAmount,
          promoCodes: [],
          // promoCode: res.order.promoCode,
          // promoCodeDescription: res.order.promoCodeDescription,
          // promoCodeStatus: res.order.promoCodeStatus,
          taxCalculated: true,
          paymentPageCC: {
            hostedPaymentPageId: "abc123",
            hostedPaymentPageUrl: "abc123",
            key: "abc123",
            signature: "abc123",
            tenantId: "abc123",
            token: "abc123",
          },
          paymentPageECheck: {
            hostedPaymentPageId: "abc123",
            hostedPaymentPageUrl: "abc123",
            key: "abc123",
            signature: "abc123",
            tenantId: "abc123",
            token: "abc123",
          },
        });
      });
  };

  const forceRefresh = () => {
    // getSummary();
    // setState((s) => ({
    //   ...s,
    //   force: true,
    //   mutated: true,
    // }));
  };
  const setMutated = () => {
    return;
  };
  const setPromoCode = () => {
    return;
  };

  const cancel = () => {
    return;
  };

  const getSummaryRatePlan = (
    wizardState: FormikWizardState<IEligibilityProfileWalmart>
  ) => {
    getSummary(wizardState);
  };

  return (
    <SummaryContext.Provider
      value={{
        setMutated,
        cancel,
        forceRefresh,
        setPromoCode,
        getSummaryRatePlan,
        getPreviewTierChange,
        setProductRatePlans: (p: Product[]) => {
          setProductRatePlans(p);
        },
        setAddendum: useCallback(
          (a: string) => {
            setAddendum(a);
          },
          [addendum]
        ),
        mutated: state.mutated,
        summary: summary,
        error: error,
        loading: loading,
        force: state.force,
        promoLoading: state.promoLoading,
        uiSummary: uiMapping,
        productRatePlans,
        addendum,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryProvider;
