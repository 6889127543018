import { useEffect, useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";

const useGetAmenities = (token: string) => {
  const [amenities, setAmenities] = useState<string[]>([]);

  useEffect(() => {
    if (token) {
      axiosNetworkInstance
        .get(`/amenities`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            setAmenities(response.data.data.activeLocationCount);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
    }
  }, [token]);
  return amenities;
};

export default useGetAmenities;
