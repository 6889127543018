import React, { Fragment, useEffect, useState, useContext } from "react";
import { IFeatureFields } from "../@types/generated/contentful";
import FeatureFactory from "../components/features/FeatureFactory";
import PoweredBy from "../components/shared/PoweredBy";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import { EntryCollection, Entry } from "contentful";
import { useConfig } from "../configuration/useConfig";
// import { useConfig } from ".."
import useContentful from "../hooks/useContentful";
import { UserContext } from "../context/UserProvider";
const Landing = ({ onOpen }: { onOpen?: (e: unknown) => void }) => {
  const [content, setContent] =
    useState<EntryCollection<IFeatureFields> | null>(null);
  const config = useConfig();
  const { getFeatureFields, getFeatureFieldsPayroll } = useContentful(
    config.config
  );

  const { user } = useContext(UserContext);
  useEffect(() => {
    (async () => {
      if (user.isPayroll) {
        getFeatureFieldsPayroll().then((res) =>
          setContent(res as EntryCollection<IFeatureFields>)
        );
      } else {
        getFeatureFields().then((res) =>
          setContent(res as EntryCollection<IFeatureFields>)
        );
      }
    })();
  }, [user.isPayroll]);

  return (
    <Fragment>
      {content?.items.map((item: Entry<IFeatureFields>, index: number) => (
        <FeatureFactory
          type={item.fields.type ?? ""}
          data={item}
          key={item.fields.type + "-" + index ?? ""}
          params={{ onClick: onOpen }}
          index={index}
        />
      ))}
      <LegalDisclaimer />
      <PoweredBy />
    </Fragment>
  );
};

export default Landing;
