import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GeneralRouting from "./routing/GeneralRouting";
import Wizard from "./components/wizard/Wizard";
import "./styles/main.scss";
import AccountRouting from "./routing/AccountRouting";
import AuthWrapper from "./components/shared/AuthWrapper";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Terms from "./pages/Terms";
import FitnessProgramAgreement from "./pages/FitnessProgramAgreement";
import Locations from "./pages/Locations";
import AccountProvider from "./context/AccountProvider";
import Impersonate from "./pages/Impersonate";
import CsrBanner from "./components/shared/CsrBanner";
import { ImpersonationContext } from "./context/ImpersonationProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import AccountRequiredWrapper from "./components/shared/AccountRequiredWrapper";
import ProductBundleProvider from "./context/ProductBundleProvider";
import { hotjar } from "react-hotjar";
import Maintenance from "./pages/Maintenance";
import Privacy from "./pages/Privacy";
import { defaultConfig, dynamicConfigUrl } from "./configuration/config";
import { ConfigContextObject, useConfig } from "./configuration/useConfig";
import Boot from "./Boot";
import SSO from "./pages/SSO";
import axiosInstance, {
  axiosAuthInstance,
  axiosMemberInstance,
  axiosNetworkInstance,
} from "./axios.instance";
import SuccessUpdated from "./pages/SuccessUpdated";
import { MockPanel } from "./components/mockpanel/MockPanel";
import { Auth0TokenProvider } from "./components/shared/Auth0TokenProvider";
import AxiosInterceptor from "./components/shared/AxiosInterceptor";

const configLoadingErrorMessage =
  "Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)";

const App = () => {
  const { setConfig, getSiteConfig } = useConfig();
  const [redirectUri, setRedirectUri] = useState<string>(
    window.location.origin + "/account"
  );

  const [configLoadingState, setConfigLoadingState] = useState<
    "loading" | "ready" | "error"
  >("loading");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (window.location.pathname === "/csr") {
      if (urlParams.get("consumerId")) {
        setRedirectUri(
          `${window.location.origin}/csr?consumerId=${urlParams.get(
            "consumerId"
          )}`
        );
      } else {
        setRedirectUri(window.location.origin + "/account");
      }
    }

    fetch(dynamicConfigUrl)
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
        // console.log("Global config fetched: ", res);
        setConfigLoadingState("ready");
        //create axiosInstance(res.apiURL)
        axiosInstance.defaults.baseURL = res.apiUrl;
        axiosMemberInstance.defaults.baseURL = res.memberApiUrl;
        axiosNetworkInstance.defaults.baseURL = res.networkApiUrl;
        axiosAuthInstance.defaults.baseURL = res.authApiUrl;
        getSiteConfig();
      })
      .catch((e) => {
        // In Codesandbox.io: deleting `config.json` will not trigger this branch, because the request response code will still be 200, not 404.
        // To test this case in codesanbox.io, add "throw {};" to line 22.

        // In development, treat this case as a warning, render the app and use default config values.
        // In production (and test) on the other hand, show error instead of rendering the app.

        // In Codesandbox.io: You cannot change the value of NODE_ENV. To test this if, change "development"
        if (process.env.NODE_ENV === "development") {
          console.log(
            `Failed to load global configuration from '${dynamicConfigUrl}', using the default configuration instead:`,
            defaultConfig
          );
          setConfigLoadingState("ready");
        } else {
          console.log(
            configLoadingErrorMessage,
            `Have you provided the config file '${dynamicConfigUrl}'?`,
            e
          );
          setConfigLoadingState("error");
        }
      });
  }, [setConfig]);

  // const { config } = useContext(ClientConfigContext);
  const { config } = useConfig();
  // const location = useLocation();
  // console.log(useLocation());
  // useDisableMasking(location);

  useEffect(() => {
    document.title = config["title.name"];
  }, [configLoadingState]);

  const { isImpersonated, values } = useContext(ImpersonationContext);

  if (configLoadingState === "loading") {
    return <div></div>; // change to some visual CircularProgress in real app
  }
  if (configLoadingState === "error") {
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        {configLoadingErrorMessage}
      </p>
    );
  }

  if (config["hotjar.hjid"] !== "" && config["hotjar.hjsv"] !== "") {
    hotjar.initialize(
      parseInt(config["hotjar.hjid"], 10),
      parseInt(config["hotjar.hjsv"], 10)
    );
  }
  // maintenance mode
  if (config["maintenance.enable"] === "true") {
    return <Maintenance />;
  }

  // user is on an invalid client url
  // if (
  //   isAuthenticated &&
  //   config["site.baseUrl"] !==
  //     window.location.protocol + "//" + window.location.host
  // ) {
  //   window.location.replace(config["site.baseUrl"] + window.location.pathname);
  //   return null;
  // }

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   console.log(params.get("consumerId"));
  //   const queryStringValue = params.get("consumerId") || "";
  //   localStorage.setItem("consumerId", queryStringValue);
  // }, [location.search]);

  return (
    <>
      {isImpersonated && (
        <CsrBanner user={values.contactId} agent={values.csrUsername} />
      )}

      <div className={isImpersonated ? "csr-layout" : ""}>
        <ConfigContextObject.Consumer>
          {({ config }) => (
            <Auth0Provider
              domain={config["idp.issuer"]}
              clientId={config["idp.clientId"]}
              authorizationParams={{
                audience: config["idp.audience"],
                impersonatePersonId:
                  new URLSearchParams(window.location.search).get("personId") ||
                  "",
                login_hint:
                  window.location.pathname !== "/csr"
                    ? ""
                    : "placeholder@tivityhealth.com",
                redirect_uri: redirectUri,
                "ext-uri": window.location.origin,
              }}
            >
              <Boot>
                <div className={isImpersonated ? "csr-layout" : ""}>
                  <AccountProvider>
                    {config["environment"] !== "prod" && <MockPanel />}
                    <ProductBundleProvider>
                      <Router>
                        {/* <ErrorBoundary FallbackComponent={SomethingWentWrong}> */}
                        <GeneralRouting />
                        {/* </ErrorBoundary> */}
                        {/* standalone routes */}
                        <Switch>
                          <Route exact path="/csr">
                            {/* <ErrorBoundary FallbackComponent={SomethingWentWrong}> */}
                            <AuthWrapper>
                              <Auth0TokenProvider>
                                <AxiosInterceptor>
                                  <AccountRequiredWrapper>
                                    <Impersonate />
                                  </AccountRequiredWrapper>
                                </AxiosInterceptor>
                              </Auth0TokenProvider>
                            </AuthWrapper>
                            {/* </ErrorBoundary> */}
                          </Route>

                          <Route exact path="/privacy-policy">
                            <Container>
                              <div style={{ padding: "24px" }}></div>
                              <Privacy home="/" />
                            </Container>
                          </Route>

                          <Route exact path="/terms">
                            <Container>
                              <div style={{ padding: "24px" }}></div>
                              <Terms home="/" />
                            </Container>
                          </Route>

                          <Route exact path="/fitness-program-agreement">
                            <Container>
                              <div style={{ padding: "24px" }}></div>
                              <FitnessProgramAgreement home="/" />
                            </Container>
                          </Route>

                          <Route exact path="/locations">
                            <Locations />
                          </Route>

                          <Route exact path="/success">
                            {/* <ErrorBoundary FallbackComponent={SomethingWentWrong}> */}
                            {/* <Success /> */}
                            <SuccessUpdated />
                            {/* </ErrorBoundary> */}
                          </Route>

                          <Route path="/account">
                            {/* <ErrorBoundary FallbackComponent={SomethingWentWrong}> */}
                            <AuthWrapper>
                              <Auth0TokenProvider>
                                <AxiosInterceptor>
                                  <AccountRequiredWrapper>
                                    <AccountRouting />
                                  </AccountRequiredWrapper>
                                </AxiosInterceptor>
                              </Auth0TokenProvider>
                            </AuthWrapper>
                            {/* </ErrorBoundary> */}
                          </Route>

                          <Route path="/enroll">
                            {/* <ErrorBoundary FallbackComponent={SomethingWentWrong}> */}
                            <Auth0TokenProvider>
                              <Wizard />
                            </Auth0TokenProvider>
                            {/* </ErrorBoundary> */}
                          </Route>
                          <Route exact path="/sso">
                            <SSO />
                          </Route>

                          {/* Dummy route to not render 404 on all pages contained in client routing */}
                          {/* <Route path="*">
                  <NotFound />
                </Route> */}
                        </Switch>
                      </Router>
                    </ProductBundleProvider>
                  </AccountProvider>
                </div>
              </Boot>
            </Auth0Provider>
          )}
        </ConfigContextObject.Consumer>
      </div>
    </>
  );
};

export default App;
