import React, { useState, useContext } from "react";
import { Formik, Form as FormikForm } from "formik";
import ProfileToast from "../../../utility/ProfileToast";
import { changePersonName } from "../../../api/identity/Queries";
import { UserContext } from "../../../context/UserProvider";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import { useConfig } from "../../../configuration/useConfig";

interface IChangePersonNameValues {
  firstName: string;
  lastName: string;
}

const PersonalInformation = () => {
  const { user } = useContext(UserContext);
  const userInfo = user.AccountSummaryMember;
  if (!userInfo) throw new Error("No UserInfo");
  const [initialNameChangeValues] = useState<IChangePersonNameValues>({
    firstName: userInfo.firstName ?? "",
    lastName: userInfo.lastName ?? "",
  });
  const [, setFormErrors] = useState({});
  const { client } = useConfig();
  const handleNameChange = async (firstName: string, lastName: string) => {
    try {
      setFormErrors(changePersonNameSchema.values);
      const res = await changePersonName(firstName, lastName);
      sessionStorage.setItem(
        "userinfoModified",
        JSON.stringify({
          givenName: firstName,
          familyName: lastName,
        })
      );

      if (res.status === "success") {
        ProfileToast("Successfully changed name.", true, client);
      } else {
        const errorMessage = "There was an error with the name change.";
        ProfileToast(errorMessage, false, client);
      }
    } catch (Error) {
      const errorMessage = "There was an error with the name change.";
      ProfileToast(errorMessage, false, client);
    }
  };

  const changePersonNameSchema = Yup.object()
    .shape({
      firstName: Yup.string().required().defined("Please enter a first name."),
      lastName: Yup.string().required().defined("Please enter a last name."),
    })
    .defined();
  return (
    <div className="edit-profile">
      <span>Personal Information</span>
      <Row>
        <Col>
          <Formik
            initialValues={initialNameChangeValues}
            validationSchema={changePersonNameSchema}
            onSubmit={(values, { resetForm }) => {
              const firstName = values.firstName;
              const lastName = values.lastName;
              handleNameChange(firstName, lastName);
              resetForm({
                values: { lastName: lastName, firstName: firstName },
              });
            }}
          >
            {(props) => (
              <FormikForm style={{ marginLeft: "14px" }}>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={3}
                    sm={3}
                    className="mb-3 p-0 edit-profile__names"
                  >
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={props.handleChange}
                      value={props.values.firstName}
                      className="edit-profile__input"
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={3}
                    className="mb-3 p-0 edit-profile__names"
                  >
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={props.values.lastName}
                      className="edit-profile__input"
                      onChange={props.handleChange}
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
      <div className="vp__personal-info my-4" />
    </div>
  );
};

export default PersonalInformation;
