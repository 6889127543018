import { Button, Modal, ProgressBar } from "react-bootstrap";
import React, { Fragment, useState } from "react";

import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import FamilyIcon from "../../../resources/Family Icon SVG.svg";
import { useHistory } from "react-router-dom";
import ChangeFamilyMemberEmail from "./ChangeFamilyMemberEmail";
import moment from "moment";
const FamilyStatusModal = ({
  status,
  message,
  show,
  onAdd,
  onDone,
  progress,
  firstName,
  lastName,
  date,
  close,
  email,
  handleChangeEmail,
  errorMessage,
  isDependentEmailLoading,
}: {
  status: string;
  message?: string;
  show: boolean;
  onAdd?: (e: unknown) => void;
  onDone?: (e: unknown) => void;
  handleChangeEmail?: (newEmail: string) => void;
  progress?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  date?: string;
  close?: (e: unknown) => void;
  errorMessage?: string;
  isDependentEmailLoading?: boolean;
}) => {
  const [check, setCheck] = useState(false);
  const history = useHistory();
  const checked = () => {
    setCheck(!check);
  };

  const getContent = () => {
    switch (status.toUpperCase()) {
      case "ERROR":
        return (
          <Fragment>
            <Modal.Body className="modal-body-form">
              <SomethingWentWrong small={true} />
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onDone}
              >
                Close
              </Button>
            </Modal.Footer>
            {/* <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Unfortunately, there was an error with your request.</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              Please close this message and try again.
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onDone}
              >
                Close
              </Button>
            </Modal.Footer> */}
          </Fragment>
        );
      case "LOADING":
        return (
          <Modal.Body>
            <p style={{ textAlign: "center" }}>
              <h3>
                <b>Hang Tight</b>
              </h3>
            </p>
            <p style={{ textAlign: "center" }}>
              <div className="mt-4">
                <br></br>
                <img src={FamilyIcon}></img>
                <br></br>
                <br></br>
                <p>Wrapping Up...</p>
                <br></br>
                <ProgressBar striped now={progress} />
              </div>
              <br></br>
              <span>
                <small>
                  Please <b>do not refresh the page</b>
                </small>
              </span>
            </p>
          </Modal.Body>
        );
      case "REMOVE":
        return (
          <Modal.Body>
            <p style={{ textAlign: "left", paddingLeft: "12px" }}>
              <h3>
                <b>Remove Family Member?</b>
              </h3>
            </p>
            <hr />
            <p style={{ textAlign: "left", paddingLeft: "12px" }}>
              Are you sure you want to remove{" "}
              <b>
                {firstName} {lastName}{" "}
              </b>
              from your plan?
              <br></br>
              <br></br>
              <span>
                <small>
                  <em>
                    <b>Note: </b>This family member will be removed effective{" "}
                    <b>
                      {!date ? "loading..." : moment(date).format("MM/DD/YYYY")}
                    </b>
                  </em>
                </small>
              </span>
            </p>
            <br></br>
            <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
              <Button
                variant="btn btn-outline-primary"
                className="col btn btn-outline-primary"
                onClick={close}
              >
                Close
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="btn btn-primary"
                className="col mx-2 btn btn-secondary"
                onClick={onDone}
              >
                Remove Family Member
              </Button>
            </div>
          </Modal.Body>
        );
      case "CONFIRMED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Family Member Confirmed!</b>
              </h2>
              <div
                className="modal-confirmed-family-close"
                onClick={onDone}
              ></div>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>Congratulations!</h6>
              <p>
                <small>
                  <b>
                    {firstName} {lastName}
                  </b>{" "}
                  was added to your plan
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="btn btn-outline-primary"
                className="nav-btn-enroll font-weight-bold modal-footer-form__button modal-footer-family-member__button"
                onClick={onAdd}
              >
                Add Another Family Member
              </Button>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold modal-footer-form__button modal-footer-family-member__button"
                onClick={onDone}
              >
                Done
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "DENIED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__exclamation"></div>
              <h2>
                <b>Eligibility Cannot Be Confirmed</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">{message}</Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="outline-primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onDone}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                style={{ whiteSpace: "nowrap" }}
                onClick={onDone}
              >
                Re-Enter Information
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EDIT":
        return (
          <Fragment>
            <ChangeFamilyMemberEmail
              close={close}
              currentEmail={email}
              handleChangeEmail={handleChangeEmail}
              errorMessage={errorMessage}
              isDependentEmailLoading={isDependentEmailLoading}
            />
          </Fragment>
        );
      case "REDEMPTION":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2 className="redemption-eligibility">
                <b>Welcome Blue365 Members</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6 className="redemption-confirmed">
                Your Eligibility has been confirmed!
              </h6>
              <p>
                <small className="redemption-agreement">
                  Explore our different packages, find participating gyms, and
                  discover more perks that come with a Fitness Your Way
                  membership.
                </small>
              </p>
              <p style={{ marginTop: "10px" }}>
                <small className="redemption-proceed">
                  Please agree to our terms before proceeding.
                </small>
              </p>
              <p style={{ marginBottom: "-20px" }}>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onChange={checked}
                    style={{
                      marginRight: "6px",
                      height: "13px",
                      width: "13px",
                    }}
                  />
                  <small className="redemption-tnc">
                    <span className="required-field-marker">*</span>I have read
                    and agree to the{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </small>
                </label>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="redemption-button"
                disabled={!check}
                onClick={onAdd}
              >
                Explore
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "EXISTS":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2 className="redemption-eligibility">
                <b>Hang Tight</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6 className="redemption-confirmed">
                We're Confirming Family Member Eligibility
              </h6>
              <p>
                <small className="redemption-agreement"></small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="redemption-button"
                onClick={() => history.push("/account")}
              >
                Login
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "ALREADY_CONFIRMED":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form">
              <div className="modal-header-form__checkmark"></div>
              <h2>
                <b>Hang Tight</b>
              </h2>
            </Modal.Header>
            <Modal.Body className="modal-body-form">
              <h6>We're Confirming Family Member Eligibility</h6>
              <p>
                <small>
                  You&apos;re one step closer to a longer, healthier life.
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form">
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold modal-footer-form__button"
                onClick={onAdd}
              >
                Proceed to Next Step
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      case "BURNALONG":
        return (
          <Fragment>
            <Modal.Header className="modal-header-form-burnalong">
              <h2>
                <b>You're heading to BurnAlong.com</b>
              </h2>
              <span style={{ cursor: "pointer" }} onClick={onDone}>
                X
              </span>
            </Modal.Header>
            <Modal.Body className="modal-body-form-burnalong">
              <h6>
                You're leaving the Fitness Your Way portal and being redirected
                to our friends at BurnAlong.com
              </h6>
              <p>
                <small>
                  Please note that BurnAlong.com is not owned, operated or
                  controlled by Tivity Health, inc. Or it's affiliates. By
                  visiting BurnAlong.com, you will be subject to their terms of
                  use and privacy policy
                </small>
              </p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-form-burnalong">
              <Button
                variant="outline-primary"
                className="font-weight-bold modal-footer-form-burnalong__button stay-here"
                style={{ whiteSpace: "nowrap" }}
                onClick={onDone}
              >
                Stay Here
              </Button>
              <Button
                variant="primary"
                className="font-weight-bold modal-footer-form-burnalong__button"
                onClick={onAdd}
              >
                Visit BurnAlong
              </Button>
            </Modal.Footer>
          </Fragment>
        );
      default:
        return null;
    }
  };
  return (
    <Modal centered show={show} dialogClassName="status-modal">
      {getContent()}
    </Modal>
  );
};

export default FamilyStatusModal;
