import { Col, Row } from "react-bootstrap";
import { useContext, useState } from "react";
import ArrowRotateLeftSolidBlue from "../../resources/ArrowRotateLeftSolidBlue.svg";
import DiscardCancellationModal from "./DiscardCancellationModal";
import { Tile } from "./Tiles/Tile";
import { momentStartOfDay } from "../../utility/util";
import { useConfig } from "../../configuration/useConfig";
import AccountUpdateFailedBanner from "./AccountUpdateFailedBanner";
import useMembership from "../../hooks/useMembership";
import { AccountContext } from "../../context/AccountProvider";

const DiscardCancelBanner = () => {
  const { refresh } = useContext(AccountContext);
  const { config } = useConfig();
  const member = useMembership();

  const [isDiscarding, setIsDiscarding] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState<boolean>();

  const termDate = member?.eligibilities?.find(
    (e) => e.isPendingTermination
  )?.memberTermDate;

  if (!termDate) {
    return <></>;
  }

  const daysLeftToDiscard = momentStartOfDay(termDate).diff(
    momentStartOfDay(),
    "days"
  );

  const isDigital =
    member?.eligibilities[0].currentSubscription?.ratePlanName ===
    "Fitness Pass Digital";

  if (wasSuccessful === false) {
    return <AccountUpdateFailedBanner />;
  }

  if (wasSuccessful === true) {
    return <></>;
  }

  return (
    <Tile className="discard-cancel-banner">
      <div className="discard-cancel-banner__body">
        <Row className="justify-content-between align-items-center">
          <Col className="discard-cancel-banner__header" xs={12} sm>
            <h5 className="text-left">
              <b>It's not too late to keep your current plan!</b>
            </h5>
            <p className="text-left p-0 mb-0">
              {isDigital ? (
                <>
                  You have {daysLeftToDiscard} days left to discard your
                  cancellation before you lose access to{" "}
                  {config["lesmills.enable"] == "true"
                    ? `Les
                Mills and`
                    : ``}{" "}
                  Burnalong. Your digital subscription is set to end on{" "}
                  {momentStartOfDay(termDate).format("MM/DD/YY")}.
                </>
              ) : (
                <>
                  <p className="discard-cancel-banner__italic-text">
                    Take advantage of thousands of nationwide gyms and online
                    fitness & wellness classes by remaining enrolled in one of
                    our premier fitness packages today!
                  </p>
                </>
              )}
            </p>
          </Col>
          <Col className="justify-content-center d-flex" sm={4}>
            <button
              className="discard-cancel-banner__discard-button"
              onClick={() => setIsDiscarding(true)}
            >
              <span>
                <img
                  alt="arrow"
                  className="discard-cancel-banner__back-arrow"
                  src={ArrowRotateLeftSolidBlue}
                />
                <>&nbsp;&nbsp;</>
                <b>
                  {member?.eligibilities[0].futureSubscription
                    ? "Discard Changes"
                    : "Discard Changes"}
                </b>
              </span>
            </button>
          </Col>
        </Row>
      </div>
      {isDiscarding && (
        <DiscardCancellationModal
          member={member}
          onClose={(success) => {
            if (success) {
              refresh();
            }
            setWasSuccessful(success);
            setIsDiscarding(false);
          }}
        />
      )}
    </Tile>
  );
};

export default DiscardCancelBanner;
