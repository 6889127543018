import React, { Fragment, useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";

import { NavLink, Route, useHistory, useLocation } from "react-router-dom";
import HowItWorks from "../pages/HowItWorks";
import Landing from "../pages/Landing";
import NavBar from "../components/navigation/NavBar";
import NavMenu from "../components/navigation/NavMenu";
import { UserContext, USER_STATUS } from "../context/UserProvider";
import { ButtonContext } from "../context/ButtonProvider";
import FAQ from "../pages/FAQ";
import useDisableMasking from "../hooks/useDisableMasking";
import Login from "../pages/Login";
// import NotFound from "../pages/NotFound";

/**
 *
 * Routing page for fyw.
 */
const GeneralRouting = () => {
  const { user } = useContext(UserContext);
  const { setButton } = useContext(ButtonContext);
  // const { isAuthenticated } = useAuth0();

  const [, setOpen] = useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen((open) => !open);
    history.push("/enroll/eligibility");
  };

  useEffect(() => {
    setButton({
      text: "Get Started",
      onClick: handleOpen,
    });
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    history.push("/account");
  };

  const location = useLocation();
  useDisableMasking(location);

  return (
    <Fragment>
      <Route exact path="/">
        <NavBar logo>
          <Nav
            className="justify-content-end"
            style={{
              width: "100%",
            }}
          >
            <Nav.Item>
              <Nav.Link
                style={{ alignSelf: "end" }}
                as={NavLink}
                exact={true}
                to="/"
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                style={{ whiteSpace: "nowrap" }}
                as={NavLink}
                to="/how-it-works"
              >
                How It Works
              </Nav.Link>
            </Nav.Item>
            {user.isSSO && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/locations">
                  Locations
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link as={NavLink} to="/faqs">
                FAQs
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={handleLogin}
          />
        </NavBar>
        <Landing onOpen={handleOpen} />
      </Route>
      <Route exact path="/how-it-works">
        <NavBar logo>
          <Nav
            className="justify-content-end"
            style={{
              width: "100%",
            }}
          >
            <Nav.Item>
              <Nav.Link
                style={{ alignSelf: "end" }}
                as={NavLink}
                exact={true}
                to="/"
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                style={{ whiteSpace: "nowrap" }}
                as={NavLink}
                to="/how-it-works"
              >
                How It Works
              </Nav.Link>
            </Nav.Item>
            {user.isSSO && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/locations">
                  Locations
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link as={NavLink} to="/faqs">
                FAQs
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={handleLogin}
          />
        </NavBar>
        <HowItWorks />
      </Route>
      <Route exact path="/faqs">
        <NavBar logo>
          <Nav
            className="justify-content-end"
            style={{
              width: "100%",
            }}
          >
            <Nav.Item>
              <Nav.Link
                style={{ alignSelf: "end" }}
                as={NavLink}
                exact={true}
                to="/"
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                style={{ whiteSpace: "nowrap" }}
                as={NavLink}
                to="/how-it-works"
              >
                How It Works
              </Nav.Link>
            </Nav.Item>
            {user.isSSO && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/locations">
                  Locations
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link as={NavLink} to="/faqs">
                FAQs
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={handleLogin}
          />
        </NavBar>
        <FAQ />
      </Route>
      <Route exact path="/login">
        <NavBar logo>
          <Nav
            className="justify-content-end"
            style={{
              width: "100%",
            }}
          >
            <Nav.Item>
              <Nav.Link
                style={{ alignSelf: "end" }}
                as={NavLink}
                exact={true}
                to="/"
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                style={{ whiteSpace: "nowrap" }}
                as={NavLink}
                to="/how-it-works"
              >
                How It Works
              </Nav.Link>
            </Nav.Item>
            {user.isSSO && (
              <Nav.Item>
                <Nav.Link as={NavLink} to="/locations">
                  Locations
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link as={NavLink} to="/faqs">
                FAQs
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <NavMenu
            onEligibility={
              user.status === USER_STATUS.PENDING ? handleOpen : undefined
            }
            onEnroll={
              user.status === USER_STATUS.ELIGIBLE ? handleOpen : undefined
            }
            onLogin={handleLogin}
          />
        </NavBar>
        <Login />
      </Route>
    </Fragment>
  );
};

export default GeneralRouting;
