import React, { useEffect, useState, useContext } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Payment } from "../../../types";
import PaymentWidget from "../PaymentWidget";
import DeletePaymentModal from "./DeletePaymentModal";
import AddPaymentModal from "./AddPaymentModal";
import EditPaymentModal from "./EditPaymentModal";
import HangTightPaymentModal from "./HangTightPaymentModal";
import ProfileToast from "../../../utility/ProfileToast";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserProvider";
import { useConfig } from "../../../configuration/useConfig";
import FailPaymentModal from "./FailPaymentModal";

import axiosInstance from "../../../axios.instance"; // Axios added for mock up purposes

const PaymentMethods = () => {
  const { client } = useConfig();
  const [paymentMethods, setPaymentMethods] = useState<Payment[] | null>(null);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openHangTight, setOpenHangTight] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<string>("");
  const [editPayment, setEditPayment] = useState<Payment | null>(null);
  const [reloadToggle, setReloadToggle] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setPayrollDate] = useState<string>("");
  const [, setPackageName] = useState<string>("");
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [openFailModal, setOpenFailModal] = useState(false);
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [summary, setSummary] = useState<
    | {
        clientEligibilityStatus: boolean;
        clientStateCode: string;
        payrollStatus: string;
        isImpersonated: boolean;
        csrUsername: string;
        member: {
          consumerId: string;
          personId: string;
          firstName: string;
          lastName: string;
          dateOfBirth: string;
          email: string;
          address1: string;
          address2: string;
          city: string;
          state: string;
          zip: string;
          cardNumber: string;
          eligibilities: [
            {
              isMembershipActive: boolean;
              isPendingtermination: boolean;
              alid: number;
              costCenterCode: number;
              productCode: string;
              clientMemberId: string;
              memberEffectiveDate: string;
              memberTermDate: string;
              currentSubscriptionStatus: string;
              isPrimary: boolean;
              isDependentToMember: boolean;
              currentSubscription: {
                price: string;
                productId: string;
                productName: string;
                ratePlanChargeId: string;
                ratePlanName: string;
                tier: string;
                subscriptionNumber: string;
              };
              futureSubscription: {
                price: string;
                productId: string;
                productName: string;
                ratePlanChargeId: string;
                ratePlanName: string;
                tier: string;
                subscriptionNumber: string;
              };
              tiers: [
                {
                  tier: number;
                  tierStartDate: string;
                  tierEndDate: string;
                  programCode: string;
                  createdOnDate: string;
                }
              ];
              attribute: object;
            }
          ];
          subscriptionAccount: {
            id: string;
            accountNumber: string;
            autoPay: boolean;
            billCycleDay: string;
            lastInvoiceDate: string;
            billing: {
              id: string;
              firstName: string;
              lastName: string;
              address1: string;
              city: string;
              state: string;
              country: string;
            };
            shipping: {
              id: string;
              firstName: string;
              lastName: string;
              address1: string;
              city: string;
              state: string;
              country: string;
            };
            paymentMethods: [];
          };
        };
        dependents: [];
      }
    | undefined
  >(undefined);
  // EDITED CODE

  const getPaymentMethods = () => {
    setLoading(true);

    axiosInstance
      .get("/account/payment-methods", {})
      .then(({ data }) => {
        if (data) {
          setPaymentMethods(data);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.toJSON());
        setError(true);
      });
  };
  useEffect(() => {
    if (openHangTight && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, openHangTight]);

  useEffect(() => {
    if (!user.isPayroll) getPaymentMethods();
    if (user.isPayroll && user.payrollAuthorizations.length > 0) {
      const payrollDate = new Date(
        user.payrollAuthorizations[0].authorizationAtUtc
      );

      setPackageName(user.payrollAuthorizations[0].newPackageName);
      setPayrollDate(
        `${("0" + (payrollDate.getMonth() + 1)).slice(-2).toString()}/${(
          "0" +
          (payrollDate.getDate() + 1)
        )
          .slice(-2)
          .toString()}/${payrollDate.getFullYear().toString()}`
      );
    }
  }, [reloadToggle, openAddModal, user.payrollAuthorizations]);

  useEffect(() => {
    axiosInstance
      .get("/account/summary", {})
      .then(({ data }) => {
        if (data) {
          setSummary(data);
          setIsSummary(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const setAsDefaultPaymentMethod = (id: string) => {
    axiosInstance
      .put(`/account/payment-methods/${id}/default`, id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        const json = response.data;
        setReloadToggle(!reloadToggle);
        ProfileToast(
          `Your default payment method has been successfully changed.`,
          true,
          client
        );
        setLoading(false);
        if (json.isDefault === false) {
          setOpenFailModal(true);
        }
      })
      .catch((e) => {
        ProfileToast(
          `There was an error changing your default payment method.`,
          false,
          client
        );
        console.log(e.toJSON());
      });
  };

  const setAsDefault = (id: string) => {
    setLoading(true);
    setAsDefaultPaymentMethod(id);
  };

  useEffect(() => {
    if (paymentMethods && paymentMethods.length === 1) {
      if (!paymentMethods[0].isDefaultPaymentMethod) {
        setAsDefault(paymentMethods[0].id);
      }
    }
  }, [paymentMethods]);

  const onEdit = (paymentMethod: Payment) => {
    setOpenEditModal(false);
    setProgress(0);
    setOpenHangTight(true);
    setIntervalId(setInterval(() => setProgress((p) => p + 45), 300));
      axiosInstance
        .put(`/account/payment-methods/${paymentMethod.id}`, paymentMethod, {
          headers: {
            accept: "application/json",
          },
        })
        .then(({ data }) => {
          if (data.wasSuccessful === true) {
            const updated: Payment[] = [];
            paymentMethods?.map((p) => {
              if (p.id === paymentMethod.id) {
                updated.push(paymentMethod);
              } else {
                updated.push(p);
              }
            });
            setPaymentMethods(updated);
            ProfileToast(`Your payment method has been updated.`, true, client);
            setOpenHangTight(false);
            setEditLoading(false);
            getPaymentMethods();
          } else {
            setOpenHangTight(false);
            setEditLoading(false);
            setOpenFailModal(true);
          }
        })
        .catch((error) => console.log(error));
  };

  const onAdd = () => {
    setReloadToggle(!reloadToggle);
    ProfileToast(`Your payment method has been added.`, true, client);
  };
  const onRemove = (id: string) => async () => {
      axiosInstance
        .delete(`/account/payment-methods/${id}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then(() => {
          const updated: Payment[] = [];
          paymentMethods?.map((p) => {
            if (p.id != id) {
              updated.push(p);
            }
          });
          setPaymentMethods(updated);
          ProfileToast(`Your payment method has been deleted.`, true, client);
          setOpenDeleteModal(false);
          setDeleteID("");
        })
        .catch((error) => {
          console.log(error.toJSON());
          ProfileToast(
            `Your payment method could not be deleted.`,
            false,
            client
          );
        });
  };

  const formatDate = (date) => {
    const dateFormatted = new Date(date);

    const result = `${("0" + (dateFormatted.getMonth() + 1))
      .slice(-2)
      .toString()}/${("0" + dateFormatted.getDate())
      .slice(-2)
      .toString()}/${dateFormatted.getFullYear().toString()}`;

    return result;
  };

  const isoFormat = (date) => {
    const isoDate = new Date(date).toISOString();
    const month = isoDate.slice(5, 7);
    const day = isoDate.slice(8, 10);
    const year = isoDate.slice(0, 4);

    return `${month}/${day}/${year}`;
  };

  const immediateCancelRecord = (index) => {
    if (
      user.payrollAuthorizations[index + 1] &&
      user.payrollAuthorizations[index + 1].authorizationType === "Cancel"
    ) {
      const nextCancel = user.payrollAuthorizations[index + 1];
      const today = new Date(new Date().setHours(0, 0, 0, 0));

      // Verify if currentTierEndDate exists
      if (nextCancel.currentTierEndDate) {
        const dateFormatted = isoFormat(nextCancel.currentTierEndDate);
        const currentTierEndDate = new Date(nextCancel.currentTierEndDate);
        return currentTierEndDate < today ? dateFormatted : null;
      }
    }

    return formatDate(
      user.payrollAuthorizations[index].authorizationEndDateAtAz
    );
  };

  const payrollDeduction = (item, index) => {
    if (
      item.authorizationType === "NewAccount" ||
      item.authorizationType === "SubscriptionNew" ||
      item.authorizationType === "SubscriptionUpdate" ||
      item.authorizationType === "DiscardSubscription"
    ) {
      if (!item.authorizationEndDateAtAz) {
        return (
          <div style={{ marginTop: "-1rem" }}>
            <PaymentWidget.Body>
              <PaymentWidget.Label>
                Package Name: {item.newPackageName}
              </PaymentWidget.Label>
            </PaymentWidget.Body>

            <PaymentWidget.Footer>
              <span className="payment-widget__default">
                <i>
                  Payroll deduction authorized on{" "}
                  {formatDate(item.authorizationStartDateAtAz)}
                </i>
              </span>
            </PaymentWidget.Footer>
          </div>
        );
      } else {
        if (immediateCancelRecord(index)) {
          return (
            <div style={{ marginTop: "-1rem" }}>
              <PaymentWidget.Body>
                <PaymentWidget.Label>
                  Package Name: {item.newPackageName}
                </PaymentWidget.Label>
              </PaymentWidget.Body>
              <PaymentWidget.Footer>
                <span className="payment-widget__default">
                  <i>
                    Payroll deduction authorized{" "}
                    {formatDate(item.authorizationStartDateAtAz)} -{" "}
                    {immediateCancelRecord(index)}
                  </i>
                </span>
              </PaymentWidget.Footer>
            </div>
          );
        } else {
          return (
            <div style={{ marginTop: "-1rem" }}>
              <PaymentWidget.Body>
                <PaymentWidget.Label>
                  Package Name: {item.newPackageName}
                </PaymentWidget.Label>
              </PaymentWidget.Body>

              <PaymentWidget.Footer>
                <span className="payment-widget__default">
                  <i>
                    Payroll deduction authorized on{" "}
                    {formatDate(item.authorizationStartDateAtAz)}
                  </i>
                </span>
              </PaymentWidget.Footer>
            </div>
          );
        }
      }
    }
  };

  const payrollDeductionList = (item, index) => {
    if (item.authorizationType !== "Cancel") {
      return (
        <PaymentWidget key={index} selected={true}>
          <PaymentWidget.Body>
            <PaymentWidget.Label>
              {summary?.member.firstName} {summary?.member.lastName}
            </PaymentWidget.Label>
            {/* <PaymentWidget.Label>
              Package Name:{" "}
              {summary?.member.eligibilities[0].currentSubscription.ratePlanName}
            </PaymentWidget.Label> */}
          </PaymentWidget.Body>
          <>{payrollDeduction(item, index)}</>
        </PaymentWidget>
      );
    }
  };

  const openAndSetDelete = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteID(id);
  };

  const openAndSetEdit = (payment: Payment) => {
    setOpenEditModal(true);
    setEditPayment(payment);
  };

  //fetch payment methods
  return (
    <div className="payment-methods">
      {openAddModal ? (
        <AddPaymentModal
          open={openAddModal}
          onClose={() => setOpenAddModal(!openAddModal)}
          onAdd={onAdd}
        />
      ) : null}
      {openDeleteModal ? (
        <DeletePaymentModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(!openDeleteModal)}
          onDelete={onRemove(deleteID)}
        />
      ) : null}
      {openHangTight ? (
        <HangTightPaymentModal
          open={openHangTight}
          onClose={() => setOpenHangTight(!openHangTight)}
          progress={progress}
        />
      ) : null}
      {openEditModal ? (
        <EditPaymentModal
          open={openEditModal}
          loading={editLoading}
          payment={editPayment}
          onClose={() => setOpenEditModal(!openEditModal)}
          onEdit={onEdit}
        />
      ) : null}
      {openFailModal ? (
        <FailPaymentModal
          open={openFailModal}
          onClose={() => setOpenFailModal(!openFailModal)}
        />
      ) : null}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" />
        </div>
      ) : null}
      {error && !user.isPayroll && (
        <div className="alert alert-danger" role="alert">
          There was an error retrieving your payment methods.
        </div>
      )}
      {user.isPayroll &&
        isSummary &&
        user.payrollAuthorizations
          .sort(
            (a, b) =>
              new Date(a.authorizationStartDateAtAz).getTime() -
              new Date(b.authorizationStartDateAtAz).getTime()
          )
          .map((item, index) => {
            return payrollDeductionList(item, index);
          })}
      {!error &&
        !user.isPayroll &&
        paymentMethods &&
        paymentMethods
          .slice()
          .sort((a, b) => {
            if (a.isDefaultPaymentMethod && !b.isDefaultPaymentMethod) {
              return -1;
            }
            if (!a.isDefaultPaymentMethod && b.isDefaultPaymentMethod) {
              return 1;
            }
            return 0;
          })
          .map((p, i) => {
            return (
              <PaymentWidget key={p.id} selected={p.isDefaultPaymentMethod}>
                <PaymentWidget.Body>
                  <PaymentWidget.Label>
                    {p.creditCardHolderName}
                  </PaymentWidget.Label>
                  {p.type === "CreditCard" && (
                    <PaymentWidget.Label>
                      {p.creditCardType.toUpperCase()} {p.creditCardMaskNumber}
                    </PaymentWidget.Label>
                  )}
                  {p.type === "CreditCard" && (
                    <PaymentWidget.Label>
                      Exp: {p.creditCardExpirationMonth}/
                      {p.creditCardExpirationYear} Zip Code:{" "}
                      {p.creditCardPostalCode}
                    </PaymentWidget.Label>
                  )}
                  {p.type === "ACH" && (
                    <PaymentWidget.Label>temp</PaymentWidget.Label>
                  )}
                </PaymentWidget.Body>
                <PaymentWidget.Footer>
                  {p.isDefaultPaymentMethod ? (
                    <span className="payment-widget__default">
                      <i>This is your Default</i>
                    </span>
                  ) : (
                    <PaymentWidget.Button
                      index={i}
                      className="payment-widget w-50"
                      setDefault={() => setAsDefault(p.id)}
                      variant="success"
                    >
                      Set as default
                    </PaymentWidget.Button>
                  )}
                  {p.type === "CreditCard" ? (
                    <PaymentWidget.Button
                      variant="success"
                      className="payment-widget__edit"
                      onClick={() => openAndSetEdit(p)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} /> Edit
                    </PaymentWidget.Button>
                  ) : (
                    <></>
                  )}
                  {p.type === "ACH" ? (
                    <PaymentWidget.Button
                      variant="success"
                      className="payment-widget__edit"
                      onClick={() => openAndSetEdit(p)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} /> Edit
                    </PaymentWidget.Button>
                  ) : (
                    <></>
                  )}

                  {p.isDefaultPaymentMethod ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Can not remove payment while it is the default
                          Payment.
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <PaymentWidget.Button
                          className="payment-widget__remove"
                          variant="error"
                          disabled={p.isDefaultPaymentMethod}
                          onClick={() => openAndSetDelete(p.id)}
                        >
                          <b>X</b> Remove
                        </PaymentWidget.Button>
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <PaymentWidget.Button
                      className="payment-widget__remove"
                      variant="error"
                      disabled={p.isDefaultPaymentMethod}
                      onClick={() => openAndSetDelete(p.id)}
                    >
                      <b>X</b> Remove
                    </PaymentWidget.Button>
                  )}
                </PaymentWidget.Footer>
              </PaymentWidget>
            );
          })}
      {!user.isPayroll && (
        <div className="payment-methods__add-new">
          <Button
            className="btn-outline-primary"
            id="btn-add-payment"
            onClick={() => setOpenAddModal(true)}
          >
            Add New Payment Option
          </Button>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
