import { useEffect } from "react";
import useGetPrograms from "../../hooks/useGetPrograms";

const BundlesDropDown = ({ planId }: { planId?: string }) => {
  useEffect(() => {
    // console.log(planId);
  }, [planId]);
  const { error, loading, productRatePlans, addendum } = useGetPrograms();

  if (loading) {
    return <option>Loading...</option>;
  }
  if (error) {
    return <option>Error getting pricing</option>;
  }

  return (
    <>
      {/* {termed ? (
        <option value={"termedDefault"} key={"termedDefault"} disabled>
          Please Select a Package
        </option>
      ) : null} */}
      {productRatePlans.map((plan) => {
        return (
          <option
            selected={plan.recurringRatePlanChargeId === planId}
            value={plan.recurringRatePlanChargeId}
            key={plan.id}
          >
            {plan.name.toUpperCase()}
            {" - "} {`$${plan.recurringPrice.toFixed(2) ?? 0}/${addendum}`}
          </option>
        );
      })}
    </>
  );
};

export default BundlesDropDown;
