import {
  IAddressForm,
  ICheckoutForm,
  IMembershipForm,
  IPackageSelectForm,
  IEligibilityFormProfileBCBSA,
  IEligibilityFormProfileBSC,
  IEligibilityFormProfileWalmart,
} from "./../types";

export enum POLICY_TYPES {
  PRIVACY = "PRIVACY_POLICY",
  FITNESS = "FITNESS_AGREEMENT",
  CONTACT = "CONTACT_AGREEMENT",
}

export const initialBscModel: IEligibilityFormProfileBSC = {
  firstName: "",
  lastName: "",
  middleName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  privacyPolicy: false,
  fitnessPolicy: false,
  locked: false,
  completed: false,
  zip: "",
  associateIdentificationNumber: "",
  isDependent: false,
  createLogin: false,
  card: "",
};

export const initialBcbsaModel: IEligibilityFormProfileBCBSA = {
  primarySubscriber: "",
  hasBlue365: "",
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  zip: "",
  coverageType: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  privacyPolicy: false,
  fitnessPolicy: false,
  contactInfo: false,
  completed: false,
};

export const initialWalmartModel: IEligibilityFormProfileWalmart = {
  primarySubscriber: "",
  hasBlue365: "",
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  zip: "",
  coverageType: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  associateIdentificationNumber: "",
  isDependent: false,
  createLogin: false,
  privacyPolicy: false,
  fitnessPolicy: false,
  contactInfo: false,
  completed: false,
  card: "",
  isPayroll: false,
  consumerId: "",
};

export const initialPackageModel: IPackageSelectForm = {
  sku: "",
  ratePlanChargeId: "",
  completed: false,
};

export const initialMembershipModel: IMembershipForm = {
  email: "",
  password: "",
  fitnessPolicy: false,
  completed: false,
};

export const initialAddressModel: IAddressForm = {
  home: { street1: "", street2: "", city: "", state: "", zip: "" },
  billing: { street1: "", street2: "", city: "", state: "", zip: "" },
  verifiedBilling: false,
  verifiedHome: false,
  billingRequired: false,
  homeId: "",
  billingId: "",
  completed: false,
};

export const initialCheckoutModel: ICheckoutForm = {
  paymentMethod: "",
  name: "",
  number: "",
  expiration: "",
  cvv: "",
  completed: false,
  fitnessPolicy: false,
};
