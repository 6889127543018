import { Button, Col, Nav, Row } from "react-bootstrap";
import {
  IconDefinition,
  faCog,
  faFileInvoiceDollar,
  faHome,
  faQuestionCircle,
  faRunning,
} from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import React, { Fragment } from "react";
import { USER_STATUS, UserContext } from "../context/UserProvider";

import { AccountContext } from "../context/AccountProvider";
import ActivityLog from "../components/account/Activity/ActivityLog";
import BannerWrapper from "../components/shared/BannerWrapper";
import Billing from "../pages/account/Billing";
// import DigitalUpgradeBanner from "../components/account/DigitalUpgradeBanner";
import DiscardCancelBanner from "../components/account/DiscardCancelBanner";
import TermedNotEligibleBanner from "../components/account/TermedNotEligibleBanner";
import { ErrorBoundary } from "react-error-boundary";
import FAQ from "../pages/FAQ";
import Help from "../pages/account/Help";
import InformationDisplay from "../components/account/InformationDisplay";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import LesMills from "../pages/LesMills";
import NavBar from "../components/navigation/NavBar";
import NotFound from "../pages/NotFound";
import OutStandingBalanceBanner from "../components/account/OutStandingBalanceBanner";
import Overview from "../pages/account/Overview";
import PoweredBy from "../components/shared/PoweredBy";
import SideNavigation from "../components/account/SideNavigation";
import SomethingWentWrong from "../pages/SomethingWentWrong";
import ViewProfile from "../pages/account/ViewProfile";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import useScrollToTop from "../hooks/useScrollToTop";
import { useState } from "react";
import ManagePlanUpdate from "../pages/account/ManagePlanUpdate";

import DependentPendingTerminationBanner from "../components/account/DependentPendingTerminationBanner";
import DependentTermedBanner from "../components/account/DependentTermedBanner";
import Locations from "../pages/Locations";

type NavLink = {
  label: string;
  url: string;
  cssClass?: string;
  newTab?: boolean;
  cssId?: string;
  onClick?: () => void;
};

export type SideNavLink = {
  label: string;
  url?: string;
  icon?: IconDefinition;
  newTab?: boolean;
  disabledForStatus?: string[];
  onClick?: () => Promise<void>;
};

const AccountRouting = () => {
  toast.configure();
  useScrollToTop();

  const [fullWidth, setFullWidth] = useState(false);
  const { nextPayment, member, clientEligibilityStatus, payrollStatus } =
    useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { path } = useRouteMatch();
  const location = useLocation();
  const { logout } = useAuth0();
  const termReasonPattern = /^RS5[0-9]/;
  const termReason = member?.eligibilities[0].termReason ?? "";

  if (!user.info) throw new Error("No UserInfo");
  const userInfo = user.info;

  const handleLogout = async () => {
    logout();
  };
  const [, setIsTermedStatus] = useState(false);
  const fullwidthRoutes = ["lesmills", "migrate", "locations"];

  const navBarLinks: NavLink[] = [
    { label: "My Account", url: "/account", cssId: "account-header-bold" },
    { label: "Locations", url: "/account/locations", cssId: "account-header" },
    { label: "FAQs", url: "/account/faqs", cssId: "account-header" },
    {
      label: "View Profile",
      url: "/account/view-profile",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Account Home",
      url: "/account",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Manage Plan",
      url: "/account/manage",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Terms of Use",
      url: "/terms",
      newTab: true,
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Privacy Policy",
      url: "/privacy-policy",
      newTab: true,
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Program Agreement",
      url: "/fitness-program-agreement",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Help",
      url: "/account/help",
      cssClass: "isHiddenOnLarge",
      cssId: "account-header",
    },
    {
      label: "Log Out",
      url: "/",
      onClick: handleLogout,
      cssClass: "isHiddenOnLarge logout-button",
    },
  ];

  const sideNavLinks: SideNavLink[] = [
    { label: "Account Home", url: "/account", icon: faHome },
    {
      label: "Manage Plan",
      url: "/account/manage",
      icon: faCog,
      disabledForStatus: ["TERMED", "CANCELLED", "NONE"],
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      icon: faFileInvoiceDollar,
      disabledForStatus: ["NONE", "DEPENDENT"],
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      icon: faRunning,
      disabledForStatus: ["NONE"],
    },
    { label: "Help", url: "/account/help", icon: faQuestionCircle },
    { label: "Terms of Use", url: "/terms", newTab: true },
    { label: "Privacy Policy", url: "/privacy-policy", newTab: true },
    { label: "Program Agreement", url: "/fitness-program-agreement" },
    {
      label: "Log Out",
      onClick: handleLogout,
    },
  ];

  const sideNavLinksDependent: SideNavLink[] = [
    { label: "Account Home", url: "/account", icon: faHome },
    // {
    //   label: "Manage Plan",
    //   url: "/account/manage",
    //   icon: faCog,
    //   disabledForStatus: ["TERMED", "CANCELLED", "NONE"],
    // },
    // {
    //   label: "Billing & Payment",
    //   url: "/account/billing",
    //   icon: faFileInvoiceDollar,
    //   disabledForStatus: ["NONE"],
    // },
    {
      label: "Activity Log",
      url: "/account/activity",
      icon: faRunning,
      disabledForStatus: ["NONE"],
    },
    // { label: "Help", url: "/account/help", icon: faQuestionCircle },
    { label: "Terms of Use", url: "/terms", newTab: true },
    // { label: "Privacy Policy", url: "/privacy-policy", newTab: true },
    { label: "Program Agreement", url: "/fitness-program-agreement" },
    {
      label: "Log Out",
      onClick: handleLogout,
    },
  ];

  const sideNavLinksPayroll: SideNavLink[] = [
    { label: "Account Home", url: "/account", icon: faHome },
    {
      label: "Manage Plan",
      url: "/account/manage",
      icon: faCog,
      disabledForStatus: ["TERMED", "CANCELLED", "NONE"],
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      icon: faFileInvoiceDollar,
      disabledForStatus: ["NONE"],
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      icon: faRunning,
      disabledForStatus: ["NONE"],
    },
    { label: "Help", url: "/account/help", icon: faQuestionCircle },
    { label: "Terms of Use", url: "/terms", newTab: true },
    { label: "Privacy Policy", url: "/privacy-policy", newTab: true },
    // { label: "Program Agreement", url: "/fitness-program-agreement" },
    {
      label: "Log Out",
      onClick: handleLogout,
    },
  ];

  // useEffect(() => {
  //   setUser({ status: USER_STATUS.ELIGIBLE });
  // }, []);

  // useEffect(() => {
  //   // Making sure the value is correct.
  //   setDependentStatus(!user.AccountSummaryMember?.eligibilities[0].isPrimary);
  // }, [isDependent]);

  //specific routes using full width layouts
  useEffect(() => {
    setFullWidth(
      fullwidthRoutes.filter((r) => location.pathname.toLowerCase().includes(r))
        .length > 0
    );
  }, [location]);

  useEffect(() => {
    if (
      member?.eligibilities[0].currentSubscriptionStatus === "Cancelled" &&
      user.status != USER_STATUS.ELIGIBLE
    ) {
      setIsTermedStatus(true);
    } else {
      setIsTermedStatus(false);
    }
  }, [member?.eligibilities[0].currentSubscriptionStatus, user.status]);

  const getName = useCallback(() => {
    if (userInfo && userInfo?.givenName && userInfo?.familyName) {
      return `${userInfo.givenName} ${userInfo.familyName}`;
    }
    return "";
  }, [userInfo]);

  const getInitials = useCallback(() => {
    if (user.AccountSummaryMember) {
      return `${user.AccountSummaryMember.firstName.charAt(
        0
      )} ${user.AccountSummaryMember.lastName.charAt(0)}`;
    } else {
      return `${userInfo["email"].charAt(0)}`;
    }
  }, [userInfo]);

  // const digitalUpsell = member?.eligibilities[0].currentSubscription
  //   ? member?.eligibilities[0].currentSubscription.ratePlanName ===
  //       "Fitness Pass Digital" &&
  //     member?.eligibilities[0].currentSubscriptionStatus !== "Cancelled" &&
  //     member?.eligibilities[0].currentSubscriptionStatus !==
  //       "ActiveFutureTerm" &&
  //     suspension.status === "NONE"
  //   : false;

  return (
    <Fragment>
      <Route
        exact
        path={[
          `${path}/`,
          `${path}/activity`,
          `${path}/faqs`,
          `${path}/locations`,
          `${path}/help`,
          `${path}/manage`,
          `${path}/manage/*`,
          `${path}/billing`,
          `${path}/view-profile`,
        ]}
      >
        <NavBar logo id={true}>
          <Nav activeKey={path}>
            <Nav.Link
              className="my-account-header"
              id="account-header"
            ></Nav.Link>
            {navBarLinks.map((linkItem: NavLink, index: number) => (
              <Fragment key={index}>
                {!linkItem.onClick ? (
                  <Nav.Link
                    as={Link}
                    eventKey={index}
                    to={linkItem.url}
                    key={index}
                    target={linkItem.newTab ? "_blank" : ""}
                    rel="noreferrer"
                    id={linkItem.cssId ? linkItem.cssId : ""}
                    className={linkItem.cssClass ? linkItem.cssClass : ""}
                  >
                    {linkItem.label}
                  </Nav.Link>
                ) : (
                  <Button
                    variant="link"
                    onClick={linkItem.onClick}
                    className={linkItem.cssClass ? linkItem.cssClass : ""}
                  >
                    {linkItem.label}
                  </Button>
                )}
              </Fragment>
            ))}
          </Nav>
        </NavBar>
      </Route>

      <div className="account-template h-100">
        <Route
          exact
          path={[
            `${path}/`,
            `${path}/activity`,
            `${path}/help`,
            `${path}/manage`,
            `${path}/manage/*`,
            `${path}/billing`,
            `${path}/view-profile`,
          ]}
        >
          <SideNavigation
            className="account-template__nav d-none d-lg-flex"
            name={getName()}
            avatar={getInitials()}
            // avatar={getInitials()}
            links={
              !member?.eligibilities[0].isDependentToMember
                ? payrollStatus === "Payroll"
                  ? sideNavLinksPayroll
                  : sideNavLinks
                : sideNavLinksDependent
            }
            status={member?.eligibilities[0].currentSubscriptionStatus || ""}
          />
        </Route>
        <div
          className={`${
            !fullWidth
              ? "account-template__content"
              : "account-template__content-no-padding"
          } h-100`}
        >
          <Row className="h-100">
            <Col
              xs={12}
              xl={fullWidth ? 12 : 9}
              style={{ padding: 0 }}
              className="h-100"
            >
              <Switch>
                <Route exact path={`${path}/`}>
                  {!member?.eligibilities[0].isDependentToMember && (
                    <InformationDisplay header="Account Home" />
                  )}
                  {member?.eligibilities[0].isDependentToMember &&
                    member?.eligibilities[0].isPendingTermination && (
                      <DependentPendingTerminationBanner />
                    )}

                  {member?.eligibilities[0].isDependentToMember &&
                    member?.eligibilities[0].currentSubscriptionStatus ===
                      "Cancelled" && <DependentTermedBanner />}

                  {member?.eligibilities[0].isPendingTermination &&
                    member?.eligibilities[0].isPrimary && (
                      <BannerWrapper
                        show={
                          member?.eligibilities[0].isPendingTermination &&
                          member?.eligibilities[0].isPrimary &&
                          member?.eligibilities[0].memberTermDate !==
                            "9999-12-31T23:59:00" &&
                          member?.eligibilities[0].isPrimary &&
                          !termReasonPattern.test(termReason)
                        }
                      >
                        <DiscardCancelBanner />
                      </BannerWrapper>
                    )}
                  <BannerWrapper
                    show={
                      !clientEligibilityStatus &&
                      payrollStatus === "Payroll" &&
                      member?.eligibilities[0].isDependentToMember === false
                    }
                  >
                    <TermedNotEligibleBanner />
                  </BannerWrapper>
                  {/* <DigitalUpgradeBanner show={digitalUpsell} /> */}
                  <Overview />
                </Route>
                <Route exact path={`${path}/activity`}>
                  <InformationDisplay header="Activity Log" />
                  {/* <DigitalUpgradeBanner show={digitalUpsell} /> */}
                  <div className="activity-log-container">
                    <ActivityLog />
                  </div>
                </Route>
                {!member?.eligibilities[0].isDependentToMember && (
                  <Route exact path={`${path}/billing`}>
                    <InformationDisplay header="Billing and Payment" />
                    {/* <DigitalUpgradeBanner show={digitalUpsell} /> */}
                    <BannerWrapper show={nextPayment?.overdue ?? false}>
                      <OutStandingBalanceBanner
                        amount={nextPayment?.amount ?? 0}
                        due={nextPayment?.due ?? ""}
                      />
                    </BannerWrapper>

                    <Billing />
                  </Route>
                )}
                <Route path={`${path}/lesmills`}>
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <LesMills />
                  </ErrorBoundary>
                </Route>
                <Route exact path={`${path}/manage`}>
                  {/* <DigitalUpgradeBanner show={digitalUpsell} /> */}
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <ManagePlanUpdate />
                  </ErrorBoundary>
                </Route>

                <Route exact path={`${path}/faqs`}>
                  <FAQ />
                </Route>
                <Route path={`${path}/locations`}>
                  <Locations />
                </Route>
                <Route exact path={`${path}/help`}>
                  <Help />
                </Route>
                <Route exact path={`${path}/view-profile`}>
                  <ViewProfile />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Col>
          </Row>
        </div>
        <div className="account-template__footer">
          <LegalDisclaimer />
          <PoweredBy />
        </div>
      </div>
    </Fragment>
  );
};

export default AccountRouting;
