import React, { useContext, useEffect, useState } from "react";
import ActivityLogTile from "../../components/account/Tiles/ActivityLogTile";
import StatusModal from "../../components/wizard/components/StatusModal";
import PlanTile from "../../components/account/Tiles/PlanTile";
import NoPlanTile from "../../components/account/Tiles/NoPlanTile";
import { Card } from "@tivityhealth/base.ui.card";
// import Widget from "../../components/account/Widget/Widget";
import OutStandingBalanceBanner from "../../components/account/OutStandingBalanceBanner";
import { AccountContext } from "../../context/AccountProvider";
import {
  faDumbbell,
  faExternalLinkAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import WidgetCard from "../../components/account/Widget/WidgetCard";
// import lesMillsTile from "./../../../resources/LesMillsTile.png";
import lesMillsTile from "../../resources/LesMillsTile.png";
import burnAlongTile from "../../resources/FamilyDigital.jpg";
import { Spinner } from "react-bootstrap";
import { useConfig } from "../../configuration/useConfig";
import axiosInstance, { axiosMemberInstance } from "../../axios.instance";
import { UserContext } from "../../context/UserProvider";
import AgreeTermsModal from "../../components/wizard/components/AgreeTermsModal";
import ProfileToast from "../../utility/ProfileToast";
import PayrolltoNonPayrollBanner from "../../components/account/PayrolltoNonPayrollBanner";
import { momentStartOfDay } from "../../utility/util";
import BurnAlongLogo from "../../resources/BurnAlongLogo.svg";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthToken } from "../../components/shared/Auth0TokenProvider";

const Overview = () => {
  const {
    entitlements,
    nextPayment,
    acceptedTerms,
    acceptedProgram,
    member,
    payrollStatus,
    setAccountDetails,
    refresh,
  } = useContext(AccountContext);
  const now = momentStartOfDay().format("MM/DD/YYYY");
  const userStatus = member?.eligibilities[0].currentSubscriptionStatus;
  const { config } = useConfig();
  const [loadingBurnalong, setLoadingBurnalong] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [handleTermsModal, setHandleTermsModal] = useState<boolean>(true);
  const [, setIsTier] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPayrollToNonPayrollBanner, setShowPayrollToNonPayrollBanner] =
    useState<boolean>(false);
  const termDate = momentStartOfDay(
    member?.eligibilities[0].memberTermDate
  ).format("MM/DD/YYYY");

  const formatedNow = moment(now, "MM/DD/YYYY");
  const formatedTermDate = moment(termDate, "MM/DD/YYYY");
  const token = useAuthToken();

  useEffect(() => {
    if (
      formatedTermDate > formatedNow &&
      member?.eligibilities[0].termReason === "RS50"
    ) {
      setShowPayrollToNonPayrollBanner(true);
    } else {
      setShowPayrollToNonPayrollBanner(false);
    }
  }, []);

  const loadButtonSpinner = () => {
    return (
      <Spinner
        style={{ borderWidth: ".1em", width: 15, height: 15 }}
        animation="border"
        as="span"
        size="sm"
      />
    );
  };

  const handleClickBurnalong = () => {
    setLoadingBurnalong(true);
    axiosInstance
      .post(
        "/partner-sso",
        {},
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        setLoadingBurnalong(false);
        axiosInstance;
        window.open(
          `https://${config["idp.issuer"]}/samlp/${config["burnalong.clientId"]}`
        );
      })
      .catch((e) => {
        setShowError(true);
        setOpenModal(true);
        console.log(e.toJSON());
      });
  };

  const haldleAgreement = async () => {
    setHandleTermsModal(false);
    await axiosInstance
      .post(
        "/account/terms",
        {
          acceptedTerms: true,
          acceptedProgram: payrollStatus === "NonPayroll",
        },
        {}
      )
      .then((status) => {
        if (status.status === 200) {
          refresh().then(() => {
            ProfileToast(`Your plan was successfully updated`, true, "success");
          });
        }
      })
      .catch((e) => {
        ProfileToast(`Terms and Conditions Accepted Failed`, false, "error");
        console.log(e);
      });
  };

  useEffect(() => {
    axiosMemberInstance
      .get(`/${user.personId}/card`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data) {
          setAccountDetails({ memberCardBaseImage: `${data}` });
        }
      })
      .catch((e) => {
        console.log(e.toJSON());
      });
  }, []);

  useEffect(() => {
    setIsTier(true);
  }, [member]);

  const handleCancelError = () => {
    setShowError(false);
  };

  return (
    <>
      {payrollStatus === "Payroll" ? (
        acceptedTerms === false ? (
          <AgreeTermsModal
            open={handleTermsModal}
            onAgree={haldleAgreement}
            isPayroll={payrollStatus === "Payroll"}
          />
        ) : null
      ) : acceptedTerms === false || acceptedProgram === false ? (
        <AgreeTermsModal
          open={handleTermsModal}
          onAgree={haldleAgreement}
          isPayroll={false}
        />
      ) : null}
      <div className="widget d-flex flex-wrap flex-row mb-3">
        {showPayrollToNonPayrollBanner && (
          <PayrolltoNonPayrollBanner
            termDate={member?.eligibilities[0].memberTermDate}
          />
        )}

        {nextPayment?.overdue ? (
          <OutStandingBalanceBanner
            amount={nextPayment?.amount}
            due={nextPayment?.due}
          />
        ) : null}
        {userStatus !== "Cancelled" &&
          entitlements?.includes("lesmills") &&
          config["lesmills.enable"] == "true" && (
            <WidgetCard
              iconName={faDumbbell}
              title="LES MILLS <sup>TM</sup> On-Demand"
              imageUrl={lesMillsTile}
              content="Now get access to LES MILLS On-Demand workouts! Choose from thousands of exclusive workouts led by world-class instructors to motivate and push you each step of the way."
              linkText="Explore Les Mills"
              linkUrl="/account/lesmills"
            />
          )}
        {userStatus !== "Cancelled" && entitlements?.includes("burnalong") && (
          <div className="burnalong-card">
            <Card
              iconName={faVideo}
              title="PREMIUM DIGITAL CONTENT"
              imageUrl={burnAlongTile}
            >
              <div className="whereisthisclass">
                <img
                  src={BurnAlongLogo}
                  width={"135px"}
                  className="burnlong-logo"
                ></img>
                <p className="burn-along-paragraph">
                  Enjoy full access to thousands of workouts and live classes
                  including cardio, bootcamps, barre, nutrition, yoga, and even
                  stress management and insomnia support. There's only one thing
                  left to do... feel the burn!
                </p>
                <button
                  className="burnalong-button"
                  onClick={handleClickBurnalong}
                >
                  {loadingBurnalong ? loadButtonSpinner() : "Explore Burnalong"}
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    className="widget__link-icon"
                  />
                </button>
              </div>
            </Card>
          </div>
        )}
        {userStatus === undefined || userStatus === "Cancelled" ? (
          <NoPlanTile />
        ) : (
          <PlanTile />
        )}
        {<ActivityLogTile />}
      </div>
      {openModal && (
        <StatusModal
          show={showError}
          status={"GENERIC-ERROR"}
          message=""
          onCancel={handleCancelError}
          onNext={() => {
            return;
          }}
        />
      )}
    </>
  );
};

export default Overview;
