import { ConfigResponse } from "../utility/member-portal-api-types";

export interface DynamicConfig {
  version: string;
  apiUrl: string;
  client: string;
  memberApiUrl: string;
  networkApiUrl: string;
  authApiUrl: string;
  authApiKey: string;
  apiUrlNew: string;
  contentfulSpace: string;
  contentfulAccessToken: string;
  contentfulEnvironment: string;
  environment: string;
  "commerce.clientCode": string;
  "communication.addressChanged": string;
  "communication.emailChanged": string;
  "communication.welcomeHomeGym": string;
  "communication.welcomeNoHomeGym": string;
  "contact.phoneNumber": string;
  "eligibility.flow": string;
  "eligibility.kind": string;
  "eligibility.scope": string;
  "google.maps.key": string;
  "idp.clientId": string;
  "idp.issuer": string;
  "idp.audience": string;
  "burnalong.clientId": string;
  "maintenance.enable": string;
  "maintenance.until": string;
  "membership.costCenter": string;
  "network.clientCode": string;
  "site.baseUrl": string;
  "smartystreets.key": string;
  wholeHealthChoices: string;
  "zuora.hostedPagePath": string;
  "zuora.paymentGatewayCode.cc": string;
  "zuora.paymentGatewayCode.echeck": string;
  "zuora.paymentPageId.cc": string;
  "zuora.paymentPageId.echeck": string;
  "hotjar.hjid": string;
  "hotjar.hjsv": string;
  "lesmills.enable": string;
  "title.name": string;
  "endpoint.base.url": string;
  "help.email": string;
}

export const defaultConfig: DynamicConfig = {
  apiUrl: "http://localhost:3005/boot.json",
  client: "walmart",
  memberApiUrl: "",
  networkApiUrl: "",
  authApiUrl: "",
  authApiKey: "",
  apiUrlNew: "",
  environment: "DEV",
  version: "",
  contentfulSpace: "walmart-uat",
  contentfulAccessToken: "",
  contentfulEnvironment: "",
  "commerce.clientCode": "",
  "communication.addressChanged": "",
  "communication.emailChanged": "",
  "communication.welcomeHomeGym": "",
  "communication.welcomeNoHomeGym": "",
  "contact.phoneNumber": "",
  "eligibility.flow": "",
  "eligibility.kind": "",
  "eligibility.scope": "",
  "google.maps.key": "",
  "idp.clientId": "",
  "idp.issuer": "",
  "idp.audience": "",
  "burnalong.clientId": "",
  "maintenance.enable": "",
  "maintenance.until": "",
  "membership.costCenter": "",
  "network.clientCode": "",
  "site.baseUrl": "",
  "smartystreets.key": "",
  wholeHealthChoices: "",
  "zuora.hostedPagePath": "",
  "zuora.paymentGatewayCode.cc": "",
  "zuora.paymentGatewayCode.echeck": "",
  "zuora.paymentPageId.cc": "",
  "zuora.paymentPageId.echeck": "",
  "hotjar.hjsv": "",
  "hotjar.hjid": "",
  "lesmills.enable": "false",
  "title.name": "",
  "endpoint.base.url": "",
  "help.email": "",
};

export const defaultClientConfig: ConfigResponse = {
  subClientID: 1,
  clientID: 1,
  clientName: null,
  costCenterName: null,
  costCenterCode: null,
  productCode: null,
  productFullName: null,
  subClientName: null,
  incomingHealthPlanUrl: null,
  eligibilityInputType: null,
  healthPlanAPIKey: null,
  healthPlanAPIClientID: null,
  healthPlanAPIClientSecret: null,
  healthPlanAPIUserName: null,
  healthPlanAPIPassword: null,
  healthPlanAPICertPassword: null,
  isWholeHealthAllowed: null,
  isFamilyMembershipAllowed: null,
  isIndStudioPurchaseAllowed: null,
  studioDisplayType: null,
  isGymsAllowed: null,
  isFlexAllowed: null,
  isWearablesAllowed: null,
  isVirtualTrainingAllowed: null,
  isGymNominationAllowed: null,
  isHomeGymSelectionAllowed: null,
  isLegacyMigrationOn1stVisitAllowed: null,
  isManualCheckInAllowed: null,
  terminationRule: null,
  isIMETsAllowed: null,
  minFamilyMemberAge: null,
  minBuyerMemberAge: null,
  minConsumerNonBuyerAge: null,
  setPrimaryByClient: null,
  setPrimaryByUser: null,
  isDependentAccessLinked: null,
  dependentSource: null,
  termsOfUpp: null,
  programAgreement: null,
  dependentsMaxNum: null,
  isIdpEnabled: null,
  allowFamilyMemberManualAdd: null,
  allowMemberSelfCancellation: null,
  isDefaultProduct: null,
  requiredForEligibilityCheck: null,
  clientFieldMappings: null,
  subClientGenConfigs: null,
  rewardableActivity: null,
  idpRolesAssigned: null,
  clientConsumerMappings: null,
  ssoFieldEligMappings: null,
  communicationTypes: null,
  memberCancellations: null,
  nonpayrollStates: null,
};

export const dynamicConfigUrl = "/boot.json";
