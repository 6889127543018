import { useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { momentStartOfDay } from "../../utility/util";
import { UserContext } from "../../context/UserProvider";

const CancelSubscriptionModal = ({
  onClose,
  onCancel,
  termDate,
}: {
  onClose: () => void;
  onCancel: () => void;
  termDate?: string;
}) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const formatedTermDate = momentStartOfDay(termDate).format("MM/DD/YYYY");
  const todayFormattedDate = momentStartOfDay().format("MM/DD/YYYY");
  const { user } = useContext(UserContext);

  return (
    <Modal
      show
      onHide={() => !isCancelling && onClose()}
      centered
      style={{ pointerEvents: isCancelling ? "none" : "inherit" }}
    >
      <Modal.Header>
        <Modal.Title
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #c9c9c9",
          }}
        >
          <h2>Cancel Your Plan?</h2>
          {!isCancelling && (
            <span style={{ cursor: "pointer" }} onClick={onClose}>
              X
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      {isCancelling ? (
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <p>
              Are you sure you want to cancel your plan with us?
              <br></br>
              <br></br>
              <p
                style={{
                  fontStyle: "italic",
                }}
              >
                <b>Note</b>: your current plan will come to an end for
                <b>
                  {" "}
                  you and all family members on{" "}
                  {user.isPayroll ? todayFormattedDate : formatedTermDate}.
                </b>
              </p>
            </p>
          </Modal.Body>
        </>
      )}
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "white", width: "48%" }}
          onClick={() => onClose()}
          disabled={isCancelling}
        >
          <span style={{ color: "var(--primary)" }}>Close</span>
        </Button>
        <Button
          style={{ width: "48%" }}
          variant="primary"
          onClick={() => {
            setIsCancelling(true);
            onCancel();
          }}
          disabled={isCancelling}
        >
          Cancel My Plan
        </Button>
      </Modal.Footer>{" "}
    </Modal>
  );
};
export default CancelSubscriptionModal;
