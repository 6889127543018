import { createUseStyles } from "react-jss";

//TODO: desktop breakpoint

const useStyles = createUseStyles((theme) => ({
  locationFinder: {
    display: "flex",
    // height: 850,
    width: "100%",
    color: "#4a4a4a",
    fontFamily:
      '"Roboto", "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    flexDirection: "column-reverse",
    justifyContent: "flex-end",
    boxSizing: "border-box",
    overflow: "visible",
    "-moz-osx-font-smoothing": "grayscale",
    "-webkit-font-smoothing": "antialiased",
    "& *": {
      boxSizing: "border-box",
    },
    "@media (min-width: 780px)": {
      // Need to use row as IE11 doesn't support initial
      flexDirection: "row",
      overflow: "hidden",
      justifyContent: "unset",
      minHeight: 600,
      width: "100%",
      height: "100%",
    },
  },
  locationFinderMapSearch: {
    flex: "none",
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 0,

    "@media (min-width: 780px)": {
      display: "block",
      height: "auto",
      width: "auto",
      flexGrow: "1",
      flexShrink: "1",
    },
  },

  locationFinderMapSearchWrapper: {
    margin: 5,
    position: "absolute",
    width: "97%",
    zIndex: 3,
    "@media (min-width: 780px)": {
      //   margin:5,
      position: "absolute",
      // width:"100%",
      zIndex: 4,
    },
  },
  locationFinderTotalGyms: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 5,
    position: "absolute",
    width: "60%",
    zIndex: 2,
    top: "10%",
    left: "35%",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "white",
    "@media (min-width: 780px)": {
      position: "absolute",
      zIndex: 3,
    },
  },
  markerNameActive: {
    border: `1px solid ${theme.primary.color}`,
    color: "#0d2674",
    maxWidth: 300,
    // Need z-index for safari
    zIndex: 1,
  },
  nationWideGyms: {
    marginTop: "3px",
    color: "#0d2674",
    fontSize: "0.8rem",
    fontFamily:
      '"Roboto", "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },
  gymTierBanner: {
    color: "#0d2674",
    fontSize: "0.7rem",
    fontFamily:
      '"Roboto", "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },
  tierBanner: {
    position: `absolute`,
    top: `-15%`,
    backgroundColor: theme.primary.color.css(),
    height: `20px`,
    width: `75%`,
    webkitClipPath: `polygon(0 0, 100% 0, 100% 0%, 0 100%)`,
    clipPath: `polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%)`,
    display: `flex`,
    justifyContent: `center`,
  },

  //
  // Toasts
  //
  "@keyframes Toastify__slideInRight": {
    from: {
      transform: "translate3d(110%, 0, 0)",
      visibility: "visible",
    },
    to: {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "@keyframes Toastify__slideInLeft": {
    from: {
      transform: "translate3d(-110%, 0, ,0)",
      visibility: "visible",
    },
    to: {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "@keyframes Toastify__slideInUp": {
    from: {
      transform: "translate3d(0, 110%, 0)",
      visibility: "visible",
    },
    to: {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "@keyframes Toastify__slideInDown": {
    from: {
      transform: "translate3d(0, -110%, 0)",
      visibility: "visible",
    },
    to: {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "@keyframes Toastify__slideOutRight": {
    from: {
      transform: "translate3d(0, 0, 0)",
    },
    to: {
      visibility: "hidden",
      transform: "translate3d(110%, 0, 0)",
    },
  },

  "@keyframes Toastify__slideOutLeft": {
    from: {
      transform: "translate3d(0, 0, 0)",
    },
    to: {
      visibility: "hidden",
      transform: "translate3d(-110%, 0,0)",
    },
  },

  "@keyframes Toastify__slideOutDown": {
    from: {
      transform: "translate3d(0, 0, 0)",
    },
    to: {
      visibility: "hidden",
      transform: "translate3d(0, 500px, 0)",
    },
  },

  "@keyframes Toastify__slideOutUp": {
    from: {
      transform: "translate3d(0, 0, 0)",
    },
    to: {
      visibility: "hidden",
      transform: "translate3d(0, -500px, 0)",
    },
  },

  "@keyframes Toastify__zoomIn": {
    from: {
      opacity: 0,
      transform: "scale3d(0.3, 0.3, 0.3)",
    },
    "50%": {
      opacity: 1,
    },
  },

  "@keyframes Toastify__zoomOut": {
    from: {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
      transform: "scale3d(0.3, 0.3, 0.3)",
    },
    to: {
      opacity: 0,
    },
  },

  "@keyframes Toastify__bounceInRight": {
    "from,60%,75%,90%,to": {
      animationTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    },
    from: {
      opacity: 0,
      transform: "translate3d(3000px, 0, 0)",
    },
    "60%": {
      opacity: 1,
      transform: "translate3d(-25px, 0, 0)",
    },
    "75%": {
      transform: "translate3d(10px, 0, 0)",
    },
    "90%": {
      transform: "translate3d(-5px, 0, 0)",
    },
    to: {
      transform: "none",
    },
  },

  "@keyframes Toastify__bounceOutRight": {
    "20%": {
      opacity: 1,
      transform: "translate3d(-20px, 0, 0)",
    },
    to: {
      opacity: 0,
      transform: "translate3d(2000px, 0, 0)",
    },
  },

  "@keyframes Toastify__bounceInLeft": {
    "from,60%,75%,90%,to": {
      animationTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    },
    "0%": {
      opacity: 0,
      transform: "translate3d(-3000px, 0, 0)",
    },
    "60%": {
      opacity: 1,
      transform: "translate3d(25px, 0, 0)",
    },
    "75%": {
      transform: "translate3d(-10px, 0, 0)",
    },
    "90%": {
      transform: "translate3d(5px, 0, 0)",
    },
    to: {
      transform: "none",
    },
  },

  "@keyframes Toastify__bounceOutLeft": {
    "20%": {
      opacity: 1,
      transform: "translate3d(20px, 0, 0)",
    },
    to: {
      opacity: 0,
      transform: "translate3d(-2000px, 0, 0) }",
    },
  },

  "@keyframes Toastify__bounceInUp": {
    "from,60%,75%,90%,to": {
      animationTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    },
    from: {
      opacity: 0,
      transform: "translate3d(0, 3000px, 0)",
    },
    "60%": {
      opacity: 1,
      transform: "translate3d(0, -20px, 0)",
    },
    "75%": {
      transform: "translate3d(0, 10px, 0)",
    },
    "90%": {
      transform: "translate3d(0, -5px, 0)",
    },
    to: {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "@keyframes Toastify__bounceOutUp": {
    "20%": {
      transform: "translate3d(0, -10px, 0)",
    },
    "40%,45%": {
      opacity: 1,
      transform: "translate3d(0, 20px, 0)",
    },
    to: {
      opacity: 0,
      transform: "translate3d(0, -2000px, 0)",
    },
  },

  "@keyframes Toastify__bounceInDown": {
    "from,60%,75%,90%,to": {
      animationTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    },
    "0%": {
      opacity: 0,
      transform: "translate3d(0, -3000px, 0)",
    },
    "60%": {
      opacity: 1,
      transform: "translate3d(0, 25px, 0)",
    },
    "75%": {
      transform: "translate3d(0, -10px, 0)",
    },
    "90%": {
      transform: "translate3d(0, 5px, 0)",
    },
    to: {
      transform: "none",
    },
  },

  "@keyframes Toastify__bounceOutDown": {
    "20%": {
      transform: "translate3d(0, 10px, 0)",
    },
    "40%,45%": {
      opacity: 1,
      transform: "translate3d(0, -20px, 0)",
    },
    to: {
      opacity: 0,
      transform: "translate3d(0, 2000px, 0)",
    },
  },

  "@keyframes Toastify__trackProgress": {
    "0%": {
      transform: "scaleX(1)",
    },
    "100%": {
      transform: "scaleX(0)",
    },
  },

  toastWrapper: {
    "& .Toastify__toast-container": {
      zIndex: 9999,
      position: "fixed",
      padding: 4,
      width: 320,
      boxSizing: "border-box",
      color: "#fff",
    },
    "& .Toastify__toast-container--top-left": {
      top: "1em",
      left: "1em",
    },
    "& .Toastify__toast-container--top-center": {
      top: "1em",
      left: "50%",
      marginLeft: "-160px",
    },
    "& .Toastify__toast-container--top-right": {
      top: "1em",
      right: "1em",
    },
    "& .Toastify__toast-container--bottom-left": {
      bottom: "1em",
      left: "1em",
    },
    "& .Toastify__toast-container--bottom-center": {
      bottom: "1em",
      left: "50%",
      marginLeft: "-160px",
    },
    "& .Toastify__toast-container--bottom-right": {
      bottom: "1em",
      right: "1em",
    },

    "@media only screen and (max-width: 480px)": {
      "& .Toastify__toast": {
        marginBottom: 0,
      },

      "& .Toastify__toast-container": {
        width: "100vw",
        padding: 0,
        left: 0,
        margin: 0,
      },
      "& .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right":
        {
          top: 0,
        },
      "& .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right":
        {
          bottom: 0,
        },
      "& .Toastify__toast-container--rtl": {
        right: 0,
        left: "initial",
      },
    },

    "& .Toastify__toast": {
      position: "relative",
      minHeight: 64,
      boxSizing: "border-box",
      marginBottom: "1rem",
      padding: 8,
      borderRadius: 1,
      boxShadow:
        "0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05)",
      display: "flex",
      justifyContent: "space-between",
      maxHeight: 800,
      overflow: "hidden",
      fontFamily: "sans-serif",
      cursor: "pointer",
      direction: "ltr",
    },
    "& .Toastify__toast--rtl": {
      direction: "rtl",
    },
    "& .Toastify__toast--default": {
      backgroundColor: "#505355",
      background: "#505355",
      color: "white",
      borderRadius: "8px",
    },
    "& .Toastify__toast--info": {
      background: "#3498db",
    },
    "& .Toastify__toast--success": {
      background: "#07bc0c",
    },
    "& .Toastify__toast--warning": {
      background: "#f1c40f",
    },
    "& .Toastify__toast--error": {
      background: "#e74c3c",
    },
    "& .Toastify__toast-body": {
      margin: "auto 0",
      flex: 1,
    },

    "& .Toastify__close-button": {
      color: "#fff",
      opacity: 0.5,
      fontWeight: "bold",
      fontSize: 14,
      background: "transparent",
      outline: "none",
      border: "none",
      padding: 0,
      cursor: "pointer",
      transition: "0.3s ease",
      alignSelf: "flex-start",
    },
    "& .Toastify__close-button--default": {
      color: "white",
      opacity: "0.5",
    },
    "& .Toastify__close-button:hover, .Toastify__close-button:focus": {
      opacity: 1,
    },

    "& .Toastify__progress-bar": {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: 5,
      zIndex: 9999,
      opacity: 0.7,
      background: "rgba(255,255,255, 0.25)",
      transformOrigin: "left",
    },
    "& .Toastify__progress-bar--animated": {
      animation: "$Toastify__trackProgress linear 1 forwards",
    },
    "& .Toastify__progress-bar--controlled": {
      transition: "transform .2s",
    },
    "& .Toastify__progress-bar--rtl": {
      right: 0,
      left: "initial",
      transformOrigin: "right",
    },
    "& .Toastify__progress-bar--default": {
      background: "rgba(255,255,255, 0.25)",
    },

    "& .Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left":
      {
        animationName: "$Toastify__bounceInLeft",
      },

    "& .Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right":
      {
        animationName: "$Toastify__bounceInRight",
      },

    "& .Toastify__bounce-enter--top-center": {
      animationName: "$Toastify__bounceInDown",
    },

    "& .Toastify__bounce-enter--bottom-center": {
      animationName: "$Toastify__bounceInUp",
    },

    "& .Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left": {
      animationName: "$Toastify__bounceOutLeft",
    },

    "& .Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right":
      {
        animationName: "$Toastify__bounceOutRight",
      },

    "& .Toastify__bounce-exit--top-center": {
      animationName: "$Toastify__bounceOutUp",
    },

    "& .Toastify__bounce-exit--bottom-center": {
      animationName: "$Toastify__bounceOutDown",
    },

    "& .Toastify__zoom-enter": {
      animationName: "$Toastify__zoomIn",
    },

    "& .Toastify__zoom-exit": {
      animationName: "$Toastify__zoomOut",
    },

    // '& .Toastify__flip-enter': {
    //   animationName: '$Toastify__flipIn',
    // },

    // '& .Toastify__flip-exit': {
    //   animationName: '$Toastify__flipOut',
    // },

    "& .Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left": {
      animationName: "$Toastify__slideInLeft",
    },

    "& .Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right":
      {
        animationName: "$Toastify__slideInRight",
      },

    "& .Toastify__slide-enter--top-center": {
      animationName: "$Toastify__slideInDown",
    },

    "& .Toastify__slide-enter--bottom-center": {
      animationName: "$Toastify__slideInUp",
    },

    "& .Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left": {
      animationName: "$Toastify__slideOutLeft",
    },

    "& .Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right": {
      animationName: "$Toastify__slideOutRight",
    },

    "& .Toastify__slide-exit--top-center": {
      animationName: "$Toastify__slideOutUp",
    },

    "& .Toastify__slide-exit--bottom-center": {
      animationName: "$Toastify__slideOutDown",
    },
  },

  // GLOBAL STYLES
  "@global": {
    "#tivityLocationFinder": {
      height: "100%",
      minHeight: 500,
      width: "100%",

      "@media (min-width: 780px)": {
        minHeight: 650,
      },
    },

    a: {
      color: theme.accessible.primary.color.css(),
      textDecoration: "none",
      transition: "color 0.2s ease-in-out",

      "&:hover, &:focus, &:active": {
        color: theme.accessible.primary.hoverColor.css(),
        textDecoration: "underline",
      },
    },

    button: {
      // Button Reset.
      // From https://gist.github.com/MoOx/9137295
      border: "none",
      margin: 0,
      padding: 0,
      width: "auto",
      overflow: "visible",

      background: "transparent",

      /* inherit font from ancestor */
      color: "inherit",
      font: "inherit",

      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      lineHeight: "normal",

      /* Corrects font smoothing for webkit */
      "-webkit-font-smoothing": "inherit",
      "-moz-osx-font-smoothing": "inherit",

      /* Corrects inability to style clickable `input` types in iOS */
      "-webkit-appearance": "none",

      /* Remove excess padding and border in Firefox 4+ */
      "&::-moz-focus-inner": {
        border: 0,
        padding: 0,
      },
      cursor: "pointer",
    },

    //
    // RC-Collapse Styles
    //

    ".rc-collapse-anim-active": {
      transition: "height .2s ease-out",
    },

    ".rc-collapse-item": {
      "& > .rc-collapse-header": {
        display: "flex",
        alignItems: "center",
        padding: "15px 0",
        cursor: "pointer",

        ".arrow": {
          display: "inline-block",
          content: "%5C20",
          width: 0,
          height: 0,
          fontSize: 0,
          lineHeight: 0,
          verticalAlign: "middle",
          marginRight: 8,

          "#arrow > .right": {
            borderTop: "3px solid transparent",
            borderBottom: "3px solid transparent",
            borderLeft: "4px solid #666",
          },
        },

        ".rc-collapse-extra": {
          margin: "0 16px 0 auto",
        },
      },
    },

    ".rc-collapse-item-disabled > .rc-collapse-header": {
      cursor: "not-allowed",
    },

    ".rc-collapse-content": {
      overflow: "hidden",
      backgroundColor: "#fff",
    },

    ".rc-collapse-content-box": {
      marginBottom: 16,
    },
    ".rc-collapse-content-inactive": {
      display: "none",
    },

    ".rc-collapse-item-active": {
      ".rc-collapse-header": {
        ".arrow": {
          position: "relative",
          top: 2,
          marginRight: 6,
        },
      },
    },
  },
}));

export default useStyles;
