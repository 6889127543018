import React, { Fragment, useState } from "react";

import DatePicker from "react-date-picker";
import moment from "moment";
import { momentStartOfDay } from "../../../utility/util";

interface IProps {
  selectedEndDate: string;
  selectedStartDate: string;
  setSelectedEndDate: (date: string) => void;
  setSelectedStartDate: (date: string) => void;
  applyFilters: () => void;
  clearFilter: () => void;
  filteredActivitiesByType: (type: string) => void;
  setCurrentPageNum: (num: number) => void;
}

const ActivityFilters = ({
  selectedEndDate,
  selectedStartDate,
  setSelectedEndDate,
  setSelectedStartDate,
  applyFilters,
  filteredActivitiesByType,
}: IProps) => {
  const handleChangeStartDate = (e: moment.MomentInput) => {
    if (!e) {
      e = momentStartOfDay().subtract(1, "y").format("l");
    }
    setSelectedStartDate(momentStartOfDay(e).format("l"));
  };

  const handleChangeEndDate = (e: moment.MomentInput) => {
    if (!e) {
      e = momentStartOfDay().format("l");
    }
    setSelectedEndDate(momentStartOfDay(e).format("l"));
  };

  const [eventType, setEventType] = useState("");

  const handleEventTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEventType(e.target.value);
    filteredActivitiesByType(e.target.value);
  };

  return (
    <Fragment>
      <h5>Activity Log</h5>
      <span className="border-bottom w-100 my-2" />
      <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center my-3">
        <p>Filter by</p>
        <p className="from-text text-secondary font-weight-bold font-small m-0">
          Date Range
        </p>
        <div className="d-flex flex-column align-self-start align-self-lg-center mb-3 m-lg-0">
          <p className="from-text text-uppercase text-secondary font-weight-bold font-small m-0 smaller-font">
            From
          </p>
          <DatePicker
            value={new Date(selectedStartDate)}
            onChange={(e: moment.MomentInput) => handleChangeStartDate(e)}
          />
        </div>
        <div className="d-flex flex-column align-self-start align-self-lg-center mb-3 m-lg-0">
          <p className="from-text text-uppercase text-secondary font-weight-bold font-small m-0 smaller-font">
            To
          </p>
          <DatePicker
            value={new Date(selectedEndDate)}
            onChange={(e: moment.MomentInput) => handleChangeEndDate(e)}
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div
          className="d-flex flex-column align-self-start align-self-lg-center mb-3 m-lg-0"
          style={{ textAlign: "center" }}
        >
          <select
            className="activity-log__type-selection"
            value={eventType}
            onChange={handleEventTypeChange}
          >
            <option value="">Type</option>
            <option value="Location check-in">Location check-in</option>
            <option value="Online">Online</option>
          </select>
        </div>
        <span className="border-bottom activity-log__vertical-line" />
        <button
          className="activity-log__apply-filter-btn"
          onClick={() => {
            applyFilters();
          }}
        >
          Apply Filter
        </button>
        &nbsp;
        {/* {<button
          className="btn btn-outline-secondary align-self-center mb-3 m-lg-0 activity-log__clear-filter-btn"
          onClick={() => clearFilter()}
        >
          Clear Filter
        </button>} */}
      </div>
    </Fragment>
  );
};

export default ActivityFilters;
