import { useEffect, useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";

const useGetTiers = (token: string) => {
  const [tiers, setTiers] = useState<string[]>([]);
  const [tierNames, setTierNames] = useState<string[]>([]);

  useEffect(() => {
    if (token) {
      axiosNetworkInstance
        .get(`/locations/search/metadata?code=prime_lte`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const tiers = response.data.data.tiers.map((item) =>
              item.number.toString()
            );
            const tierNames = response.data.data.tiers.map((item) =>
              item.name.toString()
            );
            setTierNames(tierNames ?? []);
            setTiers(tiers ?? []);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
    }
  }, [token]);
  return { tiers, tierNames };
};

export default useGetTiers;
