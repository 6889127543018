import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const Impersonate = () => {
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const queryStringValue = params.get("consumerId") || "";
  //   localStorage.setItem("consumerId", queryStringValue);
  // }, [location.search]);

  useEffect(() => {
    // console.log(new URLSearchParams(window.location.search).get("csrEmail"));
    // let unRestricted = true;
    // axiosInstance.defaults.baseURL = `${config["endpoint.base.url"]}/clients/walmart`;
    // console.log(auth0User);
    // console.log(query.get("consumerId"));
    // const cId = localStorage.getItem("consumerId");
    // console.log(cId);
    // console.log(user.token);
    /////////////////////
    // if (query.get("consumerId")) {
    //   const personId = "155825659";
    //   const consumerId = query.get("consumerId");
    //   // const consumerId = "e3215a34-781c-40ca-afd3-67bb7bd872bc";
    //   const getConsumer = async () => {
    //     console.log(user.token);
    //     const consumerData = await axiosInstance(`/consumers/${consumerId}`, {
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${user.token}`,
    //       },
    //     })
    //       .then(({ data }) => {
    //         if (data) {
    //           console.log(data);
    //           member?.eligibilities[0].currentSubscriptionStatus === undefined;
    //           history.push(
    //             `/enroll/eligibility?firstName=${data.firstName}&lastName=${data.lastName}&dob=${data.dateOfBirth}&email=${data.email}&subscriberId=${data.subscriberId}&zip=${data.zip}`
    //           );
    //         }
    //       })
    //       .catch((e) => {
    //         console.log(e.toJSON());
    //       });
    //   };
    //   getConsumer();
    // }
    /////////////////////////
    // const getTokens = async () => {
    //   const token = await getAccessTokenSilently()
    //     // const token = await getAccessTokenSilently({
    //     //   authorizationParams: {
    //     //     impersonatePersonId:
    //     //       new URLSearchParams(window.location.search).get("personId") || "",
    //     //     login_hint: "placeholder@tivityhealth.com",
    //     //     // redirect_uri: window.location.origin + "/enroll/eligibility",
    //     //     // audience: config["idp.audience"],
    //     //     // login_hint:
    //     //     //   new URLSearchParams(window.location.search).get("csrEmail") || "",
    //     //   },
    //     // })
    //     .then(async (token) => {
    //       console.log(token);
    //       const cId = localStorage.getItem("consumerId");
    //       const consumerData = await axiosInstance(`/consumers/${cId}`, {
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //         },
    //       })
    //         .then(({ data }) => {
    //           if (data) {
    //             console.log(data);
    //             member?.eligibilities[0].currentSubscriptionStatus === undefined;
    //             history.push(
    //               `/enroll/eligibility?firstName=${data.firstName}&lastName=${data.lastName}&dob=${data.dateOfBirth}&email=${data.email}&subscriberId=${data.subscriberId}&zip=${data.zip}`
    //             );
    //           }
    //         })
    //         .catch((e) => {
    //           console.log(e.toJSON());
    //         });
    //     })
    //     .catch((e) => console.log(e));
    // };
    // getTokens();
    // console.log("right before if");
    // if (auth0User) {
    //   setValues({
    //     csrUsername: auth0User["http://tivityhealth.com/csrUsername"],
    //     contactId: auth0User["http://tivityhealth.com/csrEmail"],
    //     accessToken: "",
    //     client: "",
    //     inContactBaseUri: "",
    //     inContactToken: "",
    //     maskingEnabled: false,
    //     sessionId: "",
    //     siteUrl: "",
    //   });
    //   console.log("values in impersonate ", values);
    //   history.push("/account");
    // }
  }, []);

  // useQuery(impersonation, {
  //   onCompleted: async (data) => {
  //     if (data.impersonation.state.csrUsername) {
  //       setValues(data.impersonation.state);
  //     } else {
  //       throw new Error("Invalid Impersonation");
  //     }

  //     if (data.impersonation.state.accessToken) {
  //       try {
  //         const expiresIn =
  //           data.impersonation.state.expiresIn === ""
  //             ? -1
  //             : data.impersonation.state.expiresIn;
  //         await auth0User?.setTokens({
  //           expiresIn: parseInt(expiresIn, 10),
  //           accessToken: data.impersonation.state.accessToken,
  //           idToken: "",
  //           refreshToken: "",
  //         });
  //         history.push("/account");
  //       } catch (e) {
  //         throw new Error("Tokens invalid");
  //       }
  //     } else {
  //       history.push("/");
  //     }
  //   },
  //   onError: () => {
  //     throw new Error("An error occured");
  //   },
  // });

  return (
    <div className="center-loading">
      <Spinner animation="border" />
    </div>
  );
};

export default Impersonate;
