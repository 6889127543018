import { PropsWithChildren, useContext, useState } from "react";
import { Modal, Button, Spinner, ModalProps } from "react-bootstrap";
import axiosInstance from "../../axios.instance";
import { useConfig } from "../../configuration/useConfig";

import { UserContext } from "../../context/UserProvider";
import {
  AccountSubscription,
  AccountSummaryMember,
} from "../../utility/member-portal-api-types";
import showToastMessage from "../../utility/ProfileToast";

export type OrderAction =
  | {
      ratePlanChargeId: string;
      subscriptionNumber?: string;
      type: "DiscardSubscription";
    }
  | {
      currentRatePlanChargeId: string;
      newRatePlanChargeId: string;
      currentSubscriptionNumber: string;
      type: "SubscriptionUpdate";
    };

export async function discardSubscription(
  personId: number,
  subscription: AccountSubscription,
  isPayroll: boolean,
  isDependent: boolean | undefined,
  subscriptionCurrent?: AccountSubscription
) {
  const orderAction: Partial<OrderAction> = {
    type: "DiscardSubscription",
    ratePlanChargeId: subscriptionCurrent
      ? subscriptionCurrent.ratePlanChargeId
      : subscription.ratePlanChargeId,
  };

  if (!isPayroll) {
    if (!subscription.subscriptionNumber && !isDependent) {
      throw new Error(
        "A subscriptionNumber is required when discarding a subscription change as a non payroll member."
      );
    }

    if (!isDependent) {
      orderAction.subscriptionNumber = subscription.subscriptionNumber;
    }
  }

  await axiosInstance
    .put("/account/subscriptions", {
      members: [
        {
          personId,
          orderActions: [orderAction],
        },
      ],
    })
    .then((response) => {
      // console.log(response);
      if (!response.data.wasSuccessful) {
        throw new Error("Change was not successful.");
      }
    });
}

function Element({ children, ...props }: PropsWithChildren<ModalProps>) {
  return (
    <Modal show {...props} centered>
      <Modal.Header>
        <Modal.Title>
          <h2>Discard Cancellation?</h2>
          {props.onHide && (
            <span style={{ cursor: "pointer" }} onClick={props.onHide}>
              X
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}

interface DiscardCancellationProperties {
  member: AccountSummaryMember;
  onClose: (success?: boolean) => void;
}

const DiscardCancellationModal = ({
  member,
  onClose,
}: DiscardCancellationProperties) => {
  const [isDiscarding, setIsDiscarding] = useState(false);

  const { client } = useConfig();
  const { user } = useContext(UserContext);

  const pendingCancellation = member?.eligibilities?.find(
    (e) => e.isPendingTermination
  );
  const termDate = pendingCancellation?.memberTermDate;
  const subscription = pendingCancellation?.currentSubscription;

  const personId = member?.personId;

  const isDependent = member.eligibilities[0].isDependentToMember;

  const isPayroll = user.isPayroll;
  if (!(termDate && subscription && personId && isPayroll !== undefined)) {
    return <></>;
  }

  const onDiscard = async () => {
    try {
      setIsDiscarding(true);
      await discardSubscription(personId, subscription, isPayroll, isDependent);
      showToastMessage("Your plan was successfully updated!", true, client);
      onClose(true);
    } catch (e) {
      console.log(e);
      onClose(false);
    }
  };

  if (isDiscarding) {
    return (
      <Element style={{ pointerEvents: "none" }}>
        <Modal.Body style={{ minHeight: "175px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      </Element>
    );
  }

  return (
    <Element onHide={() => onClose()}>
      <Modal.Body>
        <p>
          Our records indicate that you recently canceled your current fitness
          package, which is scheduled to take effect{" "}
          <b>on {new Date(`${termDate}Z`).toLocaleDateString()}</b>. Are you
          sure you want to discard the cancellation?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "white", width: "48%" }}
          onClick={() => onClose()}
        >
          <span style={{ color: "var(--primary)" }}>Close</span>
        </Button>
        <Button variant="primary" style={{ width: "48%" }} onClick={onDiscard}>
          Discard Cancellation
        </Button>
      </Modal.Footer>
    </Element>
  );
};

export default DiscardCancellationModal;
