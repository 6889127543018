import React, { useContext } from "react";

import { useTheme } from "react-jss";

import Map from "../Map";
// import Filters from "../Filters";
import Search from "../SidePane/Search";
import useStyles from "./packageMapStyles";
import LocationsProvider from "../../../context/LocationsProvider";
import { LocationsContext } from "../../../context/LocationsProvider";

const AppContainer = ({ packageSelected, ...rest }) => {
  const classes = useStyles({ ...rest, theme: useTheme() });
  const Marker = () => {
    return (
      <svg
        viewBox="0 0 5 24"
        width="24"
        height="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="none" fillRule="evenodd">
            <path
              d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
              fill="black"
            />
            <circle fill="#FFF" cx="8" cy="7.5" r="3" />
          </g>
        </g>
      </svg>
    );
  };
  const NationWideGyms = ({ packageSelected }) => {
    const { tierCount, tierNameList } = useContext(LocationsContext);
    return (
      <div className={classes.locationFinderTotalGyms}>
        <div className={classes.tierBanner}>
          <span
            style={{
              color: `white`,
              fontWeight: `bold`,
              fontStyle: `italic`,
            }}
          >
            {tierNameList && tierNameList[packageSelected - 1] !== undefined
              ? tierNameList[packageSelected - 1]
              : "DIGITAL"}
          </span>
        </div>
        <span className={classes.nationWideGyms}>Nationwide Access</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: `50%`,
            justifyContent: "center",
          }}
        >
          <div>
            <Marker />
          </div>
          <span
            style={{ color: "black", fontSize: "1.0rem", paddingTop: "2px" }}
          >
            {packageSelected === "0" ? `0` : tierCount}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: `50%`,
            justifyContent: "center",
          }}
        >
          <span style={{ color: "grey", fontSize: "0.7rem" }}>Gyms</span>
        </div>
      </div>
    );
  };
  return (
    <LocationsProvider packageSelected={packageSelected}>
      <div className={classes.locationFinder}>
        <div className={classes.locationFinderMapSearch}>
          <div className={classes.locationFinderMapSearchWrapper}>
            <Search />
          </div>
          <NationWideGyms packageSelected={packageSelected} />
          <Map
            isPackagesPage={true}
            noGyms={packageSelected === "0" ? true : false}
          />
        </div>
      </div>
    </LocationsProvider>
  );
};

export default AppContainer;
