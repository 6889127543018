import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../context/AccountProvider";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IssueList } from "../../types";
import { UserContext } from "../../context/UserProvider";
import { convertToCurrency, momentStartOfDay } from "../../utility/util";
import { useConfig } from "../../configuration/useConfig";
import NonPayrollCheckoutModal from "../wizard/components/NonPayrollCheckoutModal";
import { Payment } from "../../types";
import { CurrentAddress } from "../../components/account/Profile/ShippingAddress";
import { useMembership } from "../../hooks/useMembership";
import { setAddressQuery } from "../../api/identity/Queries";
import { IAddressFields } from "../../components/wizard/types";
import API from "../../axios.instance";
import axiosInstance from "../../axios.instance";
import showToastMessage from "../../utility/ProfileToast";
import FailPaymentModal from "./Billing/FailPaymentModal";

const OutStandingBalanceBanner = ({
  amount,
  due,
}: {
  amount: number;
  due: string;
}) => {
  const { nextPayment } = useContext(AccountContext);

  const { user } = useContext(UserContext);

  const config = useConfig();
  if (!user.info) throw new Error("No UserInfo");

  const [phoneAndHours] = useState<IssueList>({
    phoneNumber: config.config["contact.phoneNumber"],
    hours: "",
    spanishPhoneNumber: "",
  });

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const [isChangingNonPayrollPlan, setIsChangingNonPayrollPlan] =
    useState(false);

  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<Payment | null>(null);

  const [paymentMethods, setPaymentMethods] = useState<Payment[] | null>(null);

  const { ...member } = useMembership();

  const defaultAddress = (member.subscriptionAccount?.shipping ||
    (member as unknown)) as CurrentAddress | undefined;

  const [openFailModal, setOpenFailModal] = useState(false);

  const [progress, setProgress] = useState(0);

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const [openProcessingModal] = useState(false);

  const PayoffBalance = async () => {
    await axiosInstance
      .post(
        "/account/payoff-balance",
        {
          accountId: member.subscriptionAccount?.id,
          paymentMethodId: defaultPaymentMethod?.id,
        },
        {
        }
      )
      .then((responese) => {
        if (responese.status === 200) {
          showToastMessage(`Your plan was successfully updated`, true, " ");
          setIsChangingNonPayrollPlan(false);
          timeout(1000).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsChangingNonPayrollPlan(false);
        setOpenFailModal(true);
      });
  };

  useEffect(() => {
    if (openProcessingModal && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, openProcessingModal]);

  useEffect(() => void getPaymentMethods(), [isChangingNonPayrollPlan]);

  const getPaymentMethods = async () => {
    if (user.isPayroll) {
      return;
    }

    try {
      const { data } = await API.get<Payment[]>("/account/payment-methods");
      setDefaultPaymentMethod(
        data?.find((item) => item.isDefaultPaymentMethod) || null
      );
      setPaymentMethods(data);
    } catch (error) {
      console.log("An error occurred getting payment methods:");
      console.error(error);
    }
  };

  const setAddress = async (values: IAddressFields) => {
    try {
      setAddressQuery({
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        address1: values.street1,
        address2: values.street2,
        city: values.city,
        state: values.state,
        zipCode: values.zip,
      });
    } catch (error) {
      console.log("An error occurred setting an address during checkout:");
      console.error(error);
    }
  };

  const dueFormated = momentStartOfDay(due).format("MM/DD/YYYY");
  return (
    <>
      {openFailModal ? (
        <FailPaymentModal
          open={openFailModal}
          onClose={() => {
            window.location.reload();
            setOpenFailModal(!openFailModal);
          }}
          isEnrollment={false}
        />
      ) : null}
      <NonPayrollCheckoutModal
        show={isChangingNonPayrollPlan}
        onClose={() => setIsChangingNonPayrollPlan(false)}
        paymentMethod={defaultPaymentMethod}
        paymentMethods={paymentMethods}
        onPaymentChange={setDefaultPaymentMethod}
        shippingAddress={{
          street1: defaultAddress?.address1 ?? "",
          street2: defaultAddress?.address2 ?? "",
          city: defaultAddress?.city ?? "",
          state: defaultAddress?.state ?? "",
          zip: defaultAddress?.zip ?? "",
        }}
        total={nextPayment?.amount}
        onAddressEdit={setAddress}
        onConfirm={PayoffBalance}
        setAddressChanged={null}
      />
      <Alert className="account-overdue-banner">
        <Alert.Heading>
          <span>
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          Notice
        </Alert.Heading>
        <Row>
          <Col xs={12} style={{ padding: 0 }}>
            <div className="account-overdue-banner__body">
              Our records indicate you have a{" "}
              <b>
                past due balance of {convertToCurrency(amount)}, which was due
                on {dueFormated}.
              </b>{" "}
              Please pay off the existing balance now to avoid losing access to
              Fitness Pass. If you feel this balance is an error, please contact
              customer service at{" "}
              <u>
                <a
                  className="a-link"
                  href={`tel:${phoneAndHours.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {phoneAndHours.phoneNumber}
                </a>
                .{" "}
              </u>
              <br />
            </div>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col xs={5}></Col>
          <Col xs={5}></Col>
          <Col xs={2} style={{ padding: 0 }}>
            <Button
              onClick={() => {
                setIsChangingNonPayrollPlan(true);
              }}
              className="btn btn-primary w-5 btn-sm"
            >
              Pay Off Existing Balance
            </Button>
          </Col>
        </Row>
      </Alert>
    </>
  );
};

export default OutStandingBalanceBanner;
